var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dx-card chat-container"},[_vm._m(0),_c('hr'),_c('DxList',{ref:"chatsList",staticClass:"chats-list",attrs:{"items":_vm.chats,"pullRefreshEnabled":false,"selectionMode":"none","focusStateEnabled":false,"hoverStateEnabled":false,"activeStateEnabled":false,"bounceEnabled":true,"scrollByThumb":true,"useNativeScrolling":false},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var chatItem = ref.data;
var index = ref.index;
return [_c('div',[(chatItem.type === 'receive')?_c('div',{class:index == 0
              ? 'chat-from-container chat-list-first'
              : 'chat-from-container'},[(_vm.isHeaderVisible(index))?_c('div',{staticClass:"chat-header"},[_c('hr',{staticClass:"chat-header-indicator"}),_c('span',{staticClass:"chat-header-text"},[_vm._v(" "+_vm._s(_vm.getChatDateText(chatItem.dateTime))+" ")]),_c('hr',{staticClass:"chat-header-indicator"})]):_vm._e(),_c('div',{staticClass:"chat-from"},[_c('span',{staticClass:"tail-in",attrs:{"data-icon":"tail-in"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 8 13","width":"8","height":"13"}},[_c('path',{attrs:{"opacity":".13","fill":"#0000000","d":"M1.533 3.568L8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"}}),_c('path',{attrs:{"fill":"currentColor","d":"M1.533 2.568L8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"}})])]),_c('div',{staticClass:"chat-bubble"},[_c('span',{staticClass:"chat-message"},[_vm._v(_vm._s(chatItem.message)+" ")]),_c('span',{staticClass:"chat-date-time"},[_vm._v(_vm._s(_vm.getChatTimeText(chatItem.dateTime))+" ")])])])]):_vm._e(),(chatItem.type === 'send')?_c('div',{class:index == 0
              ? 'chat-to-container chat-list-first'
              : 'chat-to-container'},[(_vm.isHeaderVisible(index))?_c('div',{staticClass:"chat-header"},[_c('hr',{staticClass:"chat-header-indicator"}),_c('span',{staticClass:"chat-header-text"},[_vm._v(" "+_vm._s(_vm.getChatDateText(chatItem.dateTime))+" ")]),_c('hr',{staticClass:"chat-header-indicator"})]):_vm._e(),_c('div',{staticClass:"chat-to"},[_c('span',{staticClass:"tail-out",attrs:{"data-icon":"tail-out"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 8 13","width":"8","height":"13"}},[_c('path',{attrs:{"opacity":".13","d":"M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"}}),_c('path',{attrs:{"fill":"currentColor","d":"M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"}})])]),_c('div',{staticClass:"chat-bubble"},[_c('span',{staticClass:"chat-message"},[_vm._v(_vm._s(chatItem.message)+" ")]),_c('span',{staticClass:"chat-date-time"},[_vm._v(_vm._s(_vm.getChatTimeText(chatItem.dateTime))+" ")])])])]):_vm._e()])]}}])}),_c('div',{staticClass:"chat-template-container"},_vm._l((_vm.chatsTemplate),function(chatTemplate,idx){return _c('DxButton',{key:chatTemplate.ID,class:idx == _vm.chatsTemplate.length - 1
          ? 'chat-template last-item'
          : 'chat-template',attrs:{"text":chatTemplate.text},on:{"click":function($event){return _vm.onClickChatTemplate(chatTemplate)}}})}),1),_c('div',{staticClass:"new-chat-container"},[_c('DxTextArea',{staticClass:"text-area-send",attrs:{"placeholder":_vm.$t("chat.hint_chat_message"),"height":"64","valueChangeEvent":"input","onKeyDown":_vm.onKeyDownMsg},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('DxButton',{staticClass:"btn-send",attrs:{"type":"success","icon":"email","width":"48","height":"48","onClick":_vm.onBtnSendMessageClick}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"receiver-info-container"},[_c('img',{staticClass:"receiver-propic",attrs:{"width":"32","height":"32","src":require("@/assets/images/profile-user.svg")}}),_c('span',[_c('b',[_vm._v("Planner X")])])])}]

export { render, staticRenderFns }