<template>
  <div class="content-block">
    <footer class="footer">
      <LanguageSelectSimple />
      <div
        :class="
          online ? 'network-indicator-online' : 'network-indicator-offline'
        "
      ></div>
      <span style="flex-grow: 1; text-align: right"
        >Copyright © {{ new Date().getFullYear() }}
        {{ this.$appInfo.company }}</span
      >
    </footer>
  </div>
</template>

<script>
import LanguageSelectSimple from "./language-select-simple";
import { mapState } from "pinia";
import { useGeneralStore } from "../stores/generalStore";

export default {
  computed: {
    ...mapState(useGeneralStore, ["online", "isDeviceReady"]),
  },
  components: {
    LanguageSelectSimple,
  },
};
</script>
<style lang="scss">
@import "../themes/generated/variables.base.scss";

.footer {
  display: flex;
  align-items: center;
  color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1em;
  padding-bottom: 1em;
}
.network-indicator-online {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: #00ff00;
  border-color: #006400;
  border-width: 1.5px;
  border-style: solid;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.network-indicator-offline {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: #ff0000;
  border-color: #8b0000;
  border-width: 1.5px;
  border-style: solid;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
</style>
