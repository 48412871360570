<template>
  <div id="splash-screen-container" class="single-card">
    <PscopeDisclosures
      :permissionTypes="[
        'background-locations',
      ]"
    />
  </div>
</template>

<script>
import util from "../commons/utility";
import PscopeDisclosures from "../components/disclosure/pscope-disclosures";

export default {
  mounted() {
    this.$nextTick(() => {
      if (util.isDisclosurePermanentlyAccepted()) {
        this.$router
          .replace({
            path: "/resource-selection",
          })
          .catch(() => {});
      }
    });
  },
  components: {
    PscopeDisclosures,
  },
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

@keyframes slideleft {
  from {
    background-position: 0%;
  }
  to {
    background-position: 90000%;
  }
}

@-webkit-keyframes slideleft {
  from {
    background-position: 0%;
  }
  to {
    background-position: 90000%;
  }
}

#splash-screen-container {
  background-image: url("../assets/images/bg.jpg");
  background-repeat: repeat-x;
  animation: slideleft 20000s infinite linear;
  -webkit-animation: slideleft 20000s infinite linear;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-attachment: fixed;
  position: relative;
  min-height: 100vh;
}
</style>
