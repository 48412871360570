<template>
  <div id="root">
    <div :class="cssClasses">
      <router-view
        name="layout"
        :title="title"
        :is-x-small="screen.isXSmall"
        :is-large="screen.isLarge"
      >
        <div class="content">
          <router-view name="content" />
        </div>
        <template #footer>
          <the-footer />
        </template>
      </router-view>
    </div>
  </div>
</template>

<script>
import util from "./commons/utility";
import TheFooter from "./components/the-footer";
import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import { useGeneralStore } from "./stores/generalStore";

function getScreenSizeInfo() {
  const screenSizes = sizes();

  return {
    isXSmall: screenSizes["screen-x-small"],
    isLarge: screenSizes["screen-large"],
    cssClasses: Object.keys(screenSizes).filter((cl) => screenSizes[cl]),
  };
}

export default {
  name: "app",

  mounted() {
    subscribe(this.screenSizeChanged);
    this.title = this.getToolbarTitle();

    const store = useGeneralStore();
    const isOnline = navigator.onLine;

    if (isOnline) {
      store.setOnline();
    } else {
      store.setOffline();
    }

    // for online
    window.addEventListener("online", this.handleOnline);
    window.addEventListener("offline", this.handleOffline);
  },
  beforeDestroy() {
    unsubscribe(this.screenSizeChanged);
    window.removeEventListener("online", this.handleOnline);
    window.removeEventListener("offline", this.handleOffline);
  },
  data() {
    return {
      title: this.getToolbarTitle(),
      screen: getScreenSizeInfo(),
      onLine: navigator.onLine,
    };
  },
  computed: {
    cssClasses() {
      return ["app"].concat(this.screen.cssClasses);
    },
  },
  watch: {},
  methods: {
    handleDeviceReady() {
      alert("device is ready");
      console.log("handleDeviceReady");

      const store = useGeneralStore();
      store.setDeviceReady();
    },
    handleOnline() {
      const store = useGeneralStore();
      store.setOnline();

      this.onLine = true;
    },
    handleOffline() {
      const store = useGeneralStore();
      store.setOffline();

      this.onLine = false;
    },
    getToolbarTitle() {
      const tenantName = util.getSelectedTenant()
        ? ` - ${util.getSelectedTenant().Name}`
        : "";
      return this.$appInfo.title + tenantName;
    },
    screenSizeChanged() {
      this.screen = getScreenSizeInfo();
    },
  },

  components: {
    TheFooter,
  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.app {
  @import "./themes/generated/variables.base.scss";
  background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
  width: 100%;
}

.dx-button-mode-contained.dx-button-default .dx-icon {
  color: white !important;
}
.dx-button-mode-contained.dx-button-success .dx-icon {
  color: white !important;
}
.dx-button-mode-contained.dx-button-danger .dx-icon {
  color: white !important;
}

.dx-toolbar-label > div {
  font-size: medium;
}
</style>
