<template>
  <div style="display: flex">
    <DxButton
      icon="photo"
      type="default"
      @click="this.onScan"
      style="
        padding-right: 0px;
        margin-right: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      "
    />
    <DxTextBox
      width="100vw"
      :placeholder="placeholder"
      maxLength="128"
      :showClearButton="true"
      :value="scanValue"
      @valueChanged="handleValueChanged"
      :disabled="disabled"
      :onFocusIn="onFocusChange"
      :onFocusOut="onFocusChange"
      style="
        padding-left: 0px;
        margin-left: 0px;
        padding-right: 0px;
        margin-right: 0px;
        border-radius: 0px;
      "
    />

    <DxButton
      :icon="this.submitIcon"
      type="success"
      @click="this.onSubmit"
      style="
        padding-left: 0px;
        margin-left: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      "
    />
  </div>
</template>

<script>
import DxTextBox from "devextreme-vue/text-box";
import DxButton from "devextreme-vue/button";
import { v4 as uuidv4 } from "uuid";

export default {
  mounted() {
    // this.$root.deviceReadyCallback.push(this.onItemScanned);
    this.$root.deviceReadyCallback.set(this.id, this.onItemScanned);
    // this.$root.deviceReadyCallback[this.id] = this.onItemScanned;
  },
  beforeDestroy() {
    this.$root.deviceReadyCallback.delete(this.id);
    // const idx = this.$root.deviceReadyCallback.indexOf(this.onItemScanned);
    // this.$root.deviceReadyCallback[this.id] = undefined;

    // this.$root.deviceReadyCallback.splice(idx, 1);
  },
  props: {
    autoClear: {
      type: Boolean,
      default: true,
    },
    submitIcon: {
      type: String,
      default: "arrowright",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Enter value...",
    },
  },
  data() {
    return {
      id: uuidv4(),
      isFocus: false,
      scanValue: null,
    };
  },
  methods: {
    onFocusChange(e) {
      if (e.event.type == "focusin") {
        this.isFocus = true;
      } else if (e.event.type == "focusout") {
        this.isFocus = false;
      }
    },
    onScan() {
      if (this.$root.deviceReady) {
        console.log("deviceReady : window", window.cordova);
        // eslint-disable-next-line no-undef
        console.log("deviceReady : cordova", cordova);
        // eslint-disable-next-line no-undef
        cordova.plugins.barcodeScanner.scan(
          (result) => {
            if (!result) return;
            let barcodeValue = result.text;

            if (!barcodeValue) return;
            barcodeValue = barcodeValue.trim();

            this.$emit("barcodeScanned", barcodeValue);
          },
          (error) => {
            this.$root.showError(`Scanning Error: ${error}`);
          },
          {
            preferFrontCamera: false, // iOS and Android
            showFlipCameraButton: true, // iOS and Android
            showTorchButton: true, // iOS and Android
            torchOn: false, // Android, launch with the torch switched on (if available)
            saveHistory: false, // Android, save scan history (default false)
            prompt: "Place a barcode inside the scan area", // Android
            resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
            orientation: "landscape", // Android only (portrait|landscape), default unset so it rotates with the device
            disableAnimations: true, // iOS
            disableSuccessBeep: false, // iOS and Android
          }
        );
      } else {
        this.$root.showError("Device is not ready...");
      }
    },
    onSubmit() {
      if (!this.scanValue) return;

      this.$emit("barcodeScanned", this.scanValue);
      if (this.autoClear) this.scanValue = null;
    },
    onItemScanned(barcode) {
      if (!barcode) return;

      this.scanValue = null;

      setTimeout(() => {
        this.scanValue = barcode;

        this.$emit("barcodeScanned", barcode);
        if (this.autoClear) {
          setTimeout(() => {
            this.scanValue = null;
          }, 1000);
        }
      }, 50);
      // if (this.isFocus && barcode != null) {
      //   this.scanValue = null;

      //   setTimeout(() => {
      //     this.scanValue = null;

      //     this.scanValue = barcode;

      //     this.$emit("barcodeScanned", barcode);
      //     if (this.autoClear) {
      //       setTimeout(() => {
      //         this.scanValue = null;
      //       }, 1000);
      //     }
      //   }, 50);
      // }
    },
    handleValueChanged(e) {
      const newValue = e.value;
      if (!newValue) return;

      this.scanValue = newValue;
    },
  },
  components: {
    DxTextBox,
    DxButton,
  },
};
</script>

<style lang="scss" scoped></style>
