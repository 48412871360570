<template>
  <div class="content-block dx-card responsive-paddings">
    <DxAccordion
      :data-source="items"
      :collapsible="true"
      :selected-item="null"
      :multiple="false"
    >
      <template #title="{ data }">
        <p>
          {{ $t("selected_data_accordion.lbl_resource") }} :
          <b>{{ data.selectedResourceId }}</b>
        </p>
      </template>
      <template #item="{ data }">
        <div>
          <div>
            <p class="selected-accordion-item">
              {{ $t("selected_data_accordion.lbl_allocation") }} :
              <b>{{ data.selectedAllocationId }}</b>
            </p>
            <p class="selected-accordion-item">
              {{ $t("selected_data_accordion.lbl_site") }} :
              <b>{{ data.selectedSiteId }}</b>
            </p>
            <p class="selected-accordion-item">
              {{ $t("selected_data_accordion.lbl_location") }} :
              <b
                >{{ data.currentLat }} , {{ data.currentLon }} ({{
                  data.locationAccuraccy
                }}
                m)</b
              >
            </p>
          </div>
          <DxLookup
            style="margin-top: 1em"
            v-if="ableChangeTask"
            :dataSource="allocationPlanDataSource"
            valueExpr="SiteID"
            displayExpr="SiteID"
            :placeholder="$t('selected_data_accordion.hint_site')"
            :onValueChanged="onAllocationPlanChanged"
            :value="selectedSite"
          >
            <DxDropDownOptions
              :close-on-outside-click="true"
              :show-title="false"
            />
          </DxLookup>
          <div style="margin-top: 1.5em; margin-bottom: 0.5em">
            <DxButton icon="refresh" :onClick="onClickChangeTask" />
            <DxButton icon="info" :onClick="showAllocationPlanDetail" />
          </div>
        </div>
      </template>
    </DxAccordion>
    <DxPopup
      ref="plan-detail-popup"
      :visible="planDetailVisible"
      :drag-enabled="false"
      :show-title="true"
      :show-close-button="true"
      :title="$t('selected_data_accordion.lbl_tasks_list')"
      :on-hidden="onPlanDetailPopupHidden"
      :close-on-outside-click="true"
      :height="'auto'"
      :width="'90%'"
    >
      <div>
        <DxDataGrid
          :showColumnLines="true"
          :showRowLines="true"
          :showBorders="true"
          :dataSource="planTasksDataSource"
          :columnHidingEnabled="true"
          :columns="[
            'TaskID',
            'DestSite',
            'ProductType',
            {
              dataType: 'number',
              caption: 'Qty',
              dataField: 'Qty',
            },
            'Status',
          ]"
          :editing="{
            allowAdding: false,
            allowUpdating: false,
            allowDeleteing: false,
          }"
        >
        </DxDataGrid>
      </div>
    </DxPopup>
  </div>
</template>

<script>
import DxAccordion from "devextreme-vue/accordion";
import utility from "../commons/utility";
import api from "../commons/api";
import DxButton from "devextreme-vue/button";
import { DxLookup, DxDropDownOptions } from "devextreme-vue/lookup";
import { DxDataGrid } from "devextreme-vue/data-grid";
import DxPopup from "devextreme-vue/popup";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import i18n from "./../i18n";

export default {
  model: {
    prop: "siteId",
    event: "update",
  },
  props: {
    taskId: {
      type: String,
      default: "-",
    },
    siteId: {
      type: String,
      default: "-",
    },
  },
  mounted() {
    if (utility.hasSelectedSite()) {
      const selectedSite = utility.getSelectedSite();
      if (selectedSite) {
        this.selectedSite = selectedSite.SiteID;
        this.updateSelectedSiteId(selectedSite.SiteID);
      }
    }
    this.loadAllocationPlans();

    this.getCurrentLocation();
  },

  computed: {
    selectedSiteId() {
      return this.items[0].selectedSiteId;
    },
  },

  data() {
    const resourceId = utility.getSelectedResourceId();
    const allocationId = utility.getSelectedAllocationId();

    const _http = this.$http;

    const planTasksDataSource = new DataSource({
      key: "TaskID",
      load() {
        // do nothing for now
        // const allocationId = utility.getSelectedAllocationId();
        // const selectedSite = utility.getSelectedSite();
        // const siteId = selectedSite == null ? null : selectedSite.SiteID;
        // return _http.get(`${api.API_URL}${api.GET_TASK_BY_ALLOCATION_URL}`, {
        //   params: {
        //     allocationID: allocationId,
        //     SiteID: siteId,
        //   },
        // });
      },
    });

    let data = {
      selectedResourceId: resourceId ? resourceId : "-",
      selectedAllocationId: allocationId ? allocationId : "-",
      selectedSiteId: this.siteId,
      currentLat: 0.0,
      currentLon: 0.0,
      locationAccuraccy: "-",
    };

    return {
      planTasksDataSource,
      ableChangeTask: false,
      allocationPlanDataSource: undefined,
      items: [data],
      planDetailVisible: false,
      selectedSite: this.siteId,
      currentLat: null,
      currentLon: null,
    };
  },

  methods: {
    getCurrentLocation() {
      if (this.isLocationReady()) {
        if (!this.isLocationRetrieved()) {
          navigator.geolocation.getCurrentPosition(
            this.onSuccessGetLocation,
            this.onFailedGetLocation,
            { maximumAge: 0, timeout: 10000, enableHighAccuracy: true }
          );

          return;
        }
      }

      setTimeout(() => {
        this.getCurrentLocation();
      }, 1000);
    },
    GetTimezone() {
      let timedifference = new Date().getTimezoneOffset() / 60;
      let AbsVal = Math.abs(timedifference);
      let OutTz = AbsVal;

      if (AbsVal < 10) {
        OutTz = "0" + AbsVal;
      }

      if (timedifference < 0) {
        //GMT +
        OutTz = "+" + OutTz;
      } else if (timedifference > 0) {
        //GMT -
        OutTz = "-" + OutTz;
      }

      return " " + OutTz + ":00";
    },
    onSuccessGetLocation(position) {
      api.currentLatExport = position.coords.latitude;
      api.currentLonExport = position.coords.longitude;
      api.GPS_Time =
        this.$moment().format("YYYY-MM-DD H:mm:ss") + this.GetTimezone();

      this.currentLat = position.coords.latitude;
      this.currentLon = position.coords.longitude;
      this.items[0].currentLat = this.currentLat;
      this.items[0].currentLon = this.currentLon;
      this.items[0].locationAccuraccy = Math.round(position.coords.accuracy);

      this.$emit("onCurrentLocationChanged", this.currentLat, this.currentLon);
      return;
    },
    onFailedGetLocation(error) {
      console.error(error);
    },
    isLocationRetrieved() {
      return this.currentLat != null && this.currentlon != null;
    },
    isLocationReady() {
      return (
        navigator !== undefined &&
        navigator !== null &&
        navigator.geolocation !== undefined &&
        navigator.geolocation !== null
      );
    },
    loadAllocationPlans() {
      this.$http
        .get(`${api.API_URL}${api.GET_ALLOCATION_PLAN_URL}`, {
          params: {
            allocationID: utility.getSelectedAllocationId(),
          },
        })
        .then((resp) => {
          this.allocationPlanDataSource = new DataSource({
            key: "SiteID",
            paginate: false,
            searchExpr: ["SiteID"],
            store: new ArrayStore({
              key: "SiteID",
              data: [...resp.data],
            }),
          });
        });
    },
    onAllocationPlanChanged(e) {
      const mSiteID = e.value;

      this.allocationPlanDataSource
        .store()
        .byKey(mSiteID)
        .then((data) => {
          utility.saveSelectedSite(data);
          this.selectedSite = data.SiteID;
          this.updateSelectedSiteId(data.SiteID);

          this.planTasksDataSource.reload();
        });
    },
    showAllocationPlanDetail() {
      this.planDetailVisible = true;
    },
    onPlanDetailPopupHidden() {
      this.planDetailVisible = false;
    },
    loadSelectedAllocation(resourceId) {
      const params = {
        resourceID: resourceId,
      };
      this.$http
        .get(api.GET_SELECTED_JOB_URL, { params })
        .then((res) => {
          let data = res.data;
          if (data != null && typeof data === "object") {
            this.updateSelectedSiteId(data.SiteID);
          } else {
            this.updateSelectedSiteId("-");
          }
        })
        .catch((_) => {
          this.updateSelectedSiteId("-");
        });
    },
    updateSelectedSiteId(siteId) {
      let newData = { ...this.items[0] };
      newData.selectedSiteId = siteId;

      this.items = [newData];

      this.$emit("update", siteId);
    },

    onClickChangeTask() {
      this.ableChangeTask = !this.ableChangeTask;
    },
  },

  components: {
    DxAccordion,
    DxButton,
    DxLookup,
    DxPopup,
    DxDropDownOptions,
    DxDataGrid,
  },
};
</script>

<style lang="scss" scoped>
.dx-template-wrapper.dx-item-content.dx-accordion-item-title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.dx-template-wrapper.dx-item-content.dx-accordion-item-body {
  margin-top: 8px;
  margin-bottom: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.selected-accordion-item {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}
</style>
