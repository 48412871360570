var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"content-block"},[_vm._v("Truck "+_vm._s(_vm.pageTitle))]),_c('SelectedDataAccordion'),_c('div',{staticClass:"content-block"},[_c('div',{staticClass:"dx-card responsive-paddings"},[_c('div',{staticClass:"dx-field"},[_c('div',{staticClass:"dx-field-key"},[_c('DxButton',{attrs:{"icon":"back","type":"default"},on:{"click":_vm.handleBackToActivityPlansListBtnClick}})],1)]),_c('ScanItemTextBox',{staticClass:"form-input",attrs:{"placeholder":"Enter or Scan SSCC...","disabled":!_vm.isAbleToScanItems},on:{"barcodeScanned":_vm.onItemIDScanned}}),_c('DxDataGrid',{staticClass:"form-input2",attrs:{"showColumnLines":true,"showRowLines":true,"showBorders":true,"data-source":_vm.taskItemsDataSource,"columns":[
          'SSCC',
          'UOM',
          {
            caption: 'Quantity',
            dataField: 'Original_Qty',
          },
          {
            dataType: 'number',
            caption: this.pageTitle,
            dataField: 'count',
            cellTemplate: 'countCelltemplate',
          },
          {
            dataType: 'boolean',
            caption: 'All ' + this.pageTitle + 'ed',
            name: 'isScanned',
            calculateCellValue: this.isWholeItemLineScanned,
          } ],"editing":{
          allowAdding: false,
          allowUpdating: false,
          allowDeleteing: false,
        }},scopedSlots:_vm._u([{key:"itemDetailTemplate",fn:function(ref){
        var data = ref.data;
return [_c('div',[_c('p',{staticClass:"manifest-item-detail-info"},[_vm._v(" Item ID : "),_c('b',[_vm._v(_vm._s(data.data.ItemID))])]),_c('p',{staticClass:"manifest-item-detail-info"},[_vm._v(" Line ID: "),_c('b',[_vm._v(_vm._s(data.data.LineID))])]),_c('p',{staticClass:"manifest-item-detail-info"},[_vm._v(" SSCC : "),_c('b',[_vm._v(_vm._s(data.data.SSCC))])]),_c('p',{staticClass:"manifest-item-detail-info"},[_vm._v(" UOM : "),_c('b',[_vm._v(_vm._s(data.data.UOM))])]),_c('p',{staticClass:"manifest-item-detail-info"},[_vm._v(" Quantity : "),_c('b',[_vm._v(_vm._s(data.data.Original_Qty))])]),_c('p',{staticClass:"manifest-item-detail-info"},[_vm._v(" Load Quantity : "),_c('b',[_vm._v(_vm._s(data.data.Load_Qty))])]),_c('p',{staticClass:"manifest-item-detail-info"},[_vm._v(" Unload Quantity : "),_c('b',[_vm._v(_vm._s(data.data.Unload_QtY))])]),_c('p',{staticClass:"manifest-item-detail-info"},[_vm._v(" Count : "),_c('b',[_vm._v(_vm._s(data.data.count))])]),_c('p',{staticClass:"manifest-item-detail-info"},[_vm._v(" All Loaded : "),_c('DxCheckBox',{attrs:{"value":data.data.isScanned,"readOnly":true}})],1)])]}},{key:"countCelltemplate",fn:function(ref){
        var data = ref.data;
return [_c('span',{class:_vm.getOverPickClassname(data.data)},[_vm._v(_vm._s(data.data.count))])]}}])},[_c('DxMasterDetail',{attrs:{"enabled":true,"template":"itemDetailTemplate"}})],1),_c('DxButton',{staticClass:"form-input",attrs:{"text":'Finish ' + _vm.pageTitle + ' Truck',"type":"success","width":"100%","disabled":!_vm.isAbleToScanItems,"onClick":_vm.onFinishLoadUnloadTruckClicked}}),_c('DxButton',{staticClass:"form-input",attrs:{"text":"Cancel","type":"danger","width":"100%","onClick":_vm.cancelButtonClicked}})],1)]),(_vm.isPodVisible)?_c('Pod',{attrs:{"hidden":_vm.handlePodHidden,"tasks":_vm.tasks,"truckMode":_vm.truckMode},on:{"tasksCompleted":_vm.handleTasksCompleted}}):_vm._e(),_c('ConfirmQuantity',{attrs:{"visible":_vm.isConfirmQuantityVisible,"quantity":_vm.confirmQuantity,"barcode":_vm.confirmQantityBarcode},on:{"hidden":_vm.handleConfirmQuantityCancel,"onCancel":_vm.handleConfirmQuantityCancel,"onConfirm":_vm.handleConfirmQuantity}}),_c('DxLoadPanel',{attrs:{"visible":_vm.isLoading,"message":"Please wait...","position":{ of: '#content-container' },"close-on-outside-click":false}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }