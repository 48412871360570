<template>
  <div>
    <h2 class="content-block">Truck {{ pageTitle }}</h2>
    <SelectedDataAccordion />
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <div class="dx-field">
          <div class="dx-field-key">
            <DxButton
              icon="back"
              type="default"
              @click="handleBackToActivityPlansListBtnClick"
            />
          </div>
        </div>
        <ScanItemTextBox
          class="form-input"
          placeholder="Enter or Scan SSCC..."
          @barcodeScanned="onItemIDScanned"
          :disabled="!isAbleToScanItems"
        />
        <DxDataGrid
          class="form-input2"
          :showColumnLines="true"
          :showRowLines="true"
          :showBorders="true"
          :data-source="taskItemsDataSource"
          :columns="[
            'SSCC',
            'UOM',
            {
              caption: 'Quantity',
              dataField: 'Original_Qty',
            },
            {
              dataType: 'number',
              caption: this.pageTitle,
              dataField: 'count',
              cellTemplate: 'countCelltemplate',
            },
            {
              dataType: 'boolean',
              caption: 'All ' + this.pageTitle + 'ed',
              name: 'isScanned',
              calculateCellValue: this.isWholeItemLineScanned,
            },
          ]"
          :editing="{
            allowAdding: false,
            allowUpdating: false,
            allowDeleteing: false,
          }"
        >
          <DxMasterDetail :enabled="true" template="itemDetailTemplate" />
          <template #itemDetailTemplate="{ data }">
            <div>
              <p class="manifest-item-detail-info">
                Item ID : <b>{{ data.data.ItemID }}</b>
              </p>
              <p class="manifest-item-detail-info">
                Line ID: <b>{{ data.data.LineID }}</b>
              </p>

              <p class="manifest-item-detail-info">
                SSCC : <b>{{ data.data.SSCC }}</b>
              </p>
              <p class="manifest-item-detail-info">
                UOM : <b>{{ data.data.UOM }}</b>
              </p>
              <p class="manifest-item-detail-info">
                Quantity : <b>{{ data.data.Original_Qty }}</b>
              </p>
              <p class="manifest-item-detail-info">
                Load Quantity : <b>{{ data.data.Load_Qty }}</b>
              </p>
              <p class="manifest-item-detail-info">
                Unload Quantity : <b>{{ data.data.Unload_QtY }}</b>
              </p>
              <p class="manifest-item-detail-info">
                Count : <b>{{ data.data.count }}</b>
              </p>
              <p class="manifest-item-detail-info">
                All Loaded :
                <DxCheckBox :value="data.data.isScanned" :readOnly="true" />
              </p>
            </div>
          </template>
          <template #countCelltemplate="{ data }">
            <span :class="getOverPickClassname(data.data)">{{
              data.data.count
            }}</span>
          </template>
        </DxDataGrid>
        <DxButton
          class="form-input"
          :text="'Finish ' + pageTitle + ' Truck'"
          type="success"
          width="100%"
          :disabled="!isAbleToScanItems"
          :onClick="onFinishLoadUnloadTruckClicked"
        />
        <DxButton
          class="form-input"
          text="Cancel"
          type="danger"
          width="100%"
          :onClick="cancelButtonClicked"
        />
      </div>
    </div>
    <Pod
      v-if="isPodVisible"
      :hidden="handlePodHidden"
      :tasks="tasks"
      :truckMode="truckMode"
      @tasksCompleted="handleTasksCompleted"
    />
    <ConfirmQuantity
      :visible="isConfirmQuantityVisible"
      :quantity="confirmQuantity"
      :barcode="confirmQantityBarcode"
      @hidden="handleConfirmQuantityCancel"
      @onCancel="handleConfirmQuantityCancel"
      @onConfirm="handleConfirmQuantity"
    />
    <DxLoadPanel
      :visible="isLoading"
      message="Please wait..."
      :position="{ of: '#content-container' }"
      :close-on-outside-click="false"
    />
  </div>
</template>
<script>
import api from "../../commons/api";
import service from "../../commons/service";
import SelectedDataAccordion from "../../components/selected-data-accordion";
import ScanItemTextBox from "../../components/scan-item-textbox";
import { DxDataGrid, DxMasterDetail } from "devextreme-vue/data-grid";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import DxButton from "devextreme-vue/button";
import DxCheckBox from "devextreme-vue/check-box";
import audioFile from "../../assets/audio/notify.wav";
import util from "../../commons/utility";
import ConfirmQuantity from "./confirm-quantity.vue";
import Pod from "../activity-plan/pod";
import DxLoadPanel from "devextreme-vue/load-panel";

export default {
  mounted() {
    const truckMode = this.$route.params
      ? this.$route.params.truckMode
        ? this.$route.params.truckMode
        : "load"
      : "load";

    const tasks = this.$route.params
      ? this.$route.params.tasks
        ? this.$route.params.tasks
        : []
      : [];

    const childTaskIds = tasks.map((task) => task.GuidTaskID);
    this.truckMode = truckMode;
    this.tasks = tasks;
    this.childtaskIds = childTaskIds;
    this.pageTitle = truckMode == "load" ? "Load" : "Unload";

    this.resetState();
    this.loadTaskItemsDataFromRemote();
  },
  data() {
    const taskItemsArrayStore = new ArrayStore({
      key: "SSCC",
      data: [],
    });

    const taskItemsDataSource = new DataSource({
      store: taskItemsArrayStore,
    });

    return {
      isLoading: false,
      truckMode: null,
      tasks: [],
      childtaskIds: [],
      scannedBarcode: null,
      isConfirmQuantityVisible: false,
      confirmQantityBarcode: null,
      confirmQuantity: null,
      isPodVisible: false,
      audio: new Audio(audioFile),
      taskItemsDataSource: taskItemsDataSource,
      taskItems: [],
      pageTitle: "",
    };
  },
  computed: {
    isAbleToScanItems() {
      return this.taskItems.length > 0;
    },
    selectedTaskSeq() {
      const routeParams = this.$route.params;
      return routeParams["selectedTaskSeq"];
    },
  },
  methods: {
    handleBackToActivityPlansListBtnClick() {
      this.$router
        .replace({
          name: "activity-plan",
          query: {
            ...(this.selectedTaskSeq != null
              ? { selectedTaskSeq: this.selectedTaskSeq }
              : null),
          },
        })
        .catch(() => {});
    },
    isWholeItemLineScanned(row) {
      let loadedUnloadedQuantity = 0;
      const originalQuantity = isNaN(row.Original_Qty) ? 0 : row.Original_Qty;

      if (this.truckMode == "load") {
        loadedUnloadedQuantity = isNaN(row.Load_Qty) ? 0 : row.Load_Qty;
      } else {
        loadedUnloadedQuantity = isNaN(row.Unload_QtY) ? 0 : row.Unload_QtY;
      }

      return loadedUnloadedQuantity >= originalQuantity;
    },
    setLoading(isLoading) {
      this.isLoading = isLoading;
    },
    loadTaskItemsDataFromRemote() {
      this.setLoading(true);

      service
        .getTaskLinesByTaskIds(this.childtaskIds, true)
        .then((response) => {
          this.setLoading(false);
          this.taskItems = response.data ?? [];

          this.handleTaskLines();
        })
        .catch((_) => {
          this.setLoading(false);
        });
    },
    handleTaskLines() {
      this.taskItemsDataSource.store().clear();
      this.taskItems.forEach((item) => {
        if (this.truckMode == "load") {
          item.count = item.Load_Qty;
        } else {
          item.count = item.Unload_QtY;
        }

        if (!item.count) {
          item.count = 0;
        }
        this.taskItemsDataSource.store().insert(item);
      });

      this.taskItemsDataSource.reload();
    },
    playAudio() {
      this.audio.pause();
      this.audio.currentTime = 0;
      this.audio.play();
    },
    onItemIDScanned(barcode, confirmedQty) {
      this.scannedBarcode = barcode;

      this.taskItemsDataSource
        .store()
        .byKey(barcode)
        .then(
          async (dataItem) => {
            if (dataItem) {
              let updateTaskLineRequest = {};
              const originalQty = isNaN(dataItem.Original_Qty)
                ? 0
                : dataItem.Original_Qty;

              if (originalQty > 1 && confirmedQty == null) {
                this.showConfirmTruckLoadUnloadQty(barcode, originalQty);
                return;
              }

              const updatedQuantity = confirmedQty ?? 1;
              updateTaskLineRequest.LineID = dataItem.LineID;
              updateTaskLineRequest.TaskID = dataItem.TaskID;
              updateTaskLineRequest.SSCC = dataItem.SSCC;
              updateTaskLineRequest.Manifest = this.manifestId;
              updateTaskLineRequest.GPS_Time = api.GPS_Time;
              updateTaskLineRequest.Latitude = api.currentLatExport;
              updateTaskLineRequest.Longitude = api.currentLonExport;
              updateTaskLineRequest.Type = this.pageTitle;
              updateTaskLineRequest.Quantity = updatedQuantity;
              updateTaskLineRequest.ResourceID = util.getSelectedResourceId();
              updateTaskLineRequest.AllocationID =
                util.getSelectedAllocationId();

              this.setLoading(true);
              service
                .postTaskLine(updateTaskLineRequest, true)
                .then((res) => {
                  this.setLoading(false);

                  if (res.data[0].ErrorNumber == 50000) {
                    this.playAudio();
                    dataItem.count = updatedQuantity;

                    if (this.truckMode == "load") {
                      dataItem.Load_Qty = updatedQuantity;
                    } else {
                      dataItem.Unload_QtY = updatedQuantity;
                    }
                  } else {
                    this.$root.showError(
                      "Error submit Task Lines, Message: " +
                        res.data[0].ErrorMessage
                    );
                  }
                })
                .catch((err) => {
                  this.setLoading(false);
                  this.$root.showError("Server Error...");
                });
            }
          },
          (e) => {
            // assume all error is data item not found for now
            this.$root.showWarning("SSCC not found...");
          }
        );
    },
    onFinishLoadUnloadTruckClicked() {
      let itemCount = 0;
      let scannedItemCount = 0;
      const items = this.taskItemsDataSource.store()._array;

      items.forEach((item) => {
        itemCount++;
        if (this.isWholeItemLineScanned(item)) {
          scannedItemCount++;
        }
      });

      if (itemCount > scannedItemCount) {
        // show warning
        this.$root
          .showConfirmationDialog(
            "Confirm",
            `<strong>${scannedItemCount}</strong> / <strong>${itemCount}</strong> items has been scanned, do you want to skip scanning <strong>${
              itemCount - scannedItemCount
            }</strong> items and continue?`
          )
          .then((result) => {
            if (result) this.handleScanConfirmed();
          });
      } else {
        // show warning
        this.$root
          .showConfirmationDialog(
            "Confirm",
            "All items has been scanned, do you want to continue?"
          )
          .then((result) => {
            if (result) this.handleScanConfirmed();
          });
      }
    },
    handleSkipScanConfirmed() {
      this.$root.showSuccess("Success " + this.pageTitle + " Truck");
      this.showDialogToSignPOD();
    },
    handleScanConfirmed() {
      const itemsToValidate = [];
      const skippedItems = new Set();

      this.taskItemsDataSource.store()._array.forEach((itemLine, index) => {
        const isSkipped = !this.isWholeItemLineScanned(itemLine);
        if (isSkipped) {
          skippedItems.add(itemLine.TaskID);
        }
      });

      this.taskItemsDataSource.store()._array.forEach((itemLine, index) => {
        const isSkipped = skippedItems.has(itemLine.TaskID);

        if (!isSkipped) {
          const item = {};
          const quantity =
            this.truckMode == "load" ? itemLine.Load_Qty : itemLine.Unload_QtY;

          item.LineID = itemLine.LineID;
          item.TaskID = itemLine.TaskID;
          item.SSCC = itemLine.SSCC;
          item.Manifest = this.manifestId;
          item.GPS_Time = api.GPS_Time;
          item.Latitude = api.currentLatExport;
          item.Longitude = api.currentLonExport;
          item.Type = this.pageTitle;
          item.Quantity = quantity;
          item.ResourceID = util.getSelectedResourceId();
          item.AllocationID = util.getSelectedAllocationId();

          itemsToValidate.push(item);
        }
      });

      if (itemsToValidate.length === 0) {
        this.handleSkipScanConfirmed();
        return;
      }

      this.setLoading(true);
      service
        .validateTaskLines(itemsToValidate, true)
        .then((res) => {
          this.setLoading(false);
          if (res.data.ErrorNumber == 50000) {
            this.$root.showSuccess("Success " + this.pageTitle + " Truck");
            this.resetState();
            this.showDialogToSignPOD();
          } else {
            this.$root.showError(
              "Error Validate Task Lines, Message: " + res.data.ErrorMessage
            );
          }
        })
        .catch((err) => {
          this.setLoading(false);
          this.$root.showError("Server Error...");
        });
    },
    resetState() {
      this.isLoading = false;
      this.scannedBarcode = null;
      this.isConfirmQuantityVisible = false;
      this.confirmQantityBarcode = null;
      this.confirmQuantity = null;

      this.taskItemsDataSource.store().clear();
      this.taskItemsDataSource.reload();
    },
    cancelButtonClicked() {
      this.resetState();
      this.handleBackToActivityPlansListBtnClick();
    },
    getOverPickClassname(data) {
      return data.count > data.Original_Qty ? "scan-count-over-quantity" : "";
    },
    showConfirmTruckLoadUnloadQty(barcode, qty) {
      this.isConfirmQuantityVisible = true;
      this.confirmQantityBarcode = barcode;
      this.confirmQuantity = qty;
    },
    showDialogToSignPOD() {
      this.$root
        .showConfirmationDialog(
          "Confirm",
          "Process finished, do you want to sign POD?"
        )
        .then((result) => {
          // show pod dialog
          if (result) this.isPodVisible = true;
        });
    },
    handlePodHidden() {
      this.isPodVisible = false;
    },
    handleTasksCompleted() {
      this.isPodVisible = false;
      // redirect back
      this.handleBackToActivityPlansListBtnClick();
    },
    handleConfirmQuantityCancel() {
      this.isConfirmQuantityVisible = false;
      this.confirmQantityBarcode = null;
      this.confirmQuantity = null;
    },
    handleConfirmQuantity(barcode, confirmedQty) {
      this.isConfirmQuantityVisible = false;
      this.confirmQantityBarcode = null;
      this.confirmQuantity = null;

      this.onItemIDScanned(barcode, confirmedQty);
    },
  },
  components: {
    SelectedDataAccordion,
    ScanItemTextBox,
    DxDataGrid,
    DxMasterDetail,
    DxButton,
    DxCheckBox,
    Pod,
    ConfirmQuantity,
    DxLoadPanel,
  },
};
</script>

<style lang="scss">
.form-input {
  margin-bottom: 1em;
}

.form-input2 {
  margin-top: 4em;
  margin-bottom: 4em;
}

.dx-cell-focus-disabled.dx-master-detail-cell {
  padding: 0 1em !important;
}

.manifest-item-detail-info {
  margin: 0.7em 0;
  font-size: 0.8em;
}

.scan-count-over-quantity {
  color: rgb(211, 30, 30);
}

.dx-popup-content p {
  margin-bottom: 10px;
  margin-top: 0;
}

.dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection.dx-popup-bottom {
  padding-left: 1em;
  padding-right: 1em;
}
</style>
