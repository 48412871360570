<template>
  <dx-scroll-view height="100%" width="100%" class="with-footer">
     <slot />
  </dx-scroll-view>
</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";

export default {
  components: {
    DxScrollView
  }
};
</script>

<style lang="scss">

</style>
