<template>
  <div>
    <h2 class="content-block">GPS</h2>
    <SelectedDataAccordion />
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        in maintenance
      </div>
    </div>
  </div>
</template>

<script>
import SelectedDataAccordion from '../components/selected-data-accordion';

export default {
  components: {
    SelectedDataAccordion
  },
};

</script>

<style lang="scss">
</style>