<template>
  <div>
    <h2 class="content-block">{{ $t("send_message.title") }}</h2>
    <SelectedDataAccordion />
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <div class="dx-fieldset">
          <div class="dx-field">
            <DxSelectBox
              :placeholder="$t('send_message.hint_select_task')"
              :data-source="taskDataSrouce"
              v-model="selectedTask"
              :onValueChanged="selectedTaskChanged"
              displayExpr="TaskID"
            />
          </div>
          <div class="send-message-field dx-field">
            <div class="dx-field-label">
              {{ $t("send_message.lbl_site_id") }}
            </div>
            <div class="dx-field-value">
              <DxTextBox
                :placeholder="$t('send_message.hint_site_id')"
                :read-only="true"
                v-model="selectedSiteId"
              />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">
              {{ $t("send_message.lbl_notification") }}
            </div>
            <div class="dx-field-value">
              <DxTextBox
                max-length="50"
                :placeholder="$t('send_message.hint_notification')"
                v-model="phoneOrEmail"
              />
            </div>
          </div>
          <div class="send-message-field dx-field">
            <DxSelectBox
              :placeholder="$t('send_message.hint_select_template')"
              :data-source="messageTemplateDataSource"
              v-model="selectedTemplate"
              :onValueChanged="selectedMessageTemplateChanged"
              displayExpr="Msg"
            />
          </div>
          <div class="send-message-field dx-field">
            <DxTextArea
              height="100"
              max-length="500"
              :placeholder="$t('send_message.hint_message')"
              valueChangeEvent="keyup"
              v-model="notificationMessage"
            />
          </div>
          <div class="send-message-field dx-field">
            <DxButton
              width="100%"
              :text="$t('send_message.btn_send')"
              icon="message"
              type="success"
              :onClick="sendMessage"
              :disabled="!isValid || isLoading"
            />
          </div>
          <div class="send-message-field dx-field">
            <DxButton
              width="100%"
              :text="$t('send_message.btn_cancel')"
              icon="remove"
              type="danger"
              :onClick="cancelSendMessage"
              :disabled="isLoading"
            />
          </div>
        </div>
      </div>
    </div>
    <DxLoadPanel
      :visible="isLoading"
      :show-indicator="true"
      :show-pane="true"
      :shading="false"
    />
  </div>
</template>

<script>
import SelectedDataAccordion from "../components/selected-data-accordion";
import DxTextBox from "devextreme-vue/text-box";
import DxSelectBox from "devextreme-vue/select-box";
import DxTextArea from "devextreme-vue/text-area";
import DxButton from "devextreme-vue/button";
import api from "../commons/api";
import DataSource from "devextreme/data/data_source";
import utility from "../commons/utility";
import DxLoadPanel from "devextreme-vue/load-panel";

export default {
  methods: {
    selectedTaskChanged(e) {
      if (e.value) {
        this.selectedSiteId = e.value.SiteID;

        this.loadPhoneOrEmail(this.selectedSiteId);
      }
    },
    loadPhoneOrEmail(siteId) {
      this.showLoading(true);
      this.$http
        .get(api.GET_SITE_DETAIL, { params: { siteID: siteId } })
        .then((resp) => {
          this.showLoading(false);
          let phoneOrEmail = resp.data ? resp.data.Notification : null;
          this.phoneOrEmail = phoneOrEmail;

          if (!phoneOrEmail) {
            this.$root.showError("Phone or email not found...");
          }
        })
        .catch((_) => {
          this.showLoading(false);
          this.phoneOrEmail = null;

          this.$root.showError("Cannot get phone or email...");
        });
    },
    selectedMessageTemplateChanged(e) {
      this.notificationMessage = e.value ? e.value.Msg : null;
    },
    sendMessage() {
      this.showLoading(true);

      const receiverType = this.getReceiverType(this.phoneOrEmail);

      if (!receiverType) {
        this.$root.showError("Cannot determine receiver type...");
        return;
      }

      const params = {
        TaskID: this.selectedTask.TaskID,
        SiteID: this.selectedSiteId,
        Notification: this.phoneOrEmail,
        NotificationType: receiverType,
        Message: this.notificationMessage,
      };

      this.$http
        .post(api.POST_JOB_NOTIFICATION_MESSAGE, params)
        .then((_) => {
          this.showLoading(false);

          this.$root.showSuccess("Success send notification message...");
          this.resetInput();
        })
        .catch((err) => {
          this.showLoading(false);

          this.$root.showError("Failed to send notification message...");
        });
    },
    cancelSendMessage() {
      this.resetInput();
    },
    resetInput() {
      this.selectedTask = undefined;
      this.selectedSiteId = undefined;
      this.selectedTemplate = undefined;
      this.phoneOrEmail = undefined;
      this.notificationMessage = undefined;
    },
    showLoading(isLoading) {
      this.isLoading = isLoading;
    },
    getReceiverType(phoneOrEmail) {
      if (!phoneOrEmail) return null;

      if (phoneOrEmail.includes("@")) {
        return "Email";
      }

      return "SMS";
    },
  },

  data() {
    const _http = this.$http;

    return {
      isLoading: false,

      selectedTask: undefined,
      selectedSiteId: undefined,
      selectedTemplate: undefined,
      phoneOrEmail: undefined,
      notificationMessage: undefined,

      taskDataSrouce: new DataSource({
        load: function () {
          const params = {
            resourceID: utility.getSelectedResourceId(),
          };
          return _http.get(api.GET_JOB_LIST_URL, { params });
        },
      }),

      messageTemplateDataSource: new DataSource({
        load: function () {
          return _http.get(api.GET_NOTIFICATION_TEMPLATE_URL);
        },
      }),
    };
  },

  computed: {
    isValid() {
      return (
        this.selectedTask != null &&
        this.selectedSiteId != null &&
        this.phoneOrEmail != null &&
        this.notificationMessage
      );
    },
  },
  components: {
    DxLoadPanel,
    SelectedDataAccordion,
    DxTextBox,
    DxSelectBox,
    DxTextArea,
    DxButton,
  },
};
</script>

<style lang="scss" scoped>
.send-message-field {
  margin-top: 1.5em;
}
</style>
