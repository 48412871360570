<template>
  <div>
    <DxPopup
      :visible="visible"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-title="true"
      :width="280"
      :height="200"
      :show-close-button="true"
      @hidden="handleHidden"
      container=".dx-viewport"
      title="Confirm Quantity Loaded/Unloaded"
    >
      <DxPosition at="center" my="center" collision="fit" />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="before"
        :options="{
          icon: 'check',
          text: 'Yes',
          type: 'success',
          onClick: handleConfirmClick,
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="{
          icon: 'close',
          text: 'No',
          type: 'danger',
          onClick: handleCancelClick,
        }"
      />
      <DxNumberBox
        ref="confirm-quantity-numberbox"
        :show-spin-buttons="false"
        :show-clear-button="false"
        :min="0"
        :value="null"
        :max="9999"
      />
    </DxPopup>
  </div>
</template>
<script>
import { DxPopup, DxPosition, DxToolbarItem } from "devextreme-vue/popup";
import { DxNumberBox } from "devextreme-vue/number-box";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    quantity: {
      type: Number,
      default: -1,
    },
    barcode: {
      type: String,
      default: null,
    },
  },
  methods: {
    handleHidden() {
      const dxNumberBoxConfirmQuantity =
        this.$refs["confirm-quantity-numberbox"].instance;
      dxNumberBoxConfirmQuantity.option("value", null);
      
      this.$emit("hidden");
    },
    handleConfirmClick() {
      const dxNumberBoxConfirmQuantity =
        this.$refs["confirm-quantity-numberbox"].instance;
      const inputQty = dxNumberBoxConfirmQuantity.option("value") ?? -1;

      if (inputQty > this.quantity || inputQty <= 0) {
        this.$root.showError("Quantity not valid, please re-confirm.");
        return;
      }
      dxNumberBoxConfirmQuantity.option("value", null);
      this.$emit("onConfirm", this.barcode, inputQty);
    },
    handleCancelClick() {
      const dxNumberBoxConfirmQuantity =
        this.$refs["confirm-quantity-numberbox"].instance;
      dxNumberBoxConfirmQuantity.option("value", null);

      this.$emit("onCancel");
    },
  },
  components: {
    DxPopup,
    DxPosition,
    DxToolbarItem,
    DxNumberBox,
  },
};
</script>
<style lang=""></style>
