<template>
  <div>
    <DxActionSheet
      :visible="messageTemplatePopupVisible"
      :data-source="messageTemplates"
      :title="$t('perform_activity.title_select_message_template')"
      @cancelClick="onMessageTemplatesHidden"
      @itemClick="selectedMessageTemplateChanged"
    />

    <div id="content-container">
      <h2 class="content-block" style="margin-bottom: 0px">
        {{ $t("perform_activity.title") }}
      </h2>
      <h5
        class="content-block dx-theme-success-color-as-text-color"
        style="margin-top: 0px; font-weight: 700"
        v-if="selectedTaskId"
      >
        ({{ selectedTaskId }})
      </h5>
      <SelectedDataAccordion
        @onCurrentLocationChanged="onCurrentLocationChanged"
      />
      <CurrentLocation />
      <div class="content-block dx-card responsive-paddings">
        <div class="dx-fieldset">
          <div class="dx-field" v-if="selectedTaskId">
            <div class="dx-field-key">
              <DxButton
                icon="back"
                type="default"
                @click="backToActivitiesList"
              />
            </div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">
              {{ $t("perform_activity.lbl_action") }}
            </div>
            <div class="dx-field-value">
              <DxSelectBox
                :placeholder="$t('perform_activity.hint_action')"
                :data-source="activityTypesDataSource"
                :display-expr="(item) => getActionName(item)"
                :search-enabled="selectActionOptions.searchEnabled"
                :search-expr="selectActionOptions.searchExpr"
                :group="selectActionOptions.group"
                v-model="selectedAction"
                :onValueChanged="selectedActionChanged"
              />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">
              {{ $t("perform_activity.lbl_message_template") }}
            </div>
            <div class="dx-field-value">
              <DxButton
                style="float: left"
                :text="$t('perform_activity.hint_message_template')"
                type="default"
                icon="copy"
                :disabled="!isActionValid"
                @click="onShowMessageTemplates"
              />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">
              {{ $t("perform_activity.lbl_comment") }}
            </div>
            <div class="dx-field-value">
              <DxTextArea
                ref="comment-value"
                :placeholder="$t('perform_activity.hint_comment')"
                max-length="255"
                :disabled="!isActionValid"
                v-model="commentValue"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="content-block dx-card responsive-paddings">
        <dx-form
          id="form"
          label-location="top"
          :colCountByScreen="colCountByScreen"
        >
          <dx-simple-item :col-span="3" template="btn-take-odo" />

          <dx-simple-item :col-span="3" template="btn-take-picture" />

          <dx-simple-item :col-span="3" template="btn-take-signature" />

          <dx-simple-item :col-span="3" template="btn-save" />

          <template #btn-take-odo>
            <ImagePickerButton
              ref="btn-take-odo"
              :buttonType="btnTakeOdoOptions.type"
              :icon="btnTakeOdoOptions.icon"
              :buttonText="$t('perform_activity.btn_take_odo')"
              :disabled="!isActionValid"
              :imageResult.sync="odoImageResult"
              @uploaded="onFinishUploadOdo"
            />
          </template>
          <template #btn-take-picture>
            <ImagePickerButton
              ref="btn-take-picture"
              height="100%"
              :buttonType="btnPickPhotoOptions.type"
              :icon="btnPickPhotoOptions.icon"
              :buttonText="$t('perform_activity.btn_pick_photo')"
              :disabled="!isActionValid"
              :imageResult.sync="photoResult"
              @uploaded="onFinishUploadPicture"
            />
          </template>

          <template #btn-take-signature>
            <DxButton
              :width="btnSignatureOptions.width"
              :type="btnSignatureOptions.type"
              :icon="btnSignatureOptions.icon"
              :text="$t('perform_activity.btn_take_signature')"
              :disabled="!isActionValid"
              @click="btnSignatureOptions.onClick"
            />
          </template>

          <template #btn-save>
            <DxButton
              :width="btnSaveOptions.width"
              :type="btnSaveOptions.type"
              :icon="btnSaveOptions.icon"
              :text="$t('perform_activity.btn_save')"
              :disabled="!isValid"
              @click="btnSaveOptions.onClick"
            />
          </template>
        </dx-form>
      </div>
      <dx-popup
        :visible="signaturePadVisible"
        :drag-enabled="false"
        :show-title="selectedTaskId != null"
        :title="selectedTaskId"
        :on-hidden="onSignaturePopupHidden"
        :close-on-outside-click="true"
        :width="signaturePadConfig.width"
        :max-width="signaturePadConfig.maxWidth"
        :min-width="signaturePadConfig.minWidth"
      >
        <DxScrollView
          :scroll-by-content="true"
          :scroll-by-thumb="true"
          :bounce-enabled="true"
        >
          <DxBox direction="row" width="100%">
            <DxItem :ratio="1">
              <template #default>
                <div>
                  <canvas
                    class="sign-canvas"
                    id="signature-pad"
                    ref="signature-pad"
                    width="380"
                    height="380"
                  />
                </div>
              </template>
            </DxItem>
          </DxBox>
          <div class="dx-fieldset">
            <div class="dx-field">
              <div class="dx-field-value">
                <DxButton icon="check" type="success" @click="saveSignature" />
                <DxButton icon="clear" type="danger" @click="cancelSignature" />
                <DxButton icon="trash" type="danger" @click="clearSignature" />
                <DxButton icon="revert" type="default" @click="undoSignature" />
              </div>
            </div>
          </div>
        </DxScrollView>
      </dx-popup>

      <DxLoadPanel
        :visible="isLoading"
        :position="{ of: '#content-container' }"
        shading-color="rgba(0,0,0,0.4)"
        :close-on-outside-click="false"
      />
    </div>
  </div>
</template>

<script>
import i18n from "./../i18n";
import api from "../commons/api";
import service from "../commons/service";
import util from "../commons/utility";
import { useGeneralStore } from "../stores/generalStore";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxForm, DxSimpleItem } from "devextreme-vue/form";
import { DxBox, DxItem } from "devextreme-vue/box";
import { DxSelectBox } from "devextreme-vue/select-box";
import DxTextArea from "devextreme-vue/text-area";
import DxPopup from "devextreme-vue/popup";
import SignaturePad from "signature_pad";
import DataSource from "devextreme/data/data_source";
import ImagePickerButton from "../components/image-picker-button";
import SelectedDataAccordion from "../components/selected-data-accordion";
import CurrentLocation from "../components/current-location";
import DxLoadPanel from "devextreme-vue/load-panel";
import DxActionSheet from "devextreme-vue/action-sheet";

export default {
  async mounted() {
    const passedPlan = this.$route.params.selectedPlan;

    const taskId = passedPlan ? passedPlan.TaskID : null;
    const siteId = passedPlan ? passedPlan.SiteID : null;

    this.selectedPlan = passedPlan;
    this.selectedTaskId = taskId;
    this.selectedSiteId = siteId;

    this.activityTypesDataSource = new DataSource({
      postProcess: (dataBefore) => {
        const selectedActionType = this.selectedPlan.Activity;
        if (!selectedActionType) return dataBefore;

        const dataAfter = dataBefore.filter((data) => {
          const availableIn = data.AvailableIn;
          if (availableIn == null || !Array.isArray(availableIn)) {
            return false;
          }

          return availableIn.includes(selectedActionType);
        });
        return dataAfter;
      },
      load: () => {
        return service.getActivityTypes(true);
      },
    });

    let canvas = this.$refs["signature-pad"];
    this.signaturePad = new SignaturePad(canvas, {
      backgroundColor: "rgb(255, 255, 255)",
      penColor: "blue",
    });

    const hasSelectedResourceAndAllocation =
      await util.hasSelectedResourceAndAllocation();

    if (!hasSelectedResourceAndAllocation) {
      this.isSelectedReourceAndAllocation = false;
      notify("Please select resource first", "error");
    } else {
      this.isSelectedReourceAndAllocation = true;
    }
  },
  data() {
    return {
      selectedPlan: null,
      selectedTaskId: null,
      loadingSave: false,
      loadingUploadOdo: false,
      loadingUploadPhoto: false,
      loadingUploadSignature: false,

      odoImageResult: null,
      photoResult: null,

      isSelectedReourceAndAllocation: false,
      selectedAction: null,
      signaturePad: null,
      commentValue: null,
      selectedSiteId: null,

      currentLat: null,
      currentLon: null,

      messageTemplates: [],

      selectActionOptions: {
        visible: true,
        width: "100%",
        displayExpr: "Action",
        searchEnabled: false,
        searchExpr: "Action",
      },
      activityTypesDataSource: null,

      btnTakeOdoOptions: {
        icon: "photo",
        type: "default",
        width: "100%",
        text: "Take Odo",
      },

      btnPickPhotoOptions: {
        icon: "photo",
        type: "default",
        width: "100%",
        text: "Pick Photo",
      },

      btnSignatureOptions: {
        icon: "background",
        type: "default",
        width: "100%",
        text: "Take signature",
        onClick: this.onTakeSignatureClick,
      },

      btnSaveOptions: {
        icon: "save",
        type: "success",
        width: "100%",
        onClick: this.onSaveClick,
      },

      signaturePadConfig: {
        title: "Draw Signature",
        closeOnOutsideClick: true,
        minWidth: "320",
        maxWidth: "600",
        minHeight: "320",
        maxHeight: "600",
        width: "80%",
        height: "80%",
      },

      signaturePadVisible: false,
      messageTemplatePopupVisible: false,

      colCountByScreen: {
        xs: 1,
        sm: 2,
        md: 4,
        lg: 12,
      },
    };
  },
  computed: {
    isActionValid() {
      return this.selectedAction != null;
    },
    isSiteValid() {
      return this.selectedSiteId != null;
    },
    isValid() {
      return this.isActionValid && this.isSelectedReourceAndAllocation;
    },
    isLoading() {
      return (
        this.loadingSave ||
        this.loadingUploadOdo ||
        this.loadingUploadPhoto ||
        this.loadingUploadSignature
      );
    },
  },
  methods: {
    backToActivitiesList() {
      this.$router
        .replace({
          name: "activity-plan",
        })
        .catch(() => {});
    },
    getActionName(item) {
      const locale = i18n.locale;
      if (
        item == null ||
        !("Action_i18n" in item) ||
        !(locale in item.Action_i18n)
      )
        return "";
      const defaultName = item.Action;

      return item.Action_i18n[locale];
    },
    onCurrentLocationChanged(lat, lon) {
      this.currentLat = lat;
      this.currentLon = lon;
    },

    selectedActionChanged(e) {
      this.commentValue = "";
      const action = e.value;
      const locale = i18n.locale;

      if (action && "Comment_i18n" in action) {
        const messages = action.Comment_i18n;
        if (Array.isArray(messages)) {
          this.messageTemplates = messages.map(function (e) {
            return { text: e[locale] };
          });
        }
      }
    },

    selectedMessageTemplateChanged(e) {
      if (e.itemData.text) {
        this.commentValue = e.itemData.text;
        this.$refs["comment-value"].instance.focus();
      }

      this.onMessageTemplatesHidden();
    },

    onSignaturePopupHidden() {
      this.signaturePadVisible = false;
    },

    onTakeSignatureClick() {
      this.signaturePadVisible = true;
    },

    onShowMessageTemplates() {
      let messages = this.messageTemplates;
      if (Array.isArray(messages)) {
        this.messageTemplates = messages.map(function (e) {
          if (typeof e === "object") {
            return e;
          }
          return { text: e };
        });
      }

      this.messageTemplatePopupVisible = true;
    },

    onMessageTemplatesHidden() {
      this.messageTemplatePopupVisible = false;
    },

    async onSaveClick() {
      const actionName = this.selectedAction.Action;
      const actionDesc = this.selectedAction.Description;

      // check wether we need confirmation
      const locale = i18n.locale;
      const confirmationMessage = this.selectedAction.ConfirmMessage_i18n;

      if (confirmationMessage) {
        const result = await this.$root.showConfirmationDialog(
          i18n.t("common.lbl_confirmation"),
          confirmationMessage[locale]
        );

        if (!result) return;
      }

      // TODO Raymon
      const store = useGeneralStore();
      const isOnline = store.online;

      if (!isOnline) {
        this.loadingSave = true;
        setTimeout(() => {
          if (
            actionName == "Task Completed" ||
            actionName == "Complete" ||
            actionName == "Completed"
          ) {
            util.saveCompletedTask(this.selectedPlan.Seq);
          }

          this.loadingSave = false;
          this.$root.showSuccess(`${actionName} success!`, 2000);
          this.resetState();
        }, 1500);
        return;
      }

      // Value1 =
      // Value2 =
      // Value3 = comment
      // Value4 =
      // Value5 = photo
      // Value6 = odometer
      // Value7 = signature

      const params = {
        ResourceID: util.getSelectedResourceId(),
        AllocationID: util.getSelectedAllocationId(),
        SiteID: this.selectedSiteId,
        LogType: actionName,
        Gps_Time: util.getCurrentDateStr(),
        Latitude: this.currentLat,
        Longitude: this.currentLon,
        Value3: this.commentValue,
      };

      this.loadingSave = true;
      this.$http
        .post(api.POST_LOG_URL, params)
        .then((res) => {
          const logId = res.data.LogID;

          if (
            actionName == "Task Completed" ||
            actionName == "Complete" ||
            actionName == "Completed"
          ) {
            util.saveCompletedTask(this.selectedPlan.Seq);
          }

          // if(actionName == "Complete" || actionName == "Completed") {

          // }

          if (logId) {
            // upload image
            if (
              !this.photoResult &&
              !this.odoImageResult &&
              this.signaturePad.isEmpty()
            ) {
              this.loadingSave = false;
              notify(
                `${this.selectedAction.Action} success, log id ${res.data.LogID}`,
                "success",
                2000
              );
              this.resetState();
              return;
            }

            if (this.photoResult) {
              this.loadingUploadPhoto = true;
              this.loadingSave = false;

              this.$http({
                method: "post",
                url: api.POST_RESOURCE_LOG_IMAGE_URL,
                params: {
                  LogID: logId,
                  Type: "Value5",
                },
                data: Buffer.from(
                  this.photoResult.split(";base64,")[1],
                  "base64"
                ),
                headers: { "Content-Type": "image/jpeg" },
              })
                .then((resp) => {
                  this.loadingUploadPhoto = false;

                  if (!this.isLoading) {
                    notify(
                      `${this.selectedAction.Action} success, log id ${res.data.LogID}`,
                      "success",
                      2000
                    );
                    this.resetState();
                  }
                })
                .catch((err) => {
                  this.loadingUploadPhoto = false;

                  if (!this.isLoading) {
                    notify(
                      `${this.selectedAction.Action} success, log id ${res.data.LogID}`,
                      "success",
                      2000
                    );
                    this.resetState();
                  }
                });
            }

            if (this.odoImageResult) {
              this.loadingUploadOdo = true;
              this.loadingSave = false;

              this.$http({
                method: "post",
                url: api.POST_RESOURCE_LOG_IMAGE_URL,
                params: {
                  LogID: logId,
                  Type: "Value6",
                },
                data: Buffer.from(
                  this.odoImageResult.split(";base64,")[1],
                  "base64"
                ),
                headers: { "Content-Type": "image/jpeg" },
              })
                .then((resp) => {
                  this.loadingUploadOdo = false;

                  if (!this.isLoading) {
                    notify(
                      `${this.selectedAction.Action} success, log id ${res.data.LogID}`,
                      "success",
                      2000
                    );
                    this.resetState();
                  }
                })
                .catch((err) => {
                  this.loadingUploadOdo = false;

                  if (!this.isLoading) {
                    notify(
                      `${this.selectedAction.Action} success, log id ${res.data.LogID}`,
                      "success",
                      2000
                    );
                    this.resetState();
                  }
                });
            }

            if (!this.signaturePad.isEmpty()) {
              const signatureImage = this.signaturePad.toDataURL("image/jpeg");

              this.loadingUploadSignature = true;
              this.loadingSave = false;

              this.$http({
                method: "post",
                url: api.POST_RESOURCE_LOG_IMAGE_URL,
                params: {
                  LogID: logId,
                  Type: "Value7",
                },
                data: Buffer.from(
                  signatureImage.split(";base64,")[1],
                  "base64"
                ),
                headers: { "Content-Type": "image/jpeg" },
              })
                .then((resp) => {
                  this.loadingUploadSignature = false;

                  if (!this.isLoading) {
                    notify(
                      `${this.selectedAction.Action} success, log id ${res.data.LogID}`,
                      "success",
                      2000
                    );
                    this.resetState();
                  }
                })
                .catch((err) => {
                  this.loadingUploadSignature = false;

                  if (!this.isLoading) {
                    notify(
                      `${this.selectedAction.Action} success, log id ${res.data.LogID}`,
                      "success",
                      2000
                    );
                    this.resetState();
                  }
                });
            }
          } else {
            this.loadingSave = false;
            notify(`${this.selectedAction.Action} success`, "success", 2000);
            this.resetState();
          }
        })
        .catch((err) => {
          this.loadingSave = false;
          console.error(err);
          notify("error", "error", 2000);
        });
    },

    resetState() {
      this.selectedAction = null;
      // this.selectedMessageTemplate = null;
      this.signaturePad.clear();
      this.commentValue = "";
      this.photoResult = null;
      this.odoImageResult = null;

      this.$refs["btn-take-odo"].resetState();
      this.$refs["btn-take-picture"].resetState();
    },

    undoSignature() {
      let data = this.signaturePad.toData();
      if (data) {
        data.pop();
        this.signaturePad.fromData(data);
      }
    },

    clearSignature() {
      this.signaturePad.clear();
    },

    cancelSignature() {
      this.clearSignature();
      this.signaturePadVisible = false;
    },

    saveSignature() {
      this.signaturePadVisible = false;
    },
    onFinishUploadOdo(data) {},
    onFinishUploadPicture(data) {},
  },
  components: {
    DxButton,
    DxForm,
    DxPopup,
    DxSimpleItem,
    DxSelectBox,
    DxBox,
    DxItem,
    DxScrollView,
    ImagePickerButton,
    DxTextArea,
    SelectedDataAccordion,
    CurrentLocation,
    DxLoadPanel,
    DxActionSheet,
  },
};
</script>
<style>
canvas#signature-pad {
  margin: auto;
  /* border: 0.5px solid lightgray; */
}

.dx-button.dx-button-mode-contained.dx-widget.dx-button-has-icon {
  margin-right: 2px;
  margin-left: 2px;
}

.dx-button-has-text .dx-button-content {
  overflow: auto;
  text-overflow: unset;
  white-space: normal;
}

.dx-actionsheet-cancel {
  color: white;
  background-color: #f44336;
}
</style>

<style lang="scss" scoped>
.activity-message-field {
  margin-top: 1.5em;
}
</style>
