var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DxPopup',{attrs:{"visible":_vm.visible,"drag-enabled":false,"hide-on-outside-click":true,"show-title":true,"width":280,"height":200,"show-close-button":true,"container":".dx-viewport","title":"Confirm Quantity Loaded/Unloaded"},on:{"hidden":_vm.handleHidden}},[_c('DxPosition',{attrs:{"at":"center","my":"center","collision":"fit"}}),_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"before","options":{
        icon: 'check',
        text: 'Yes',
        type: 'success',
        onClick: _vm.handleConfirmClick,
      }}}),_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"after","options":{
        icon: 'close',
        text: 'No',
        type: 'danger',
        onClick: _vm.handleCancelClick,
      }}}),_c('DxNumberBox',{ref:"confirm-quantity-numberbox",attrs:{"show-spin-buttons":false,"show-clear-button":false,"min":0,"value":null,"max":9999}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }