<template>
  <div>
    <DxBox direction="column" width="100%" height="100%">
      <DxItem width="100%" height="100%" :ratio="1">
        <template #default>
          <DxButton
            class="image-picker-button"
            icon="photo"
            :type="currentButtonType"
            :text="currentTextButton"
            :disabled="disabled"
            @click="takePicture"
          >
            <template #content>
              <span>
                <DxLoadIndicator
                  :visible="isLoading"
                  class="button-indicator"
                />
                <i
                  :class="`dx-button-icon dx-icon-${icon}`"
                  v-if="!isLoading"
                ></i>
                <span class="dx-button-text">{{ currentTextButton }}</span>
              </span>
            </template>
          </DxButton>
        </template>
      </DxItem>
      <DxItem :base-size="50" height="100%" :visible="isValid && !disabled">
        <template #default>
          <DxButton
            icon="fa fa-eye"
            :type="currentButtonType"
            :disabled="disabled"
            @click="onPreviewImageClick"
          />
        </template>
      </DxItem>
    </DxBox>
    <DxPopup
      ref="my-popup"
      :visible="isImagePreviewVisible"
      :drag-enabled="false"
      :show-title="false"
      :on-hidden="onImagePreviewHidden"
      :close-on-outside-click="true"
      :height="'auto'"
      :width="'auto'"
    >
      <div style="margin-left: auto; margin-right: auto">
        <img
          id="image-preview"
          width="360"
          height="400"
          v-bind:src="selectedImage"
        />
      </div>
    </DxPopup>
  </div>
</template>

<script>
import i18n from "./../i18n";
import notify from "devextreme/ui/notify";
import { DxBox, DxItem } from "devextreme-vue/box";
import DxButton from "devextreme-vue/button";
import DxPopup from "devextreme-vue/popup";
import DxLoadIndicator from "devextreme-vue/load-indicator";

export default {
  mounted() {
    // this.setStatusLoading(true);
    // document.addEventListener("backbutton", this.onBackKeyDown, true);
    // this.$refs['my-popup'].$el.addEventListener("backbutton", this.onBackKeyDown, true);
  },
  data() {
    return {
      isLoading: false,
      currentButtonType: this.buttonType,
      currentlyDisabled: this.disabled,
      isImagePreviewVisible: false,
      isValid: false,
      selectedImage: this.imageResult,
    };
  },
  model: {
    prop: "imageResult",
    event: "change",
  },
  props: {
    icon: {
      type: String,
      default: undefined,
    },
    buttonType: {
      type: String,
      default: undefined,
    },
    buttonText: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    imageResult: {
      type: String,
      default: null,
    },
  },
  computed: {
    currentTextButton() {
      return this.isLoading ? i18n.t("common.lbl_uploading") : this.buttonText;
    },
  },
  methods: {
    onDeviceReady() {
      console.log("device ready");
    },
    onBackKeyDown(event) {
      if (this.isImagePreviewVisible) {
        this.isImagePreviewVisible = !this.isImagePreviewVisible;
      }

      return;
    },
    isCameraReady() {
      return (
        navigator !== undefined &&
        navigator !== null &&
        navigator.camera !== undefined &&
        navigator.camera !== null
      );
    },
    takePicture() {
      if (this.isCameraReady()) {
        navigator.camera.getPicture(
          this.onSuccessTakePicture,
          this.onFailedTakePicture,
          {
            quality: 75,
            sourceType: navigator.camera.PictureSourceType.CAMERA,
            destinationType: navigator.camera.DestinationType.FILE_URI,
            targetHeight: 720,
            targetWidth: 1080,
          }
        );
      } else {
        notify("Camera is not ready or device is not support camera", "error");
      }
    },
    onSuccessTakePicture(imageURI) {
      console.log("onSuccessTakePicture", imageURI);
      this.isValid = true;

      const thisComp = this;

      window.resolveLocalFileSystemURL(imageURI, function (fileEntry) {
        fileEntry.file(
          function (fileInput) {
            var reader = new FileReader();

            reader.onloadend = function (evt) {
              var base64 = evt.target.result;
              thisComp.selectedImage = base64;

              thisComp.$emit("update:imageResult", thisComp.selectedImage);
            };

            reader.readAsDataURL(fileInput);
          },
          function () {
            console.error("fail on trying to read the file.");
          }
        );
      });
    },
    onFailedTakePicture() {},
    onPreviewImageClick() {
      this.isImagePreviewVisible = true;
    },
    onImagePreviewHidden() {
      this.isImagePreviewVisible = false;
    },
    setStatusLoading(isLoading) {
      this.isLoading = isLoading;
      this.currentlyDisabled = isLoading ? isLoading : this.disabled;

      this.currentButtonType = isLoading ? "" : this.buttonType;
    },
    upload(url, queryParams) {
      if (!url) return;

      this.$http
        .post(url, null, { params: queryParams })
        .then(() => {})
        .catch((err) => {});
    },
    resetState() {
      this.selectedImage = undefined;
      this.isValid = false;
    },
  },

  components: {
    DxBox,
    DxItem,
    DxButton,
    DxPopup,
    DxLoadIndicator,
  },
};
</script>

<style lang="scss" scoped>
.image-picker-button .dx-button-icon {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  margin-right: 5px;
}

.image-picker-button .button-indicator {
  height: 20px;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
</style>
