<template>
  <DxSelectBox
    :items="countries"
    display-expr="countryName"
    value-expr="id"
    :value="selectedCountry"
    field-template="field"
    item-template="item"
    placeholder="Select Language"
    v-model="$i18n.locale"
    @valueChanged="onChange"
    width="100px"
  >
    <template #field="{ data }">
      <div class="custom-item">
        <img
          class="item-flag-image"
          :src="getImgUrl(data.imgName)"
          width="30"
          height="30"
        />
        <DxTextBox
          :value="data && data.id"
          :read-only="true"
          class="country-name"
        />
      </div>
    </template>
    <template #item="{ data }">
      <div class="custom-item">
        <img
          class="item-flag-image"
          :src="getImgUrl(data.imgName)"
          width="30"
          height="30"
        />
        <div class="country-name">
          {{ data.id }}
        </div>
      </div>
    </template>
  </DxSelectBox>
</template>

<script>
import DxSelectBox from "devextreme-vue/select-box";
import DxTextBox from "devextreme-vue/text-box";
import countryConfig from "../assets/data/country-config";

export default {
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const defaultSelectedLanguage = countryConfig.getDefaultLanguage().id;

    return {
      testImg: "@/assets/images/flags/th.svg",
      countries: countryConfig.COUNTRIES,
      selectedCountry: defaultSelectedLanguage,
    };
  },

  methods: {
    getImgUrl(imgName) {
      var images = require.context("../assets/images/flags/", false, /\.svg$/);
      return images("./" + imgName);
    },
    onChange(event) {
      localStorage.setItem("i18nextLng", event.value);
    },
  },

  components: {
    DxSelectBox,
    DxTextBox,
  },
};
</script>

<style lang="scss" scoped>
.dx-dropdownlist-popup-wrapper
  .dx-list:not(.dx-list-select-decorator-enabled)
  .dx-list-item-content {
  padding-left: 7px;
  padding-right: 7px;
}

.custom-item {
  position: relative;
  min-height: 30px;
}

.item-flag-image {
  margin-left: 0.5em;
}

.custom-item .country-name {
  display: inline-block;
  padding-left: 38px;
  text-indent: 0;
  line-height: 30px;
  font-size: 15px;
  width: 100%;
}

.custom-item > img {
  left: 1px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
}

.dx-theme-material #custom-templates .dx-texteditor-buttons-container {
  display: none;
}

.current-value {
  padding: 10px 0;
}

.current-value > span {
  font-weight: bold;
}

.dx-theme-material .dx-selectbox-container .country-name {
  padding-left: 58px;
}
</style>
