var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"content-container"}},[_c('h2',{staticClass:"content-block"},[_vm._v(_vm._s(_vm.$t("create_task.title")))]),_c('div',{staticClass:"content-block"},[_c('div',{staticClass:"dx-card responsive-paddings"},[_c('DxForm',{attrs:{"id":"form","form-data":_vm.taskData,"label-location":"top","col-count":"auto","min-col-width":240},scopedSlots:_vm._u([{key:"dimensionTemplate",fn:function(ref){return [_c('div',{staticStyle:{"display":"flex"}},[_c('DxButton',{attrs:{"icon":!_vm.dimensionGroupVisible ? 'chevronright' : 'chevrondown',"onClick":_vm.toggleDimensionGroupVisibility}}),_c('span',{staticClass:"dx-form-group-caption",staticStyle:{"display":"inline-flex","align-items":"center","margin-left":"0.5rem"}},[_vm._v(_vm._s(_vm.$t("create_task.main.lbl_dimention")))])],1)]}}])},[_c('DxTabbedItem',{attrs:{"tabPanelOptions":{
            swipeEnabled: false,
            loop: false,
          }}},[_c('DxTab',{attrs:{"title":_vm.$t('create_task.main.title'),"col-count":1}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":"taskId","label":{
                  text: _vm.$t('create_task.main.lbl_task_id'),
                },"is-required":true,"editorType":"dxTextBox","editorOptions":{
                  maxLength: 50,
                  placeholder: _vm.$t('create_task.main.hint_task_id'),
                  value: this.taskData.taskId,
                }}}),_c('DxSimpleItem',{attrs:{"data-field":"from","label":{
                  text: _vm.$t('create_task.main.lbl_from'),
                },"is-required":true,"editorType":"dxSelectBox","editorOptions":{
                  placeholder: _vm.$t('create_task.main.hint_from'),
                  displayExpr: 'Description',
                  valueExpr: 'SiteID',
                  searchExpr: ['SiteID', 'Description'],
                  searchEnabled: true,
                  dataSource: _vm.siteDataSource,
                }}}),_c('DxSimpleItem',{attrs:{"data-field":"to","label":{
                  text: _vm.$t('create_task.main.lbl_to'),
                },"is-required":true,"editorType":"dxSelectBox","editorOptions":{
                  placeholder: _vm.$t('create_task.main.hint_to'),
                  displayExpr: 'Description',
                  valueExpr: 'SiteID',
                  searchExpr: ['SiteID', 'Description'],
                  searchEnabled: true,
                  dataSource: _vm.siteDataSource,
                }}}),_c('DxSimpleItem',{attrs:{"data-field":"type","label":{
                  text: _vm.$t('create_task.main.lbl_product_type'),
                },"editorType":"dxSelectBox","editorOptions":{
                  placeholder: _vm.$t('create_task.main.hint_product_type'),
                  displayExpr: 'TypeID',
                  valueExpr: 'TypeID',
                  searchExpr: ['TypeID', 'Description'],
                  searchEnabled: true,
                  dataSource: _vm.productDataSource,
                }}})],1),_c('DxEmptyItem'),_c('DxGroupItem',{attrs:{"template":"dimensionTemplate"}}),_c('DxGroupItem',{attrs:{"visible":_vm.dimensionGroupVisible}},[_c('DxSimpleItem',{attrs:{"data-field":"quantity","label":{
                  text: _vm.$t('create_task.main.lbl_quantity'),
                },"editorType":"dxNumberBox","editorOptions":{
                  showSpinButtons: true,
                  min: 0,
                  max: 100000,
                  step: 0.5,
                }}}),_c('DxSimpleItem',{attrs:{"data-field":"metricTon","label":{
                  text: _vm.$t('create_task.main.lbl_metric_ton'),
                },"editorType":"dxNumberBox","editorOptions":{
                  showSpinButtons: true,
                  min: 0,
                  max: 100000,
                  step: 0.5,
                }}}),_c('DxSimpleItem',{attrs:{"data-field":"metricCube","label":{
                  text: _vm.$t('create_task.main.lbl_metric_cube'),
                },"editorType":"dxNumberBox","editorOptions":{
                  showSpinButtons: true,
                  min: 0,
                  max: 100000,
                  step: 0.5,
                }}}),_c('DxSimpleItem',{attrs:{"data-field":"metricOthers","label":{
                  text: _vm.$t('create_task.main.lbl_metric_others'),
                },"editorType":"dxNumberBox","editorOptions":{
                  showSpinButtons: true,
                  min: 0,
                  max: 100000,
                  step: 0.5,
                }}}),_c('DxSimpleItem',{attrs:{"data-field":"comment","label":{
                  text: _vm.$t('create_task.main.lbl_comment'),
                },"editorType":"dxTextArea","editorOptions":{
                  height: '60',
                  maxLength: 50,
                }}})],1),_c('DxEmptyItem'),_c('DxButtonItem',{attrs:{"buttonOptions":{
                icon: 'todo',
                type: 'success',
                width: '48',
                height: '48',
                elementAttr: {
                  id: 'create-task-button',
                },
                onClick: _vm.createTask,
                disabled: !this.isValid,
              }}})],1),_c('DxTab',{attrs:{"title":_vm.$t('create_task.timing.title')}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":"earliestStart","label":{
                  text: _vm.$t('create_task.timing.lbl_earliest_start'),
                },"editorType":"dxDateBox","editorOptions":{
                  type: 'datetime',
                  pickertype: 'rollers',
                  dateSerializationFormat: 'yyyy-MM-dd HH:mm:ss',
                }}}),_c('DxSimpleItem',{attrs:{"data-field":"earliestFinish","label":{
                  text: _vm.$t('create_task.timing.lbl_earliest_finish'),
                },"editorType":"dxDateBox","editorOptions":{
                  type: 'datetime',
                  pickertype: 'rollers',
                  dateSerializationFormat: 'yyyy-MM-dd HH:mm:ss',
                }}})],1)],1)],1)],1)],1)]),_c('DxLoadPanel',{attrs:{"visible":_vm.isLoading,"message":_vm.$t('create-task.Loading'),"position":{ of: '#content-container' },"close-on-outside-click":false}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }