import CreateTask from "./views/create-task";
import SplashScreen from "./views/splash-screen";
import Chat from "./views/chat";
import PodDetail from "./views/pod-detail";
import TruckLoadUnload from "./views/truck-load-unload/truck-load-unload";
import SendMessage from "./views/send-message";
import About from "./views/about";
import GPS from "./views/gps";
import POD from "./views/pod";
import Dashboard from "./views/dashboard";
import SelectTenant from "./views/select-tenant";
import ActivityDetail from "./views/activity-detail";
import ActivityPlan from "./views/activity-plan/activity-plan";
import PerformActivity from "./views/perform-activity";
import RefuelOrToll from "./views/refuel-or-toll";
import ResourceSelection from "./views/resource-selection/resource-selection";
import Camera from "./views/camera";
import Locations from "./views/locations";
import Vue from "vue";
import Router from "vue-router";
import SwlWorkBench from "./views/tools/sql-work-bench.vue";

import auth from "./auth";

// import Home from "./views/home";
import Profile from "./views/profile";
import Tasks from "./views/tasks";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import defaultNoScrollNoFooterLayout from "./layouts/side-nav-outer-toolbar-no-scroll-no-footer";
import singleCardLayout from "./layouts/single-card";
import simpleLayout from "./layouts/simple-container";
import util from "./commons/utility";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/splash-screen",
      name: "splash-screen",
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        content: SplashScreen,
      },
    },
    {
      path: "/profile",
      name: "profile",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Profile,
      },
    },
    {
      path: "/tasks",
      name: "tasks",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Tasks,
      },
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        // route level code-splitting
        // this generates a separate chunk (login.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // content: function() {
        //   import(/* webpackChunkName: "login" */ "./views/login-form");
        // }
        content: () =>
          import(/* webpackChunkName: "login" */ "./views/login-form"),
      },
    },
    {
      path: "/",
      redirect: "/splash-screen",
    },
    {
      path: "*",
      redirect: "/splash-screen",
    },
    {
      path: "/locations",
      name: "locations",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Locations,
      },
    },
    {
      path: "/camera",
      name: "camera",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Camera,
      },
    },
    {
      path: "/resource-selection",
      name: "resource-selection",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: ResourceSelection,
      },
    },
    {
      path: "/refuel-or-toll",
      name: "refuel-or-toll",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: RefuelOrToll,
      },
    },
    {
      path: "/perform-activity",
      name: "perform-activity",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: PerformActivity,
      },
    },
    {
      path: "/activity-detail",
      name: "activity-detail",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: ActivityDetail,
      },
    },
    {
      path: "/activity-plan",
      name: "activity-plan",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: ActivityPlan,
      },
    },
    {
      path: "/select-tenant",
      name: "select-tenant",
      meta: { requiresAuth: true },
      components: {
        layout: simpleLayout,
        content: SelectTenant,
      },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Dashboard,
      },
    },
    {
      path: "/pod",
      name: "pod",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: POD,
      },
    },
    {
      path: "/gps",
      name: "gps",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: GPS,
      },
    },
    {
      path: "/about",
      name: "about",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: About,
      },
    },
    {
      path: "/send-message",
      name: "send-message",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: SendMessage,
      },
    },
    {
      path: "/truck-load-unload",
      name: "truck-load-unload",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: TruckLoadUnload,
      },
    },
    {
      path: "/pod-detail/:podId",
      name: "pod-detail",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: PodDetail,
      },
      props: true,
      // props: (route) => ({
      //   ...route.params
      // })

      // props: function(route) {
      //   return {
      //     podDetail: route.params.podDetail
      //   }
      // }
    },
    {
      path: "/chat",
      name: "chat",
      meta: { requiresAuth: true },
      components: {
        layout: defaultNoScrollNoFooterLayout,
        content: Chat,
      },
    },
    // {
    //   path: "/chat",
    //   name: "chat",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: simpleLayout,
    //     content: () => import("./views/chat"),
    //   },
    // },,
    {
      path: "/create-task",
      name: "create-task",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: CreateTask,
      },
    },
    {
      path: "/sql-work-bench",
      name: "sql-work-bench",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: SwlWorkBench,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.name === "login-form" && auth.authenticated()) {
    if (!util.hasSelectTenant()) {
      // select tenant, if has not select any
      next({ name: "select-tenant" });
    } else {
      next({ name: "resource-selection" });
    }
  } else if (
    to.name !== "select-tenant" &&
    to.matched.some((record) => record.meta.requiresAuth)
  ) {
    if (!auth.authenticated()) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath },
      });
    } else {
      if (!util.hasSelectTenant()) {
        next({
          name: "select-tenant",
          query: { redirect: to.fullPath },
        });
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
