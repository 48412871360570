<template>
  <div>
    <h2 class="content-block">{{ $t("history_progress.title") }}</h2>
    <SelectedDataAccordion />
    <ActivityHistoryDiagram
      id="pod-diagram"
      :items="podActivities"
      @click="onClickItem"
    />
    <DxLoadPanel
      :position="panelPosition"
      :visible="isLoading"
      :show-indicator="true"
      :show-pane="true"
      :shading="false"
    />
  </div>
</template>

<script>
import DxLoadPanel from "devextreme-vue/load-panel";
import SelectedDataAccordion from "../components/selected-data-accordion";
import ActivityHistoryDiagram from "../components/activity-history-diagram";
import service from "../commons/service";
import utility from "../commons/utility";

export default {
  mounted() {
    const allocationId = utility.getSelectedAllocationId();

    if (allocationId != null) {
      this.loadPodDetails(allocationId);
    }
  },
  data() {
    return {
      isLoading: false,
      panelPosition: { of: "#pod-diagram" },

      podActivities: [],
    };
  },
  methods: {
    setLoading(isLoading) {
      this.isLoading = isLoading;
    },
    loadPodDetails(allocationId) {
      this.setLoading(true);

      service
        .getResourceLogsByAllocationId(allocationId, true)
        .then((res) => {
          let data = res.data;
          if (Array.isArray(data)) {
            this.podActivities = data;
          }

          this.setLoading(false);
        })
        .catch((_) => {
          this.setLoading(false);
        });
    },
    onClickItem(item) {
      this.$router
        .push({
          name: "pod-detail",
          params: {
            podId: item.id,
            podDetail: item,
          },
        })
        .catch(() => {});
    },
  },
  components: {
    SelectedDataAccordion,
    ActivityHistoryDiagram,
    DxLoadPanel,
  },
};
</script>

<style lang="scss"></style>
