<template>
  <div>
    <DxPopup
      :visible="showDisclosure"
      :showTitle="false"
      width="80%"
      height="70%"
      :onHidden="onPopUpHidden"
    >
      <div class="disclosure-container">
        <DxScrollView width="100%" style="flex-grow: 1">
          <DxGallery
            item-template="item"
            :data-source="disclosures"
            :showNavButtons="showNavigation"
            :showIndicator="showIndicator"
            :onSelectionChanged="permissionSelectionChanged"
            class="disclosure-gallery"
          >
            <template #item="{ data }">
              <div class="disclosure-item-container">
                <p>
                  <b>{{ $t(data.title) }}</b>
                </p>
                <img
                  class="disclosure-illustration"
                  :src="require(`@/assets/images/${data.illustration}`)"
                  :alt="$t(data.message)"
                  height="30%"
                  width="50%"
                />
                <p
                  style="
                    white-space: break-spaces;
                    word-break: break-word;
                    font-size: 0.8em;
                  "
                >
                  {{ $t(data.message) }}
                </p>
              </div>
            </template>
          </DxGallery>
        </DxScrollView>
        <div class="disclosure-footer-container">
          <DxCheckBox
            :text="$t('disclosure.lbl_dont_show_again')"
            width="100%"
            :disabled="!actionEnabled"
            v-model="dontShowAgain"
          />
          <div class="disclosure-buttons">
            <DxButton
              :text="$t('disclosure.btn_disagree')"
              width="40%"
              type="danger"
              :disabled="!actionEnabled"
              :onClick="onBtnDisagreeClicked"
            />
            <DxButton
              :text="$t('disclosure.btn_agree')"
              width="40%"
              :disabled="!actionEnabled"
              :onClick="onBtnAgreeClicked"
            />
          </div>
        </div>
        <LanguageSelectSimple style="margin: auto auto auto auto" />
      </div>
    </DxPopup>
  </div>
</template>

<script>
import util from "../../commons/utility";
import DxGallery from "devextreme-vue/gallery";
import DxPopup from "devextreme-vue/popup";
import DxButton from "devextreme-vue/button";
import DxCheckBox from "devextreme-vue/check-box";
import DxScrollView from "devextreme-vue/scroll-view";
import notify from "devextreme/ui/notify";
import constants from "../../commons/constants";
import { custom } from "devextreme/ui/dialog";
import i18n from "./../../i18n";
import LanguageSelectSimple from "./../language-select-simple";

const CURRENT_LOCATION = {
  key: "current-location",
  data: {
    key: "current-location",
    title: "disclosure.current_location.title",
    message: "disclosure.current_location.message",
    illustration: "maps.svg",
  },
};

const BACKGROUND_LOCATION = {
  key: "background-locations",
  data: {
    key: "background-locations",
    title: "disclosure.background_location.title",
    message: "disclosure.background_location.message",
    illustration: "routes.svg",
  },
};

const BACKGROUND_MOTIONS = {
  key: "background-motions",
  data: {
    key: "background-motions",
    title: "disclosure.background_motions.title",
    message: "disclosure.background_motions.message",
    illustration: "motions.svg",
  },
};

const ALL_PERMISSIONS = [
  CURRENT_LOCATION,
  BACKGROUND_LOCATION,
  BACKGROUND_MOTIONS,
];

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    manualTrigger: {
      type: Boolean,
      default: false,
    },
    permissionTypes: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  mounted() {
    this.permissionTypes.forEach((permission) => {
      const disclosure = ALL_PERMISSIONS.find((i) => i.key == permission);
      if (disclosure) this.disclosures.push(disclosure.data);
    });

    if (this.manualTrigger) {
      this.visible = this.show;
    } else {
      this.visible = this.disclosures.length > 0 && util.shouldShowDisclosure();
    }
  },
  data() {
    return {
      visible: this.show,
      selectedIndex: 0,
      enableActions: false,
      dontShowAgain: false,

      disclosures: [],
      alertDialog: undefined,
    };
  },
  computed: {
    actionEnabled() {
      return (
        this.disclosures.length > 0 &&
        this.selectedIndex + 1 >= this.disclosures.length
      );
    },
    showNavigation() {
      return this.disclosures.length > 1;
    },
    showIndicator() {
      return this.disclosures.length > 1;
    },
    showDisclosure() {
      return this.visible;
    },
  },

  watch: {
    show(newValue, oldValue) {
      if (this.manualTrigger) {
        this.visible = newValue;
      }
    },
  },
  methods: {
    exitApplication() {
      const app = navigator.app;

      if (app) {
        navigator.app.exitApp();
      } else {
        // javascript cannot close tab, sor for now in browser just show error message
        // window.close();
        notify(
          "Pscope Mobile wont benefit much, please accept the permission or uninstall...",
          "error",
          constants.INFINITE_TOAST
        );
      }
    },
    getDisclosureImage(path) {
      return path;
    },
    permissionSelectionChanged(e) {
      const item = e.addedItems[0];
      const idx = this.disclosures.findIndex((d) => d.key == item.key);
      this.selectedIndex = idx;
    },
    onPopUpHidden() {
      this.visible = false;
    },
    onBtnAgreeClicked() {
      util.saveDisclosure(true, this.dontShowAgain);

      if (this.manualTrigger) {
        this.$emit("agreed");
      } else {
        this.visible = false;
        this.$router
          .replace({
            path: "/resource-selection",
          })
          .catch(() => {});
      }
    },
    onBtnDisagreeClicked() {
      if (this.manualTrigger) {
        this.$emit("disagreed");
      } else {
        this.visible = false;
        this.$nextTick(() => {
          if (this.alertDialog) {
            this.alertDialog.hide();
          }

          this.alertDialog = custom({
            title: i18n.t("disclosure.lbl_warning"),
            messageHtml: i18n.t("disclosure.msg_app_not_fully_funcitioning"),
            buttons: [
              {
                text: i18n.t("common.btn_ok"),
                onClick: (e) => {
                  return { buttonText: e.component.option("text") };
                },
              },
            ],
          });

          this.alertDialog.show().then((dialogResult) => {
            util.saveDisclosure(false, false);
            this.exitApplication();
          });
        });
      }
    },
  },
  components: {
    DxPopup,
    DxGallery,
    DxCheckBox,
    DxButton,
    DxScrollView,
    LanguageSelectSimple,
  },
};
</script>

<style lang="scss">
.disclosure-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.disclosure-buttons {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.disclosure-item-container {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
}

.disclosure-illustration {
  margin-left: auto;
  margin-right: auto;
}
.disclosure-gallery {
  touch-action: manipulation;
}
</style>
