<template>
  <div id="select-tenant-container">
    <div class="single-card">
      <div id="select-tenant-card" class="dx-card responsive-paddings" v-if="isMultiTenant">
          <div v-for="(tenant, index) in tenantItems" :key="tenant.TenantID">
            <DxButton
              width="100%"
              :text="tenant.Name"
              type="success"
              :style="index > 0 ? { 'margin-top': '20px' } : {} "
              @click="selectTenant(tenant)"/>
          </div>
      </div>
    </div>
    <DxLoadPanel
      :visible="isLoading"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>

<script>
import auth from '../auth';
import api from '../commons/api';
import util from '../commons/utility';
import DxButton from 'devextreme-vue/button';
import DxLoadPanel from 'devextreme-vue/load-panel';
import notify from "devextreme/ui/notify";

export default {
  computed: {
    isMultiTenant() {
      return this.tenantItems !== undefined  && this.tenantItems !== null && this.tenantItems.length > 1;
    }
  },
  mounted () {
    this.getTenantsList();
  },
  data() {
    return {
      tenantItems: [],
      isLoading: true,
    }
  },
  methods: {
    selectTenant(tenant) {
      this.setLoading(true);

      let tempUserData = util.getTempUserData();

      console.log('temp data', tempUserData);
      
      auth
        .logIn(tempUserData.userName, tempUserData.password, this.$http, tenant.TenantID)
        .then((response) => {
          // check if tenant id exist
          this.setLoading(false);

          // replace old token with new token
          auth.saveAccessToken(response.data.access_token);
          
          util.saveSelectedTenant(tenant);
          util.clearTempUserData();
          
          this.$router.replace(this.$route.query.redirect || '/resource-selection').catch(() => {});
        })
        .catch((err) => {
          this.setLoading(false);
          notify("Cannot select tenant, please try to relogin...", "error", 2000);
      })
    },
    getTenantsList() {
      this.$http
        .get(api.GET_TENANT_LIST_URL)
        .then((res) => {
          this.setLoading(false);
          this.tenantItems = res.data ? res.data : [];

          if(!this.isMultiTenant) {
            this.selectTenant(this.tenantItems[0]);
          }

        })
        .catch((err) => {
          console.log(err);
          this.setLoading(false);
        })
    },
    setLoading(isLoading) {
      this.isLoading = isLoading;
    }
  },  

  components: {
    DxButton,
    DxLoadPanel,
  },
}
</script>

<style lang="scss">

#select-tenant-card {
  background-color: transparent;
  border: 0px;
} 

@keyframes slideleft {
    from {
        background-position: 0%;
    }
    to {
        background-position: 90000%;
    }
}

@-webkit-keyframes slideleft {
    from {
        background-position: 0%;
    }
    to {
        background-position: 90000%;
    }
}

#select-tenant-container {
    background-image: url('../assets/images/bg.jpg');
    background-repeat: repeat-x;
    animation: slideleft 20000s infinite linear;
    -webkit-animation: slideleft 20000s infinite linear;
    background-size: cover;
	-webkit-background-size:cover;
	-moz-background-size:cover; 
    background-attachment: fixed;
    position: relative;
	min-height: 100vh;
}

</style>