<template>
  <div>
    <h2 class="content-block">{{ $t("dashboard.title") }}</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <DxTabs
          :data-source="tabs"
          :selected-index="selectedTabIndex"
          :onSelectionChanged="onTabSelectionChanged"
        />
        <div class="dashboard-tabs-content">
          <DxCircularGauge :value="chartValue">
            <DxScale
              :start-value="tabs[selectedTabIndex].chartConfig.startValue"
              :end-value="tabs[selectedTabIndex].chartConfig.endValue"
              :tick-interval="tabs[selectedTabIndex].chartConfig.tickInterval"
              :minor-tick-interval="
                tabs[selectedTabIndex].chartConfig.minorTickInterval
              "
            >
              <DxTick color="#9c9c9c" />
              <DxMinorTick :visible="true" color="#9c9c9c" />
            </DxScale>

            <DxRangeContainer background-color="none" />
            <DxTitle :text="chartTitle">
              <DxFont :size="28"> </DxFont>
            </DxTitle>
            <DxExport :enabled="false" />
          </DxCircularGauge>
        </div>
        <div class="chart-info dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label">
              {{ $t("dashboard.lbl_allocation_id") }}
            </div>
            <div class="dx-field-value">
              <DxTextBox :value="selectedAllocationId" :read-only="true" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">
              {{ $t("dashboard.lbl_resource_id") }}
            </div>
            <div class="dx-field-value">
              <DxTextBox :value="selectedResourceId" :read-only="true" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">
              {{ $t("dashboard.lbl_exp_start_time") }}
            </div>
            <div class="dx-field-value">
              <DxTextBox :value="expStartTime" :read-only="true" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">
              {{ $t("dashboard.lbl_exp_finish_time") }}
            </div>
            <div class="dx-field-value">
              <DxTextBox :value="expFinishTime" :read-only="true" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DxTabs from "devextreme-vue/tabs";
import {
  DxCircularGauge,
  DxScale,
  DxTick,
  DxMinorTick,
  DxRangeContainer,
  DxTitle,
  DxFont,
  DxExport,
} from "devextreme-vue/circular-gauge";

import i18n from "./../i18n";
import utility from "../commons/utility";
import api from "../commons/api";
import DxTextBox from "devextreme-vue/text-box";

const defaultSelectedTabIndex = 0;
const defaultTabsConfig = [
  {
    id: 0,
    text: i18n.t("dashboard.m3.title"),
    icon: "box",
    chartConfig: {
      chartTitle: `${i18n.t("dashboard.m3.lbl_total")}: 0 `,
      startValue: 0,
      endValue: 70,
      tickInterval: 10,
      minorTickInterval: 2,
      chartValueAttr: "totalM3",
    },
  },
  {
    id: 1,
    text: i18n.t("dashboard.mt.title"),
    icon: "globe",
    chartConfig: {
      chartTitle: `${i18n.t("dashboard.mt.lbl_total")}: 0 `,
      startValue: 0,
      endValue: 40,
      tickInterval: 5,
      minorTickInterval: 1,
      chartValueAttr: "totalMT",
    },
  },
  {
    id: 2,
    text: i18n.t("dashboard.hr.title"),
    icon: "menu",
    chartConfig: {
      chartTitle: `${i18n.t("dashboard.hr.lbl_total")}: 0`,
      startValue: 0,
      endValue: 24,
      tickInterval: 1,
      minorTickInterval: 1,
      chartValueAttr: "totalHR",
    },
  },
];

export default {
  mounted() {
    let allocationId = utility.getSelectedAllocationId();
    let resourceId = utility.getSelectedResourceId();

    if (allocationId != null && resourceId != null) {
      this.loadSelectedResourceAndAllocation(resourceId, allocationId);
      this.loadAllocationDetail(resourceId, allocationId);
    }
  },
  data() {
    return {
      selectedTabIndex: defaultSelectedTabIndex,
      tabs: defaultTabsConfig,
      selectedChartConfig: defaultTabsConfig[defaultSelectedTabIndex],
      chartTitle:
        defaultTabsConfig[defaultSelectedTabIndex].chartConfig.chartTitle,
      chartValue: 0,
      selectedAllocationId: "-",
      selectedResourceId: "-",
      expStartTime: "-",
      expFinishTime: "-",

      allocationDetail: {},
    };
  },
  components: {
    DxTabs,
    DxCircularGauge,
    DxScale,
    DxTick,
    DxMinorTick,
    DxRangeContainer,
    DxTitle,
    DxFont,
    DxExport,
    DxTextBox,
  },
  methods: {
    onTabSelectionChanged(e) {
      let selectedIdx = e.addedItems[0].id;
      this.selectedTabIndex = selectedIdx;

      this.updateChartData(selectedIdx);
    },
    updateChartData(selectedIdx) {
      const currentChartValue =
        this.allocationDetail[
          this.tabs[selectedIdx].chartConfig.chartValueAttr
        ];
      let mChartValue = parseFloat(currentChartValue);

      if (mChartValue) {
        mChartValue = Math.round(mChartValue * 100) / 100;
      }

      this.chartValue = !mChartValue ? 0 : mChartValue;
      this.chartTitle =
        this.tabs[selectedIdx].chartConfig.chartTitle.replace("0", "") +
        this.chartValue;
    },
    setLoading(isLoading) {
      if (isLoading) {
        this.chartTitle = `${i18n.t("common.lbl_total")}: 0`;
        this.chartValue = 0;

        this.expStartTime = "-";
        this.expFinishTime = "-";
      }
    },
    loadAllocationDetail(resourceId, allocationId) {
      const params = {
        resourceID: resourceId,
        allocationID: allocationId,
      };

      this.$http
        .get(api.GET_ALLOCATION_DETAIL_URL, { params })
        .then((res) => {
          let data = res.data;

          if (data != null && typeof data === "object") {
            this.allocationDetail = {
              allocationId: data.AllocationID,
              totalCost: data.TotalCost,
              totalHR: data.TotalHR,
              totalKM: data.TotalKM,
              totalM3: data.TotalM3,
              totalMT: data.TotalMT,
              totalUD: data.TotalUD,
              expStartTime: data.ExpStartTime,
              expFinishTime: data.ExpFinishTime,
            };

            this.expStartTime = this.allocationDetail.expStartTime;
            this.expFinishTime = this.allocationDetail.expFinishTime;

            this.updateChartData(this.selectedTabIndex);
          }
        })
        .catch((err) => {});
    },
    loadSelectedResourceAndAllocation(resourceId, allocationId) {
      this.selectedAllocationId = allocationId == null ? "-" : allocationId;
      this.selectedResourceId = resourceId == null ? "-" : resourceId;
    },
  },
};
</script>

<style lang="scss">
.dashboard-tabs-content {
  padding-top: 1.5rem;
}

.chart-info {
  margin-top: 3em;
  text-align: justify;
}
</style>
