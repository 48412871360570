import CryptoJS from 'crypto-js';
import util from './commons/utility';
import api from './commons/api';
// import URLSearchParams from 'axios';

// saved here for dummy purpose
const key = 'primarius123';

export default {
  key,

  authenticated() {
    let userName = localStorage.getItem('USER_NAME');
    let token = localStorage.getItem('USER_TOKEN');
    return userName !== null && token !== null;
  },
  
  logIn(userName, password, _axios, tenantid) {
    const bodyParam = new URLSearchParams();
    bodyParam.append('username', userName);
    bodyParam.append('password', password);
    bodyParam.append('grant_type','password');
    if(tenantid !== undefined && tenantid !== null) bodyParam.append('tenantid', tenantid);

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    let request = _axios
      .post(api.LOGIN_URL, bodyParam, headers)
      
    return request.then(res => {
        return res;
      })
      .catch(err => {
        // currently message from the backend is not relevan just 'invalid grant type', so hardcoded to 
        // 'Username or password is invalid' for now
        return Promise.reject( { message: 'Username or password is invalid' } );
      });
  },

  saveUserData(userId, userName, password, authToken, rememberMe) {
    localStorage.setItem('USER_ID', userId);
    localStorage.setItem('USER_NAME', userName);
    localStorage.setItem('USER_TOKEN', authToken);

    if(rememberMe) {
      localStorage.setItem('SAVED_USER_NAME', userName);
      localStorage.setItem('SAVED_PASSWORD', CryptoJS.AES.encrypt(password, key));
    } else {
      localStorage.removeItem('SAVED_USER_NAME');
      localStorage.removeItem('SAVED_PASSWORD');
    }
  },

  saveAccessToken(authToken) {
    localStorage.setItem('USER_TOKEN', authToken);
  },

  logOut() {
    localStorage.removeItem('USER_ID');
    localStorage.removeItem('USER_NAME');
    localStorage.removeItem('USER_TOKEN');

    util.clearSelectedAppointment();
    util.clearSelectedTaskId();
    util.clearSelectedSite();
    util.clearSelectedTenant();
    
  },

  getUserId() {
    return localStorage.getItem('USER_ID');
  },

  getUserName() {
    return localStorage.getItem('USER_NAME');
  },

  getUserToken() {
    return localStorage.getItem('USER_TOKEN');
  }
  
};
