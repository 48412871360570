<template>
  <div>
    <h2 class="content-block">Locations</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <dx-map
          :zoom="14"
          :height="400"
          center="Brooklyn Bridge,New York,NY"
          width="100%"
          provider="google">
          <dx-key
            :bing="keys.bing"
            :google="keys.google"
            :google-static="keys.googleStatic"
          />
        </dx-map>
      </div>
    </div>
  </div>
</template>

<script>
import { DxMap, DxKey } from 'devextreme-vue/map';

export default {
  data() {
    return {
      keys: {
        // NOTE: Specify your map API keys for every used provider
        //bing: "YOUR_BING_MAPS_API_KEY",
        google: "AIzaSyDXUe4sWzgd_Ab4y516s8Y9RcxXDw_zSoo",
        googleStatic: "AIzaSyDXUe4sWzgd_Ab4y516s8Y9RcxXDw_zSoo",
        //googleStatic: "YOUR_GOOGLE_STATIC_MAPS_API_KEY"
      }
    };
  },
  components: {
    DxMap,
    DxKey
  },
}
</script>

<style>
</style>