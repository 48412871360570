<template>
  <div>
    <!-- ACTIVITY ACTION POP UP -->
    <DxPopup
      :visible="visible"
      :drag-enabled="false"
      :show-title="false"
      @hidden="handleHidden"
      :close-on-outside-click="true"
      :show-Button="true"
      :height="'auto'"
      :width="'70%'"
    >
      <div style="margin-left: auto; margin-right: auto">
        <DxButton
          :text="$t('activity.activity_detail.title')"
          type="default"
          :width="'100%'"
          icon="rowfield"
          @click="handleActivityDetailBtnClick"
        />
        <br /><br />
        <DxButton
          :text="$t('perform_activity.title')"
          type="default"
          :width="'100%'"
          icon="runner"
          :disabled="isPerformActivityDisabled"
          @click="handlePerformActivityBtnClick"
        />
        <br /><br />
        <DxButton
          :text="$t('activity.btn_navigate_to_location')"
          type="default"
          :width="'100%'"
          icon="map"
          @click="handleNavigateToSiteBtnClick"
        />
        <br /><br />
        <DxButton
          :text="$t('activity.btn_send_notification')"
          type="default"
          :width="'100%'"
          icon="message"
          @click="handleSendMessageBtnClick"
        />
        <br /><br />
      </div>
    </DxPopup>
    <ActivityDetails
      :visible="isActivityDetailsVisible"
      :hidden="handleActivityDetailsHide"
      :selected-plan="selectedPlan"
    />
  </div>
</template>
<script>
import util from "../../commons/utility";
import DxPopup from "devextreme-vue/popup";
import DxButton from "devextreme-vue/button";
import { planStatus, activityType } from "../../commons/constants";
import ActivityDetails from "./activity-details.vue";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selectedPlan: {
      type: Object,
      default: null,
    },
    hidden: Function,
  },
  data() {
    return {
      isActivityDetailsVisible: false,
    };
  },
  computed: {
    isPerformActivityDisabled() {
      const selectedPlan = this.selectedPlan;
      if (selectedPlan == null) return true;

      // const planStatus = selectedPlan.PlanStatus;
      // if (planStatus != null && (planStatus == planStatus.ON_GOING)) {
      //   return false;
      // }

      return false;
    },
    isTruckLoadDisabled() {
      const selectedPlan = this.selectedPlan;
      if (selectedPlan == null) return true;

      // now truck load and unload
      // doesnt need to have success status (can always be performed)

      // const planStatus = selectedPlan.PlanStatus;
      // if (
      //   planStatus == null ||
      //   planStatus == planStatus.COMPLETED ||
      //   planStatus == planStatus.OPEN
      // )
      //   return true;

      const planActivity = selectedPlan.Activity;
      if (planActivity == activityType.PICK_UP_TASK) {
        return false;
      }

      return true;
    },
    isTruckUnloadDisabled() {
      const selectedPlan = this.selectedPlan;
      if (selectedPlan == null) return true;

      // now truck load and unload
      // doesnt need to have success status (can always be performed)

      // const planStatus = selectedPlan.PlanStatus;
      // if (
      //   planStatus == null ||
      //   planStatus == planStatus.COMPLETED ||
      //   planStatus == planStatus.OPEN
      // )
      //   return true;

      const planActivity = selectedPlan.Activity;
      if (planActivity == activityType.DROP_OFF_TASK) {
        return false;
      }

      return true;
    },
  },
  methods: {
    handleActivityDetailBtnClick() {
      const selectedPlan = this.selectedPlan;
      if (selectedPlan == null) return;

      this.isActivityDetailsVisible = true;
    },
    handlePerformActivityBtnClick() {
      const selectedPlan = this.selectedPlan;
      if (selectedPlan == null) return;

      util.saveSelectedActivityPlan(this.selectedPlan);
      util.saveSelectedSite(this.selectedPlan.Sites);

      this.$router
        .push({
          name: "perform-activity",
          params: { selectedPlan: this.selectedPlan },
        })
        .catch((e) => {
          console.error(e);
        });
    },
    handleNavigateToSiteBtnClick() {
      const selectedPlan = this.selectedPlan;
      if (selectedPlan == null) return;

      if (
        selectedPlan.Sites.Latitude &&
        selectedPlan.Sites.Latitude > 0.0 &&
        selectedPlan.Sites.Longitude &&
        selectedPlan.Sites.Longitude > 0.0
      ) {
        let destLatLng = `${selectedPlan.Sites.Latitude},${selectedPlan.Sites.Longitude}`;

        window.open(
          `https://www.google.com/maps/search/?api=1&query=${destLatLng}&zoom=10`,
          "_system"
        );
      }
    },
    handleSendMessageBtnClick() {
      const selectedPlan = this.selectedPlan;
      if (selectedPlan == null) return;

      util.saveSelectedActivityPlan(selectedPlan);

      this.$router.push("/send-message").catch(() => {});
    },
    handleTruckLoadBtnClick() {
      const selectedPlan = this.selectedPlan;
      if (selectedPlan == null) return;

      const tasks = selectedPlan.Children ?? [];

      util.saveSelectedActivityPlan(selectedPlan);
      util.saveSelectedSite(selectedPlan.Sites);
    },
    handleTruckUnloadBtnClick() {
      const selectedPlan = this.selectedPlan;
      if (selectedPlan == null) return;

      const tasks = selectedPlan.Children ?? [];

      util.saveSelectedActivityPlan(selectedPlan);
      util.saveSelectedSite(selectedPlan.Sites);
    },
    handleHidden() {
      util.clearSelectedActivityPlan();
      util.clearSelectedSite();

      if (this.hidden != null) {
        this.hidden();
      }
    },
    handleActivityDetailsHide() {
      this.isActivityDetailsVisible = false;
    },
  },
  components: {
    DxPopup,
    DxButton,
    ActivityDetails,
  },
};
</script>
<style lang=""></style>
