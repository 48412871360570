const COUNTRIES = [
  {
    id: "th",
    countryName: "ภาษา",
    imgName: "th.svg",
  },
  {
    id: "en",
    countryName: "English",
    imgName: "gb.svg",
  },
  {
    id: "us",
    countryName: "English",
    imgName: "us.svg",
  },
];

function getDefaultLanguage() {
  return COUNTRIES[0];
}

export default {
  COUNTRIES,
  getDefaultLanguage,
};
