<template>
  <div>
    <div class="job-item-body">
      <div class="resource-info">
        <i class="allocation-header-item-icon dx-icon-car" />{{ allocation.ResourceID }}
      </div>
      <div class="resource-info">
        <i class="allocation-header-item-icon dx-icon-home" />{{ allocation.AllocationID }}
      </div>
      <div class="summary-info">
        <span class="total-km"
          ><i class="allocation-item-icon dx-icon-map" />{{
            allocation.TotalKM
          }}
          KM</span
        >
        <span class="total-hr"
          ><i class="allocation-item-icon dx-icon-clock" />{{
            allocation.TotalHR
          }}
          hours</span
        >
        <span class="total-m3"
          ><i class="allocation-item-icon dx-icon-box" />{{
            allocation.TotalM3
          }}
          M3</span
        >
      </div>
      <div class="allocation-date-time-info">
        <span class="allocation-date-time">ETA {{ startDateTime }} to {{ endDateTime }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    allocation: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    startDateTime() {
      try {
        return this.$moment(
          this.allocation.ExpStartTime,
          "YYYY-MM-DD'T'HH:mm:ss"
        ).format("D/MMM hh:mm A");
      } catch (e) {
        return "-";
      }
    },
    endDateTime() {
      try {
        return this.$moment(
          this.allocation.ExpFinishTime,
          "YYYY-MM-DD'T'HH:mm:ss"
        ).format("D/MMM hh:mm A");
      } catch (e) {
        return "-";
      }
    },
  },
  mounted() {
  },
  methods: {
    name() {},
  },
};
</script>

<style lang="scss">
.job-item-body {
  cursor: pointer;
}
.resource-info {
  color: #4a6aa3;
  font-size: 1.5em;
  font-weight: 600;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .allocation-header-item-icon {
    margin-right: 0.25em;
    font-size: 1em;
  }
}
.summary-info {
  margin-top: 0.5em;
  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .allocation-item-icon {
    margin-right: 0.25em;
  }
  .total-km {
    color: #ff2b2b;
    font-weight: 600;
    margin-right: 0.75em;
  }
  .total-hr {
    color: #2a87ca;
    font-weight: 600;
    margin-right: 0.75em;
  }
  .total-m3 {
    color: #6c9351;
    font-weight: 600;
    margin-right: 0.75em;
  }
}

.allocation-date-time-info {
  margin-top: 0.25em;
  
  .allocation-date-time {
    white-space: break-spaces;
    word-break: break-word;

    color: #ca6724;
    font-weight: 600;
  }
}
</style>
