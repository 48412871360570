import { pscopeAxios } from "../main";
import api from "./api";

import localRepository from "./local-repository";

function getActivityTypes(rawResponse) {
  const promise = new Promise((resolve, reject) => {
    pscopeAxios
      .get(api.GET_ACTIVITIES_URL)
      .then((response) => {
        const mData = response.data;

        localRepository.saveActivityTypes(mData);

        if (rawResponse) {
          resolve(response);
        } else {
          resolve(mData);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });

  return promise;
}

function getAllocationsByUserName(userName, rawResponse) {
  const promise = new Promise((resolve, reject) => {
    pscopeAxios
      .get(api.GET_ALLOCATION_URL, {
        params: {
          user: userName,
        },
      })
      .then((response) => {
        const mData = response.data;

        localRepository.saveAllocations(mData);

        if (rawResponse) {
          resolve(response);
        } else {
          resolve(mData);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });

  return promise;
}

function getAllocationPlansByAllocationId(allocationId, rawResponse) {
  const promise = new Promise((resolve, reject) => {
    pscopeAxios
      .get(api.GET_ALLOCATION_PLAN_URL, {
        params: {
          allocationID: allocationId,
        },
      })
      .then((response) => {
        if (rawResponse) {
          resolve(response);
        } else {
          resolve(response.data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });

  return promise;
}

function getTasksByAllocationId(allocationId, rawResponse) {
  const promise = new Promise((resolve, reject) => {
    pscopeAxios
      .get(api.GET_ACTIVITY_PLANS_URL, {
        params: {
          allocationID: allocationId,
        },
      })
      .then((response) => {
        const mData = response.data;

        localRepository.saveTasksAllocation(mData);

        if (rawResponse) {
          resolve(response);
        } else {
          resolve(mData);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });

  return promise;
}

function getTaskLinesByTaskIds(taskIds, rawResponse) {
  const promise = new Promise((resolve, reject) => {
    pscopeAxios
      .post(api.GET_TASK_LINES_BY_TASK_IDS, taskIds)
      .then((response) => {
        const mData = response.data;

        localRepository.saveTaskLines(mData);

        if (rawResponse) {
          resolve(response);
        } else {
          resolve(mData);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });

  return promise;
}

function postTaskLine(taskLine, rawResponse) {
  const promise = new Promise((resolve, reject) => {
    pscopeAxios
      .post(api.POST_TASK_LINE, taskLine)
      .then((response) => {
        const mData = response.data;

        if (rawResponse) {
          resolve(response);
        } else {
          resolve(mData);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });

  return promise;
}

function validateTaskLines(taskLines, rawResponse) {
  const promise = new Promise((resolve, reject) => {
    pscopeAxios
      .post(api.POST_VALIDATE_TASK_LINES, taskLines)
      .then((response) => {
        const mData = response.data;

        if (rawResponse) {
          resolve(response);
        } else {
          resolve(mData);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });

  return promise;
}

function getResourceLogsByAllocationId(allocationId, rawResponse) {
  const promise = new Promise((resolve, reject) => {
    pscopeAxios
      .get(api.GET_RESOUCE_LOGS, {
        params: {
          allocationID: allocationId,
        },
      })
      .then((response) => {
        const mData = response.data;

        localRepository.saveResourceLogs(mData);

        if (rawResponse) {
          resolve(response);
        } else {
          resolve(mData);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });

  return promise;
}

export default {
  getActivityTypes,
  getAllocationsByUserName,
  getAllocationPlansByAllocationId,
  getTasksByAllocationId,
  getTaskLinesByTaskIds,
  postTaskLine,
  validateTaskLines,
  getResourceLogsByAllocationId,
};
