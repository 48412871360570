<template>
  <!-- @deprecated -->
  <div>
    <h2 class="content-block">{{ $t("Activity.Activity") }}</h2>
    <SelectedDataAccordion />
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <DxButton
          icon="arrowleft"
          width="72"
          @click="onShowPreviousAppointmentClick"
          :disabled="!schedulerReady"
        />
        <DxButton
          icon="arrowright"
          width="72"
          @click="onShowNextAppointmentClick"
          :disabled="!schedulerReady"
        />
        <br /><br />
        <DxScheduler
          ref="appointment-scheduler"
          currentView="day"
          :views="schedulerConfig.views"
          :editing="schedulerConfig.editing"
          :adaptivity-enabled="true"
          :current-date="schedulerCurrentDate"
          :data-source="schedulerConfig.dataSource"
          :remote-filtering="schedulerConfig.remoteFiltering"
          :text-expr="schedulerConfig.textExpr"
          :date-serialization-format="schedulerConfig.dateSerializationFormat"
          :start-date-expr="schedulerConfig.startDateExpr"
          :end-date-expr="schedulerConfig.endDateExpr"
          :on-appointment-click="onAppointmentClick"
          :on-appointment-form-opening="onAppointmentFormOpening"
          :show-current-time-indicator="true"
          :on-content-ready="onSchedulerReady"
          appointment-template="appointment"
        >
          <template #appointment="{ data }">
            <div
              :ref="'appointment-' + data.appointmentData.Seq"
              :id="'appointment-' + data.appointmentData.Seq"
              :class="setAppointmentClass(data.appointmentData)"
            >
              <i> {{ data.appointmentData.SiteID }} </i>
              <p>
                <i>
                  {{ getFormatedDate(data.appointmentData.ExpArriveTime) }} -
                  {{ getFormatedDate(data.appointmentData.ExpDepartTime) }}</i
                >
              </p>
            </div>
            <!-- <p>Price: ${{data.appointmentData.price}}</p> -->
          </template>
        </DxScheduler>
        <br /><br />
      </div>
    </div>

    <!-- ACTIVITY DETAIL POP UP -->
    <DxPopup
      :title="$t('activity.activity_detail.title')"
      :visible="activityPopupVisible"
      :drag-enabled="false"
      :show-title="true"
      :on-hidden="onActivityDetailPopupHidden"
      :close-on-outside-click="true"
      height="auto"
      :width="'70%'"
    >
      <DxScrollView :scroll-by-content="true" :scroll-by-thumb="true">
        <div class="dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label">Allocation ID</div>
            <div class="dx-field-value">
              <DxTextBox :read-only="true" :value="allocationID" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Site ID</div>
            <div class="dx-field-value">
              <DxTextBox :read-only="true" :value="siteID" />
            </div>
          </div>
          <!-- hide for now -->
          <div class="dx-field" v-if="false">
            <div class="dx-field-label">Latitude</div>
            <div class="dx-field-value">
              <DxTextBox :read-only="true" :value="latitude" />
            </div>
          </div>
          <!-- hide for now -->
          <div class="dx-field" v-if="false">
            <div class="dx-field-label">Longitude</div>
            <div class="dx-field-value">
              <DxTextBox :read-only="true" :value="longitude" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Expected Arrive Time</div>
            <div class="dx-field-value">
              <DxTextBox :read-only="true" :value="expArriveTime" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Expected Depart Time</div>
            <div class="dx-field-value">
              <DxTextBox :read-only="true" :value="expDepartTime" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ $t("common.lbl_comment") }}</div>
            <div class="dx-field-value">
              <DxTextArea :read-only="true" :value="siteComment" height="75" />
            </div>
          </div>
        </div>
        <DxMap
          ref="activity-map"
          :zoom="mapConfig.zoom"
          :height="mapConfig.height"
          :width="mapConfig.width"
          :provider="mapConfig.provider"
          :ready="onMapReady"
          :auto-adjust="false"
          :center="centerOfMap"
        >
          <DxKey :google="mapConfig.googleKey" />
        </DxMap>
      </DxScrollView>
    </DxPopup>

    <!-- ACTIVITY ACTION POP UP -->
    <DxPopup
      :visible="appointmentOptionsVisible"
      :drag-enabled="false"
      :show-title="false"
      :on-hidden="onAppointmentOptionsPopupHidden"
      :close-on-outside-click="true"
      :show-Button="true"
      :height="'auto'"
      :width="'70%'"
    >
      <div style="margin-left: auto; margin-right: auto">
        <DxButton
          :title="$t('activity.activity_detail.title')"
          type="default"
          :width="'100%'"
          icon="file"
          :disabled="!isSelectedReourceAndAllocation"
          @click="onShowAppointmentDetailClick"
        />
        <br /><br />
        <DxButton
          :text="$t('perform_activity.title')"
          type="default"
          :width="'100%'"
          icon="runner"
          :disabled="!isSelectedReourceAndAllocation"
          @click="onPerformActivityClick"
        />
        <br /><br />
        <DxButton
          :text="$t('activity.btn_navigate_to_location')"
          type="default"
          :width="'100%'"
          icon="map"
          :disabled="!isSelectedReourceAndAllocation"
          @click="onNavigateToAppointmentLocationClick"
        />
        <br /><br />
        <DxButton
          :text="$t('activity.btn_send_notification')"
          type="default"
          :width="'100%'"
          icon="message"
          :disabled="!isSelectedReourceAndAllocation"
          @click="onSendNotificationClick"
        />
        <br /><br />
        <DxButton
          text="Truck Load"
          type="default"
          :width="'100%'"
          icon="download"
          :disabled="!isSelectedReourceAndAllocation"
          @click="onTruckLoadClick"
        />
        <br /><br />
        <DxButton
          text="Truck Unload"
          type="default"
          :width="'100%'"
          icon="upload"
          :disabled="!isSelectedReourceAndAllocation"
          @click="onTruckUnloadClick"
        />
      </div>
    </DxPopup>
  </div>
</template>

<script>
import api from "../commons/api";
import util from "../commons/utility";
import notify from "devextreme/ui/notify";
import DxScheduler from "devextreme-vue/scheduler";
import DataSource from "devextreme/data/data_source";
import DxPopup from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxMap, DxKey } from "devextreme-vue/map";
import DxTextBox from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxButton from "devextreme-vue/button";
import SelectedDataAccordion from "../components/selected-data-accordion";

export default {
  async mounted() {
    const shouldAutoSelectActivity = this.$route.query.shouldAutoSelectActivity;

    if (shouldAutoSelectActivity) {
      this.shouldAutoSelectActivity = shouldAutoSelectActivity;
    } else {
      util.clearSelectedAppointment();
    }

    this.schedulerConfig.dataSource.on({
      changed: this.handleSchedulerDataChanged,
    });

    const hasSelectedResourceAndAllocation =
      await util.hasSelectedResourceAndAllocation();

    if (!hasSelectedResourceAndAllocation) {
      this.isSelectedReourceAndAllocation = false;
      notify("Please select resource first", "error");
    } else {
      this.isSelectedReourceAndAllocation = true;
    }
  },
  data() {
    let _http = this.$http;

    return {
      deviceReady: false,
      selectedAppointmentId: undefined,
      isSelectedReourceAndAllocation: false,
      selectedActivity: undefined,
      appointmentOptionsVisible: false,
      activityPopupVisible: false,

      schedulerReady: false,
      schedulerCurrentDate: new Date(),

      schedulerConfig: {
        views: [],
        startDayHour: 0,
        endDayHour: 24,
        dataSource: new DataSource({
          key: "Seq",
          byKey: function () {},
          load: function () {
            return _http.get(
              `${api.API_URL}${
                api.GET_ALLOCATION_PLAN_URL
              }?allocationID=${util.getSelectedAllocationId()}`
            );
          },
        }),
        remoteFiltering: true,
        dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
        textExpr: "SiteID",
        startDateExpr: "ExpArriveTime",
        endDateExpr: "ExpDepartTime",
        editing: {
          // disable all action for now
          allowAdding: false,
          allowDeleting: false,
          allowDragging: false,
          allowResizing: false,
          allowTimeZoneEditing: true,
          allowUpdating: false,
        },
      },

      mapConfig: {
        width: "100%",
        height: "300",
        zoom: 14,
        provider: "google",
        googleKey: "AIzaSyDXUe4sWzgd_Ab4y516s8Y9RcxXDw_zSoo",
      },

      centerOfMap: { lat: 0.0, lng: 0.0 },
    };
  },
  computed: {
    allocationID() {
      return this.selectedActivity ? this.selectedActivity.AllocationID : "";
    },
    siteID() {
      return this.selectedActivity ? this.selectedActivity.SiteID : "";
    },
    latitude() {
      return this.selectedActivity ? "" + this.selectedActivity.Latitude : "";
    },
    longitude() {
      return this.selectedActivity ? "" + this.selectedActivity.Longitude : "";
    },
    expArriveTime() {
      return this.selectedActivity
        ? this.selectedActivity.ExpArriveTime.replace("T", " ")
        : "";
    },
    expDepartTime() {
      return this.selectedActivity
        ? this.selectedActivity.ExpDepartTime.replace("T", " ")
        : "";
    },
    siteComment() {
      return this.selectedActivity &&
        this.selectedActivity.Sites &&
        this.selectedActivity.Sites.Comment
        ? this.selectedActivity.Sites.Comment
        : "";
    },
  },
  methods: {
    onAppointmentClick(e) {
      e.cancel = true;

      this.selectedActivity = e.appointmentData;

      // delete all previous marker, add new one
      let activityMap = this.$refs["activity-map"].instance;

      let markers = activityMap.option("markers");
      if (markers) {
        markers.forEach((marker) => {
          activityMap.removeMarker(marker);
        });
      }

      let location = {
        lat: this.selectedActivity.Latitude
          ? this.selectedActivity.Latitude
          : 0.0,
        lng: this.selectedActivity.Longitude
          ? this.selectedActivity.Longitude
          : 0.0,
        // tooltip: this.selectedActivity.SiteID ? { text: this.selectedActivity.SiteID } : undefined
      };

      // add new marker
      activityMap.addMarker({
        location: location,
      });

      activityMap.option("zoom", this.mapConfig.zoom);

      this.centerOfMap = location;

      this.activityPopupVisible = false;
      this.appointmentOptionsVisible = true;
    },
    onAppointmentFormOpening(e) {
      e.cancel = true;
    },
    onMapReady(e) {},
    onActivityDetailPopupHidden() {
      this.activityPopupVisible = false;
    },
    onAppointmentOptionsPopupHidden() {
      this.appointmentOptionsVisible = false;
    },
    onShowAppointmentDetailClick() {
      this.activityPopupVisible = true;
      this.appointmentOptionsVisible = false;
    },
    onNavigateToAppointmentLocationClick() {
      if (
        this.selectedActivity.Latitude &&
        this.selectedActivity.Latitude > 0.0 &&
        this.selectedActivity.Longitude &&
        this.selectedActivity.Longitude > 0.0
      ) {
        let destLatLng = `${this.selectedActivity.Latitude},${this.selectedActivity.Longitude}`;

        window.open(
          `https://www.google.com/maps/search/?api=1&query=${destLatLng}&zoom=10`,
          "_system"
        );
      }
    },

    handleSchedulerDataChanged(e) {
      const selectedActivity = util.getSelectedAppointment();

      if (this.shouldAutoSelectActivity && selectedActivity) {
        this.showToAppointment(selectedActivity);
      }
    },

    saveSelectedActivity(selectedActivity) {
      util.saveSelectedAppointment(selectedActivity);
    },

    onPerformActivityClick() {
      this.saveSelectedActivity(this.selectedActivity);

      // add perform activity to the stack
      const siteID = this.selectedActivity
        ? this.selectedActivity.SiteID
        : null;

      this.$router
        .replace({
          name: "perform-activity",
          params: { siteID: siteID },
          query: { isFromActivitiesList: true },
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSendNotificationClick() {
      this.saveSelectedActivity(this.selectedActivity);

      this.$router.replace("/send-message").catch(() => {});
    },
    onTruckLoadClick() {
      this.saveSelectedActivity(this.selectedActivity);

      this.$router
        .replace({ name: "truck-load-unload", params: { truckMode: "load" } })
        .catch(() => {});
    },
    onTruckUnloadClick() {
      this.saveSelectedActivity(this.selectedActivity);

      this.$router
        .push({ name: "truck-load-unload", params: { truckMode: "unload" } })
        .catch(() => {});
    },
    onShowPreviousAppointmentClick() {
      let dateOffset = 24 * 60 * 60 * 1000;
      let scheduler = this.$refs["appointment-scheduler"].instance;

      let items = scheduler.getDataSource().items()
        ? [...scheduler.getDataSource().items()]
        : [];
      let sortedItems = this.sortItems(items);

      if (sortedItems) {
        let appointmentId;

        if (!this.selectedAppointmentId) {
          appointmentId = sortedItems[0].Seq;
        } else {
          let currSelectedItemIdx = sortedItems.findIndex(
            (i) => i.Seq === this.selectedAppointmentId
          );
          if (currSelectedItemIdx > 0) {
            appointmentId = sortedItems[currSelectedItemIdx - 1].Seq;
          }
        }

        if (appointmentId) {
          let newItem = sortedItems.find((i) => i.Seq === appointmentId);

          if (newItem.ExpArriveTime) {
            let currentSchedulerDate = scheduler.option("currentDate");
            let newItemDate = new Date(newItem.ExpArriveTime);

            if (
              currentSchedulerDate.toDateString() !== newItemDate.toDateString()
            ) {
              this.schedulerCurrentDate = newItemDate;
              return;
            }

            document
              .getElementById(`appointment-${appointmentId}`)
              .scrollIntoView();
            this.selectedAppointmentId = appointmentId;
          }
        }
      }
    },
    onShowNextAppointmentClick() {
      let scheduler = this.$refs["appointment-scheduler"].instance;
      let items = scheduler.getDataSource().items()
        ? [...scheduler.getDataSource().items()]
        : [];

      let sortedItems = this.sortItems(items);

      if (sortedItems) {
        let appointmentId;

        if (!this.selectedAppointmentId) {
          appointmentId = sortedItems[0].Seq;
        } else {
          let currSelectedItemIdx = sortedItems.findIndex(
            (i) => i.Seq === this.selectedAppointmentId
          );
          if (
            currSelectedItemIdx >= 0 &&
            currSelectedItemIdx < sortedItems.length - 1
          ) {
            appointmentId = sortedItems[currSelectedItemIdx + 1].Seq;
          }
        }

        if (appointmentId) {
          let newItem = sortedItems.find((i) => i.Seq === appointmentId);

          if (newItem.ExpArriveTime) {
            let currentSchedulerDate = scheduler.option("currentDate");
            let newItemDate = new Date(newItem.ExpArriveTime);

            if (
              currentSchedulerDate.toDateString() !== newItemDate.toDateString()
            ) {
              this.schedulerCurrentDate = newItemDate;
              return;
            }

            document
              .getElementById(`appointment-${appointmentId}`)
              .scrollIntoView();
            this.selectedAppointmentId = appointmentId;
          }
        }
      }
    },
    showToAppointment(appointment) {
      console.log("showToAppointment");

      const scheduler = this.$refs["appointment-scheduler"].instance;

      const appointmentId = appointment.Seq;
      const itemDate = new Date(appointment.ExpArriveTime);
      const currentSchedulerDate = scheduler.option("currentDate");

      if (currentSchedulerDate.toDateString() !== itemDate.toDateString()) {
        this.schedulerCurrentDate = itemDate;
        return;
      }

      document.getElementById(`appointment-${appointmentId}`).scrollIntoView();
      this.selectedAppointmentId = appointmentId;
    },
    sortItems(items) {
      return items.sort((a, b) => {
        // sort by seq
        if (!a && !b) return 0;

        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      });
    },
    onSchedulerReady(e) {
      this.schedulerReady = true;

      if (this.selectedAppointmentId) {
        let element = document.getElementById(
          `appointment-${this.selectedAppointmentId}`
        );
        if (element) element.scrollIntoView();
      }
    },
    setAppointmentClass(appointmentData) {
      let classObject = {
        "dx-template-wrapper": true,
        "dx-item-content": true,
        "dx-scheduler-appointment-content": true,
      };

      if (
        this.selectedAppointmentId &&
        appointmentData.Seq === this.selectedAppointmentId
      ) {
        classObject["selected-appointment"] = true;
      }

      return classObject;
    },
    getFormatedDate(stringDate) {
      if (stringDate) {
        return this.$moment(stringDate).format("DD MMMM YYYY hh:mm A");
      }
      return "";
    },
    pressBackButton(event) {
      // if(this.deviceReady) {
      //   if(this.appointmentOptionsVisible) {
      //     this.appointmentOptionsVisible = false;
      //   }
      // }
    },
  },
  components: {
    DxScheduler,
    DxPopup,
    DxScrollView,
    DxMap,
    DxKey,
    DxTextBox,
    DxTextArea,
    DxButton,
    SelectedDataAccordion,
  },
};
</script>

<style lang="scss">
.selected-appointment {
  background-color: #00c853;
}

.dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection.dx-popup-title {
  padding-left: 20px;
  padding-right: 20px;
}

.dx-scheduler-view-switcher.dx-dropdownmenu.dx-dropdownmenu-button.dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-icon {
  display: none;
}

.dx-scheduler-view-switcher.dx-tabs.dx-widget.dx-visibility-change-handler.dx-collection.dx-empty-collection.dx-tabs-expanded {
  display: none;
}

.dx-scheduler-view-switcher-label {
  display: none;
}
</style>
