<template>
  <div>
    <h2 class="content-block">Pod Detail</h2>
    <div class="content-block">
      <div
        class="dx-card responsive-paddings"
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <div class="dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label">
              <i class="dx-icon-selectall field-label-icon" />Activity ID
            </div>
            <div class="dx-field-value">
              <DxTextBox :readOnly="true" :value="podDetail.LogID" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">
              <i class="dx-icon-runner field-label-icon" />Activity
            </div>
            <div class="dx-field-value">
              <DxTextBox :readOnly="true" :value="podDetail.LogType" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">
              <i class="dx-icon-home field-label-icon" />Site ID
            </div>
            <div class="dx-field-value">
              <DxTextBox :readOnly="true" :value="podDetail.SiteID" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">
              <i class="dx-icon-car field-label-icon" />Resource ID
            </div>
            <div class="dx-field-value">
              <DxTextBox :readOnly="true" :value="podDetail.ResourceID" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">
              <i class="dx-icon-doc field-label-icon" />Allocation ID
            </div>
            <div class="dx-field-value">
              <DxTextBox :readOnly="true" :value="podDetail.AllocationID" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">
              <i class="dx-icon-clock field-label-icon" />Performed at
            </div>
            <div class="dx-field-value">
              <DxTextBox :readOnly="true" :value="podDetail.UpdateDate" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">
              <i class="dx-icon-group field-label-icon" />Performed by
            </div>
            <div class="dx-field-value">
              <DxTextBox :readOnly="true" :value="podDetail.UpdateBy" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">
              <i class="dx-icon-map field-label-icon" />Latitude
            </div>
            <div class="dx-field-value">
              <DxTextBox :readOnly="true" :value="String(podDetail.Latitude)" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">
              <i class="dx-icon-map field-label-icon" />Longitude
            </div>
            <div class="dx-field-value">
              <DxTextBox
                :readOnly="true"
                :value="String(podDetail.Longitude)"
              />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">
              <i class="dx-icon-comment field-label-icon" />Notes
            </div>
            <div class="dx-field-value">
              <DxTextArea
                :readOnly="true"
                height="100"
                :value="podDetail.Value3"
              />
            </div>
          </div>
        </div>
        <DxButton
          text="Back"
          type="danger"
          icon="close"
          width="50%"
          :onClick="goBack"
        />
        <div class="image-info-container" v-if="false">
          <p class="dx-field-label dx-field-image-label">Location</p>
          <iframe
            src="https://maps.google.com/maps?q=-6.1753871,106.8249641&hl=es;z=15&amp;output=embed"
            style="border: 0"
            loading="lazy"
            allowfullscreen
            width="100%"
            height="300px"
          />
        </div>
        <div class="image-info-container" v-if="false">
          <p class="dx-field-label dx-field-image-label">Signature</p>
          <img
            src="https://www.docsketch.com/assets/vip-signatures/muhammad-ali-signature-6a40cd5a6c27559411db066f62d64886c42bbeb03b347237ffae98b0b15e0005.svg"
            alt="Signature Image Not Found"
            width="300px"
            height="300px"
          />
        </div>
        <div class="image-info-container" v-if="false">
          <p class="dx-field-label dx-field-image-label">Captured Image</p>
          <img
            src="https://image.shutterstock.com/image-vector/realistic-paper-shop-receipt-barcode-260nw-768909406.jpg"
            alt="Captured Image Not Found"
            width="300px"
            height="300px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DxTextBox, DxTextArea, DxButton } from "devextreme-vue";

export default {
  data() {
    return {
      podId: this.$route.params.podId,
      podDetail: this.$route.params.podDetail,
    };
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },

  components: {
    DxTextBox,
    DxTextArea,
    DxButton,
  },
};
</script>

<style lang="scss" scoped>
.dx-field > .dx-field-label {
  width: 40% !important;
  display: flex;
  align-items: center;

  .field-label-icon {
    margin-right: 0.2em;
  }
}

.dx-field > .dx-field-value {
  width: 60% !important;
}

.image-info-container {
  display: flex;
  flex-direction: column;

  img {
    margin-right: auto;
    margin-left: auto;
  }
}

.dx-field-image-label {
  font-size: 1.2em;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
}
</style>
