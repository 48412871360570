<template>
  <div>
    <h2 class="content-block">SQL Work Bench</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <dx-simple-item :col-span="3" template="btn-take-odo" />
        <!-- <DxButton
          width="
          type="danger"
          icon="trash"
          text="Drop Table"
          @click="() => {}"
        /> -->
      </div>
    </div>
  </div>
</template>
<script>
import { DxButton } from "devextreme-vue/button";

export default {
  components: {
    // DxButton,
  },
};
</script>
<style lang="scss"></style>
