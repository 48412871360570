import { truckMode } from "../commons/constants";

let db = null;

function initialize() {
  db = window.sqlitePlugin.openDatabase({
    name: "pscope.db",
    location: "default",
  });

  prepareDatabase();
}

function prepareDatabase() {
  if (db == null) {
    return;
  }

  db.transaction((tx) => {
    tx.executeSql(`CREATE TABLE IF NOT EXISTS allocations(
                        ResourceID      text,
                        AllocationID    text PRIMARY KEY,   
                        TenantID        integer,
                        TotalKM         real,
                        TotalHR         real,
                        TotalM3         real,
                        ExpStartTime    text,
                        ExpFinishTime   text,       
                        StartLocation   text,       
                        EndLocation     text,       
                        TotalDrops      integer
                    )`);

    tx.executeSql(`CREATE TABLE IF NOT EXISTS activity_types(
                        Action                text PRIMARY KEY,
                        Action_i18n           text,
                        Comment               text,
                        Comment_i18n          text,
                        ConfirmMessage_i18n   text, 
                        AvailableIn           text 
                    )`);

    tx.executeSql(`CREATE TABLE IF NOT EXISTS allocation_tasks(
                        AllocationID            text,                                
                        Seq                     integer,                          
                        SiteID                  text,                            
                        TaskID                  text,                            
                        GuidTaskID              text,                                                                           
                        ExpArriveTime           text,                              
                        ExpStartServiceTime     text,                               
                        ExpFinishTime           text,                              
                        ExpDepartTime           text,
                        UpdateDate              text,
                        UpdateBy                text,
                        CreatedDate             text,
                        CreatedBy               text,
                        Activity                text,                                                          
                        Activity_Status         text,
                        Load_Qty                float,                                                          
                        Unload_QtY              float,
                        Site_SiteID             text,                          
                        Site_Description        text,                                
                        Site_SiteTypeID         text,                                    
                        Site_Region             text,                          
                        Site_Longitude          float,                            
                        Site_Latitude           float,                               
                        Site_WaitTime           float,                               
                        Site_ServiceTime        float,                                
                        Site_Active             text,                          
                        Site_MateType           text,                              
                        Site_RouteID            text,                                
                        Site_Comment            text,                                
                        Site_Area               text,                              
                        Site_UpdateDate         text,                          
                        Site_UpdateBy           text,                              
                        Site_CreatedDate        text,                                
                        Site_CreatedBy          text,                            
                        Site_Notification       text,                              
                        Site_Address            text,                                
                        Site_Address2           text,                              
                        Site_City               text,                              
                        Site_State              text,                            
                        Site_PostalCode         text,                          
                        Site_Country            text,                                
                        Site_ContactName        text,                                
                        Site_ContactEmail       text,                              
                        Site_ContactPhone       text,
                        PRIMARY KEY (AllocationID, Seq)                              
                  )`);

    tx.executeSql(`CREATE TABLE IF NOT EXISTS task_lines(
                        RowNum            integer,
                        LineID            text PRIMARY KEY,
                        SSCC              text,
                        ItemID            text,
                        UOM               text,
                        Original_Qty      float,
                        Load_Qty          float,
                        Unload_QtY        float,
                        TaskID            text,
                        TaskIDString      text,
                        PackType          text,
                        BoxSize           text,
                        Local_Load_Qty    float,
                        Local_Unload_Qty  float                         
                    )`);

    tx.executeSql(`CREATE TABLE IF NOT EXISTS resource_logs(
                    LogID               text PRIMARY KEY,
                    AllocationID        text,
                    LogType             text,
                    SiteID              text,
                    TaskID              text,
                    Latitude            real, 
                    Longitude           real,
                    UpdateDate          text,
                    CreatedUser         text, 
                    Value3              text
                  )`);
  });
}

function truncateTables() {
  return new Promise((resolve, _) => {
    if (db == null) {
      resolve(false);
      return;
    }

    db.transaction(
      (tx) => {
        tx.executeSql(`DELETE FROM allocations`);
        tx.executeSql(`DELETE FROM activity_types`);
        tx.executeSql(`DELETE FROM allocation_tasks`);
        tx.executeSql(`DELETE FROM task_lines`);
        tx.executeSql(`DELETE FROM resource_logs`);
      },
      (_) => {
        resolve(false);
      },
      () => {
        resolve(true);
      }
    );
  });
}

function isDataNotValid(value) {
  return (
    value === null ||
    value === undefined ||
    (Array.isArray(value) && value.length === 0)
  );
}

function extractRowsFromResult(rows) {
  if (isDataNotValid(rows)) return [];

  const mRows = [];
  for (let i = 0; i < rows.length; i++) {
    mRows.push(rows.item(i));
  }

  return mRows;
}

function remoteTaskFromLocalTask(localTask) {
  if (isDataNotValid(localTask)) return null;

  const remoteTask = {};
  const remoteSite = {};

  for (let key in localTask) {
    if (key.startsWith("Site_")) {
      const realKey = key.replace("Site_", "");
      remoteSite[realKey] = localTask[key];
    } else {
      remoteTask[key] = localTask[key];
    }
  }

  remoteTask["Sites"] = remoteSite;

  return remoteTask;
}

function localTaskFromRemoteTask(remoteTask) {
  if (isDataNotValid(remoteTask)) return null;
  const localTask = {};

  for (let key in remoteTask) {
    if (key != "Sites") {
      localTask[key] = remoteTask[key];
    }

    const remoteSite = remoteTask["Sites"];
    if (!isDataNotValid(remoteSite)) {
      for (let siteKey in remoteSite) {
        const siteLocalKey = "Site_" + siteKey;
        localTask[siteLocalKey] = remoteSite[siteKey];
      }
    }
  }

  return localTask;
}

// ALLOCATIONS
function getAllocationsByUserName(userName, rawResponse) {
  return new Promise((resolve, reject) => {
    if (db == null) {
      reject("DB is null");
      return;
    }

    db.readTransaction((tx) => {
      tx.executeSql(
        "SELECT * FROM allocations",
        [],
        (_, result) => {
          const rows = extractRowsFromResult(result.rows);
          if (rawResponse) {
            resolve({ data: rows });
          } else {
            resolve(rows);
          }
        },
        function (_, __) {
          resolve([]);
        }
      );
    });
  });
}

function saveAllocations(allocations) {
  return new Promise((resolve, reject) => {
    if (db == null) {
      reject("DB is null");
      return;
    }

    if (isDataNotValid(allocations)) {
      resolve();
      return;
    }

    db.transaction(
      (tx) => {
        for (const allocation of allocations) {
          tx.executeSql(
            `INSERT OR REPLACE INTO allocations 
            VALUES (
              ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?
            )`,
            [
              allocation["ResourceID"],
              allocation["AllocationID"],
              allocation["TenantID"],
              allocation["TotalKM"],
              allocation["TotalHR"],
              allocation["TotalM3"],
              allocation["ExpStartTime"],
              allocation["ExpFinishTime"],
              allocation["StartLocation"],
              allocation["EndLocation"],
              allocation["TotalDrops"],
            ]
          );
        }
      },
      (error) => {
        console.log("Transaction ERROR: " + error.message);
        reject(error);
      },
      () => {
        console.log("Transaction OK");
        resolve();
      }
    );
  });
}

// ACTIVITIES
function getActivityTypes(rawResponse) {
  return new Promise((resolve, reject) => {
    if (db == null) {
      reject("DB is null");
      return;
    }

    db.readTransaction((tx) => {
      tx.executeSql(
        "SELECT * FROM activity_types",
        [],
        (_, result) => {
          const mRows = extractRowsFromResult(result.rows);

          const rows = mRows.map((r) => {
            const row = { ...r };

            row["Action_i18n"] = JSON.parse(r["Action_i18n"]);
            row["Comment"] = JSON.parse(r["Comment"]);
            row["Comment_i18n"] = JSON.parse(r["Comment_i18n"]);
            row["ConfirmMessage_i18n"] = JSON.parse(r["ConfirmMessage_i18n"]);
            row["AvailableIn"] = JSON.parse(r["AvailableIn"]);

            return row;
          });

          if (rawResponse) {
            resolve({ data: rows });
          } else {
            resolve(rows);
          }
        },
        function (_, __) {
          resolve([]);
        }
      );
    });
  });
}

function saveActivityTypes(activityTypes) {
  return new Promise((resolve, reject) => {
    if (db == null) {
      reject("DB is null");
      return;
    }

    if (isDataNotValid(activityTypes)) {
      resolve();
      return;
    }

    db.transaction(
      (tx) => {
        for (const activityType of activityTypes) {
          const actionI18n = activityType["Action_i18n"];
          const comment = activityType["Comment"];
          const commentI18n = activityType["Comment_i18n"];
          const confirmMessageI18n = activityType["ConfirmMessage_i18n"];
          const availableIn = activityType["AvailableIn"];

          tx.executeSql(
            `INSERT OR REPLACE INTO activity_types 
            VALUES (
              ?, ?, ?, ?, ?, ?
            )`,
            [
              activityType["Action"],
              JSON.stringify(isDataNotValid(actionI18n) ? null : actionI18n),
              JSON.stringify(isDataNotValid(comment) ? null : comment),
              JSON.stringify(isDataNotValid(commentI18n) ? null : commentI18n),
              JSON.stringify(
                isDataNotValid(confirmMessageI18n) ? null : confirmMessageI18n
              ),
              JSON.stringify(isDataNotValid(availableIn) ? null : availableIn),
            ]
          );
        }
      },
      (error) => {
        console.log("Transaction ERROR: " + error.message);
        reject(error);
      },
      () => {
        console.log("Transaction OK");
        resolve();
      }
    );
  });
}

function getAllocationPlansByAllocationId(allocationId, rawResponse) {
  return [];
}

// TASKS ALLOCATION
function getTasksByAllocationId(allocationId, rawResponse) {
  return new Promise((resolve, reject) => {
    if (db == null) {
      reject("DB is null");
      return;
    }

    db.readTransaction((tx) => {
      tx.executeSql(
        "SELECT * FROM allocation_tasks WHERE AllocationID = ?",
        [allocationId],
        (_, result) => {
          const localRows = extractRowsFromResult(result.rows);
          const rows = localRows.map((row) => remoteTaskFromLocalTask(row));

          if (rawResponse) {
            resolve({ data: rows });
          } else {
            resolve(rows);
          }
        },
        function (_, __) {
          resolve([]);
        }
      );
    });
  });
}

function saveTasksAllocation(tasksAllocation) {
  return new Promise((resolve, reject) => {
    if (db == null) {
      reject("DB is null");
      return;
    }

    if (isDataNotValid(tasksAllocation)) {
      resolve();
      return;
    }

    db.transaction(
      (tx) => {
        for (const ta of tasksAllocation) {
          const lt = localTaskFromRemoteTask(ta);
          tx.executeSql(
            `INSERT OR REPLACE INTO allocation_tasks 
            VALUES (
              ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?,
              ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?,
              ?, ?, ?, ?
            )`,
            [
              lt["AllocationID"],
              lt["Seq"],
              lt["SiteID"],
              lt["TaskID"],
              lt["GuidTaskID"],
              lt["ExpArriveTime"],
              lt["ExpStartServiceTime"],
              lt["ExpFinishTime"],
              lt["ExpDepartTime"],
              lt["UpdateDate"],
              lt["UpdateBy"],
              lt["CreatedDate"],
              lt["CreatedBy"],
              lt["Activity"],
              lt["Activity_Status"],
              lt["Load_Qty"],
              lt["Unload_QtY"],
              lt["Site_SiteID"],
              lt["Site_Description"],
              lt["Site_SiteTypeID"],
              lt["Site_Region"],
              lt["Site_Longitude"],
              lt["Site_Latitude"],
              lt["Site_WaitTime"],
              lt["Site_ServiceTime"],
              lt["Site_Active"],
              lt["Site_MateType"],
              lt["Site_RouteID"],
              lt["Site_Comment"],
              lt["Site_Area"],
              lt["Site_UpdateDate"],
              lt["Site_UpdateBy"],
              lt["Site_CreatedDate"],
              lt["Site_CreatedBy"],
              lt["Site_Notification"],
              lt["Site_Address"],
              lt["Site_Address2"],
              lt["Site_City"],
              lt["Site_State"],
              lt["Site_PostalCode"],
              lt["Site_Country"],
              lt["Site_ContactName"],
              lt["Site_ContactEmail"],
              lt["Site_ContactPhone"],
            ]
          );
        }
      },
      (error) => {
        console.log("Transaction ERROR: " + error.message);
        reject(error);
      },
      () => {
        console.log("Transaction OK");
        resolve();
      }
    );
  });
}

function getTaskLinesByTaskIds(taskIds, rawResponse) {
  return new Promise((resolve, reject) => {
    if (db == null) {
      reject("DB is null");
      return;
    }

    const taskIdsPlaceholder = new Array(taskIds.length).fill("?").join(",");
    db.readTransaction((tx) => {
      tx.executeSql(
        `SELECT * FROM task_lines WHERE TaskID IN (${taskIdsPlaceholder})`,
        taskIds,
        (_, result) => {
          const rows = extractRowsFromResult(result.rows);
          // replace actual qty with local qty
          rows.forEach((row) => {
            const loadQty = row["Load_Qty"] ?? 0;
            const unloadQty = row["Unload_QtY"] ?? 0;
            const localLoadQty = row["Local_Load_Qty"] ?? 0;
            const localUnloadQty = row["Local_Unload_Qty"] ?? 0;

            row["Load_Qty"] = loadQty >= localLoadQty ? loadQty : localLoadQty;
            row["Unload_QtY"] =
              unloadQty >= localUnloadQty ? unloadQty : localUnloadQty;
          });

          if (rawResponse) {
            resolve({ data: rows });
          } else {
            resolve(rows);
          }
        },
        function (_, __) {
          resolve([]);
        }
      );
    });
  });
}

function saveTaskLines(taskLines) {
  return new Promise((resolve, reject) => {
    if (db == null) {
      reject("DB is null");
      return;
    }

    if (isDataNotValid(taskLines)) {
      resolve();
      return;
    }

    db.transaction(
      (tx) => {
        for (const taskLine of taskLines) {
          const lineId = taskLine["LineID"];
          let localLoadQty = taskLine["Load_Qty"];
          let localUnloadQty = taskLine["Unload_QtY"];

          tx.executeSql(
            "SELECT * FROM task_lines WHERE LineID = ?",
            [lineId],
            (tx, result) => {
              if (result.length > 0) {
                const oldData = result.item(0);
                localLoadQty = oldData["Local_Load_Qty"];
                localUnloadQty = oldData["Local_Unload_Qty"];
              }

              tx.executeSql(
                `INSERT OR REPLACE INTO task_lines 
            VALUES (
              ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?
            )`,
                [
                  taskLine["RowNum"],
                  taskLine["LineID"],
                  taskLine["SSCC"],
                  taskLine["ItemID"],
                  taskLine["UOM"],
                  taskLine["Original_Qty"],
                  taskLine["Load_Qty"],
                  taskLine["Unload_QtY"],
                  taskLine["TaskID"],
                  taskLine["TaskIDString"],
                  taskLine["PackType"],
                  taskLine["BoxSize"],
                  localLoadQty,
                  localUnloadQty,
                ]
              );
            }
          );
        }
      },
      (error) => {
        console.log("Transaction ERROR: " + error.message);
        reject(error);
      },
      () => {
        console.log("Transaction OK");
        resolve();
      }
    );
  });
}

function postTaskLine(taskLine, rawResponse) {
  return new Promise((resolve, reject) => {
    if (db == null) {
      reject("DB is null");
      return;
    }

    if (isDataNotValid(taskLine)) {
      resolve();
      return;
    }

    db.transaction(
      (tx) => {
        const type = taskLine["Type"].toLowerCase();
        let columnToUpdate = "";

        if (type == truckMode.LOAD) {
          columnToUpdate = "Local_Load_Qty";
        } else if (type == truckMode.UNLOAD) {
          columnToUpdate = "Local_Unload_Qty";
        }
        tx.executeSql(
          `UPDATE task_lines SET ${columnToUpdate} = ?
              WHERE LineID = ?`,
          [taskLine["Quantity"], taskLine["LineID"]]
        );
      },
      (error) => {
        console.log("Transaction ERROR: " + error.message);
        reject(error);
      },
      () => {
        console.log("Transaction OK");
        if (rawResponse) {
          resolve({
            data: [
              {
                ErrorNumber: 50000,
              },
            ],
          });
        } else {
          resolve();
        }
      }
    );
  });
}

function validateTaskLines(taskLines, rawResponse) {
  return new Promise((resolve, reject) => {
    if (db == null) {
      reject("DB is null");
      return;
    }

    if (isDataNotValid(taskLines)) {
      resolve();
      return;
    }

    db.transaction(
      (tx) => {
        for (const vtl of taskLines) {
          const type = vtl["Type"].toLowerCase();
          let columnToUpdate = "";

          if (type == truckMode.LOAD) {
            columnToUpdate = "Local_Load_Qty";
          } else if (type == truckMode.UNLOAD) {
            columnToUpdate = "Local_Unload_Qty";
          }

          tx.executeSql(
            `UPDATE task_lines SET ${columnToUpdate} = ?
              WHERE LineID = ?`,
            [vtl["Quantity"], vtl["LineID"]]
          );
        }
      },
      (error) => {
        console.log("Transaction ERROR: " + error.message);
        reject(error);
      },
      () => {
        console.log("Transaction OK");
        if (rawResponse) {
          resolve({
            data: {
              ErrorNumber: 50000,
            },
          });
        } else {
          resolve();
        }
      }
    );
  });
}

function getResourceLogsByAllocationId(allocationId, rawResponse) {
  return new Promise((resolve, reject) => {
    if (db == null) {
      reject("DB is null");
      return;
    }

    db.readTransaction((tx) => {
      tx.executeSql(
        "SELECT * FROM resource_logs WHERE AllocationID = ?",
        [allocationId],
        (_, result) => {
          const rows = extractRowsFromResult(result.rows);
          if (rawResponse) {
            resolve({ data: rows });
          } else {
            resolve(rows);
          }
        },
        function (_, error) {
          resolve([]);
        }
      );
    });
  });
}

function saveResourceLogs(resourceLogs) {
  return new Promise((resolve, reject) => {
    if (db == null) {
      reject("DB is null");
      return;
    }

    if (isDataNotValid(resourceLogs)) {
      resolve();
      return;
    }

    db.transaction(
      (tx) => {
        for (const rl of resourceLogs) {
          tx.executeSql(
            `INSERT OR REPLACE INTO resource_logs 
            VALUES (
              ?, ?, ?, ?, ?, ?, ?, ?, ?, ?
            )`,
            [
              rl["LogID"],
              rl["AllocationID"],
              rl["LogType"],
              rl["SiteID"],
              rl["TaskID"],
              rl["Latitude"],
              rl["Longitude"],
              rl["UpdateDate"],
              rl["CreatedUser"],
              rl["Value3"],
            ]
          );
        }
      },
      (error) => {
        console.log("Transaction ERROR: " + error.message);
        reject(error);
      },
      () => {
        console.log("Transaction OK");
        resolve();
      }
    );
  });
}

export default {
  initialize,
  getAllocationsByUserName,
  saveAllocations,
  getActivityTypes,
  saveActivityTypes,
  getAllocationPlansByAllocationId,
  getTasksByAllocationId,
  saveTasksAllocation,
  getTaskLinesByTaskIds,
  saveTaskLines,
  postTaskLine,
  validateTaskLines,
  getResourceLogsByAllocationId,
  saveResourceLogs,
  truncateTables,
};
