const INFINITE_TOAST = 2147483647;

export default {
    INFINITE_TOAST
}

export const planStatus = {
    COMPLETED: "Completed",
    ON_GOING: "On Going",
    OPEN: "Open",
  };
  
  export const activityType = {
    START: "START",
    PICK_UP_TASK: "PICK UP TASK",
    TRAVEL_TO: "TRAVEL TO",
    DROP_OFF_TASK: "DROP OFF TASK",
    FINISH: "FINISH",
  };

  export const truckMode = {
    LOAD: "load",
    UNLOAD: "unload",
  };