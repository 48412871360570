<template>
  <div id="content-container">
    <h2 class="content-block">{{ $t("refuel_or_toll.title") }}</h2>
    <SelectedDataAccordion
      @onCurrentLocationChanged="onCurrentLocationChanged"
    />
    <div class="content-block dx-card">
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">
            {{ $t("refuel_or_toll.hint_reason") }}
          </div>
          <div class="dx-field-value">
            <DxSelectBox
              :placeholder="$t('refuel_or_toll.hint_reason')"
              :items="reasonsData"
              displayExpr="reasonName"
              :onValueChanged="onReasonChanged"
            />
          </div>
        </div>
        <div class="dx-field" v-if="computeselectedReason == TYPES.REFUEL">
          <div class="dx-field-label">
            {{ $t("refuel_or_toll.lbl_odometer") }}
          </div>
          <div class="dx-field-value">
            <DxNumberBox
              :placeholder="$t('refuel_or_toll.hint_odometer')"
              :show-spin-buttons="false"
              :show-clear-button="false"
              :min="textOdoOptions.min"
              :max="textOdoOptions.max"
              format="#"
              v-model="odoValue"
            />
          </div>
        </div>
        <div class="dx-field" v-if="computeselectedReason == TYPES.REFUEL">
          <div class="dx-field-label">{{ $t("refuel_or_toll.lbl_litre") }}</div>
          <div class="dx-field-value">
            <DxNumberBox
              :placeholder="$t('refuel_or_toll.hint_litre')"
              :show-spin-buttons="true"
              :show-clear-button="false"
              :min="textLitreOptions.min"
              :max="textLitreOptions.max"
              format="#.###"
              v-model="litresValue"
            />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">{{ $t("refuel_or_toll.lbl_cost") }}</div>
          <div class="dx-field-value">
            <DxNumberBox
              :placeholder="$t('refuel_or_toll.hint_cost')"
              :show-spin-buttons="false"
              :show-clear-button="false"
              :min="textCostOptions.min"
              :max="textCostOptions.max"
              :format="$t('symbols.currency') + ' #.##'"
              v-model="costValue"
            />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">
            {{ $t("refuel_or_toll.lbl_comment") }}
          </div>
          <div class="dx-field-value">
            <DxTextArea
              :placeholder="$t('refuel_or_toll.hint_comment')"
              max-length="100"
              v-model="commentValue"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="content-block dx-card responsive-paddings">
      <dx-form
        id="form"
        label-location="top"
        :colCountByScreen="colCountByScreen"
      >
        <dx-simple-item :col-span="3" template="btn-receipt-photo" />
        <dx-simple-item v-if="false" :col-span="3" template="btn-snap-upload" />
        <dx-simple-item
          v-if="computeselectedReason == TYPES.REFUEL"
          :col-span="3"
          template="btn-odo-photo"
        />
        <dx-simple-item v-if="false" :col-span="3" template="btn-history" />
        <dx-simple-item
          v-if="computeselectedReason == TYPES.TOLL"
          :col-span="3"
          template="btn-toll-photo"
        />
        <dx-simple-item :col-span="3" template="btn-save" />

        <template #btn-receipt-photo>
          <ImagePickerButton
            ref="btn-receipt-photo"
            :buttonType="btnReceiptUploadOptions.type"
            :icon="btnReceiptUploadOptions.icon"
            :buttonText="$t('refuel_or_toll.btn_upload_receipt')"
            :disabled="!isValid"
            :imageResult.sync="receiptImageResult"
          />
        </template>

        <template #btn-odo-photo>
          <ImagePickerButton
            ref="btn-receipt-photo"
            :buttonType="btnOdoUploadOptions.type"
            :icon="btnOdoUploadOptions.icon"
            :buttonText="$t('refuel_or_toll.btn_upload_odometer')"
            :disabled="!isValid"
            :imageResult.sync="odoImageResult"
          />
        </template>

        <template #btn-toll-photo>
          <ImagePickerButton
            :buttonType="btnTollPhotoOptions.type"
            :icon="btnTollPhotoOptions.icon"
            height="100%"
            :buttonText="$t('refuel_or_toll.btn_upload_toll')"
            :disabled="!isValid"
            :imageResult.sync="tollImageResult"
            @uploaded="onFinishUploadTollPhoto"
          />
        </template>

        <template #btn-snap-upload>
          <ImagePickerButton
            :buttonType="btnSnapUploadOptions.type"
            :icon="btnSnapUploadOptions.icon"
            height="100%"
            :buttonText="$t('refuel-or-toll.btn_snap_upload')"
            :disabled="true"
            :imageResult.sync="snapImageResult"
            @uploaded="onFinishUploadSnap"
          />
        </template>

        <template #btn-history>
          <DxButton
            :width="btnHistoryOptions.width"
            :type="btnHistoryOptions.type"
            :icon="btnHistoryOptions.icon"
            :text="$t('refuel-or-toll.btn_history')"
            :disabled="true"
          />
        </template>

        <template #btn-save>
          <DxButton
            :width="btnSaveOptions.width"
            :type="btnSaveOptions.type"
            :icon="btnSaveOptions.icon"
            :text="$t('refuel_or_toll.btn_save')"
            :disabled="!isValid"
            @click="btnSaveOptions.onClick"
          />
        </template>
      </dx-form>
    </div>
    <DxLoadPanel
      :visible="isLoading"
      :position="{ of: '#content-container' }"
      :close-on-outside-click="false"
    />
  </div>
</template>

<script>
import api from "../commons/api";
import util from "../commons/utility";
import i18n from "./../i18n";
import notify from "devextreme/ui/notify";
import DxLoadPanel from "devextreme-vue/load-panel";
import DxSelectBox from "devextreme-vue/select-box";
import { DxForm, DxSimpleItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import { DxNumberBox } from "devextreme-vue/number-box";
import { DxTextArea } from "devextreme-vue/text-area";
import ImagePickerButton from "../components/image-picker-button";
import SelectedDataAccordion from "../components/selected-data-accordion";

const TYPES = {
  REFUEL: "Refuel",
  TOLL: "Toll",
};

const reason = [
  {
    reasonId: "refuel",
    reasonName: "Refuel",
    type: TYPES.REFUEL,
  },
  {
    reasonId: "Toll",
    reasonName: "Toll",
    type: TYPES.TOLL,
  },
  {
    reasonId: "Expressway-toll",
    reasonName: "Exressway Toll",
    type: TYPES.TOLL,
  },
  {
    reasonId: "Ferry-charge",
    reasonName: "Ferry Charge",
    type: TYPES.TOLL,
  },
];

export default {
  async mounted() {
    const hasSelectedResourceAndAllocation =
      await util.hasSelectedResourceAndAllocation();

    if (!hasSelectedResourceAndAllocation) {
      this.isSelectedReourceAndAllocation = false;
      notify("Please select resource first", "error");
    } else {
      this.isSelectedReourceAndAllocation = true;
    }
  },
  data() {
    return {
      loadingSave: false,
      loadingUploadReceipt: false,
      loadingUploadOdo: false,
      loadingUploadToll: false,

      TYPES: TYPES,
      selectedReason: undefined,

      loading: false,

      odoValue: 0,
      litresValue: 0.0,
      costValue: 0.0,
      commentValue: "",
      currentLat: null,
      currentLon: null,

      odoImageResult: undefined,
      receiptImageResult: undefined,
      snapImageResult: undefined,
      tollImageResult: undefined,

      isSelectedReourceAndAllocation: false,

      reasonsData: reason,

      textOdoOptions: {
        placeholder: "Odometers value",
        min: 0,
        max: 10000000,
        format: "# KM",
      },

      textLitreOptions: {
        placeholder: "Fuel litres",
        min: 0,
        max: 1000,
        format: "#.## L",
      },

      textCostOptions: {
        placeholder: "Cost",
        min: 0,
        max: 1000000,
      },

      btnReceiptUploadOptions: {
        icon: "upload",
        type: "default",
        width: "100%",
        text: "Receipt Upload",
      },

      btnOdoUploadOptions: {
        icon: "upload",
        type: "default",
        width: "100%",
        text: "Odometer Upload",
      },

      btnSnapUploadOptions: {
        icon: "upload",
        type: "default",
        width: "100%",
        text: "Snap Upload",
      },

      btnTollPhotoOptions: {
        icon: "upload",
        type: "default",
        width: "100%",
        text: "Toll Photo",
      },

      btnHistoryOptions: {
        icon: "columnchooser",
        type: "default",
        width: "100%",
        text: "History",
      },

      btnSaveOptions: {
        icon: "save",
        type: "success",
        width: "100%",
        onClick: this.saveData,
      },

      colCountByScreen: {
        xs: 1,
        sm: 2,
        md: 4,
        lg: 6,
      },
    };
  },

  methods: {
    onCurrentLocationChanged(lat, lon) {
      this.currentLat = lat;
      this.currentLon = lon;
    },
    getselectedReason(type) {
      if (type === undefined || type === null) return null;

      if (type == TYPES.REFUEL) {
        this.odoValue = 0;
        this.litresValue = 0.0;

        return TYPES.REFUEL;
      }

      return TYPES.TOLL;
    },
    onReasonChanged(e) {
      const mSelectedReason = e.value;
      this.selectedReason = mSelectedReason;
    },
    saveData() {
      const selectedReason = this.selectedReason;

      const selecteResourceId = util.getSelectedResourceId();
      const selectedAllocationId = util.getSelectedAllocationId();
      // const selectedSite = util.getSelectedSite().SiteID;

      const gpsDateTime = util.getCurrentDateStr();

      const odo = this.odoValue;
      const litres = this.litresValue > 0 ? this.litresValue : null;
      const cost = this.costValue > 0 ? this.costValue : null;
      const comment = this.commentValue ? this.commentValue : "";
      const commentSummary =
        this.selectedReason.type == TYPES.REFUEL
          ? `${odo} - ${comment}`
          : comment;

      const param = {
        ResourceID: selecteResourceId,
        AllocationID: selectedAllocationId,
        // SiteID: selectedSite,
        LogType: selectedReason.type,
        Gps_Time: gpsDateTime,
        Latitude: this.currentLat,
        Longitude: this.currentLon,
        Value1: litres,
        Value2: cost,
        Value3: commentSummary,
      };

      this.loadingSave = true;
      this.$http
        .post(api.POST_RESOURCE_LOG_URL, param)
        .then((res) => {
          const logId = res.data.LogID;

          if (logId) {
            if (
              !this.receiptImageResult &&
              !this.odoImageResult &&
              !this.tollImageResult
            ) {
              this.loadingSave = false;
              notify(
                `Refuel / Tolls success, log id ${res.data.LogID}`,
                "success",
                2000
              );
              this.resetState();
              return;
            }

            if (this.receiptImageResult) {
              this.loadingUploadReceipt = true;
              this.loadingSave = false;

              this.$http({
                method: "post",
                url: api.POST_RESOURCE_LOG_IMAGE_URL,
                params: {
                  LogID: logId,
                  Type: "Value5",
                },
                data: Buffer.from(
                  this.receiptImageResult.split(";base64,")[1],
                  "base64"
                ),
                headers: { "Content-Type": "image/jpeg" },
              })
                .then((resp) => {
                  ("success receipt result");
                  this.loadingUploadReceipt = false;

                  if (!this.isLoading) {
                    notify(
                      `Refuel / Tolls success, log id ${res.data.LogID}`,
                      "success",
                      2000
                    );
                    this.resetState();
                  }
                })
                .catch((err) => {
                  this.loadingUploadReceipt = false;

                  if (!this.isLoading) {
                    notify(
                      `Refuel / Tolls success, log id ${res.data.LogID}`,
                      "success",
                      2000
                    );
                    this.resetState();
                  }
                });
            }

            if (
              this.selectedReason.type == TYPES.REFUEL &&
              this.odoImageResult
            ) {
              this.loadingUploadOdo = true;
              this.loadingSave = false;

              this.$http({
                method: "post",
                url: api.POST_RESOURCE_LOG_IMAGE_URL,
                params: {
                  LogID: logId,
                  Type: "Value6",
                },
                data: Buffer.from(
                  this.odoImageResult.split(";base64,")[1],
                  "base64"
                ),
                headers: { "Content-Type": "image/jpeg" },
              })
                .then((resp) => {
                  this.loadingUploadOdo = false;

                  if (!this.isLoading) {
                    notify(
                      `Refuel / Tolls success, log id ${res.data.LogID}`,
                      "success",
                      2000
                    );
                    this.resetState();
                  }
                })
                .catch((err) => {
                  this.loadingUploadOdo = false;

                  if (!this.isLoading) {
                    notify(
                      `Refuel / Tolls success, log id ${res.data.LogID}`,
                      "success",
                      2000
                    );
                    this.resetState();
                  }
                });
            }

            if (
              this.selectedReason.type == TYPES.TOLL &&
              this.tollImageResult
            ) {
              this.loadingUploadToll = true;
              this.loadingSave = false;

              this.$http({
                method: "post",
                url: api.POST_RESOURCE_LOG_IMAGE_URL,
                params: {
                  LogID: logId,
                  Type: "Value6",
                },
                data: Buffer.from(
                  this.tollImageResult.split(";base64,")[1],
                  "base64"
                ),
                headers: { "Content-Type": "image/jpeg" },
              })
                .then((resp) => {
                  this.loadingUploadToll = false;

                  if (!this.isLoading) {
                    notify(
                      `Refuel / Tolls success, log id ${res.data.LogID}`,
                      "success",
                      2000
                    );
                    this.resetState();
                  }
                })
                .catch((err) => {
                  this.loadingUploadToll = false;

                  if (!this.isLoading) {
                    notify(
                      `Refuel / Tolls success, log id ${res.data.LogID}`,
                      "success",
                      2000
                    );
                    this.resetState();
                  }
                });
            }
          } else {
            this.loadingSave = false;
            notify("Refuel / Tolls success", "success", 2000);
            this.resetState();
          }
        })
        .catch((err) => {
          this.loadingSave = false;
          notify("error", "error", 2000);
        });
    },

    resetState() {
      this.odoValue = 0;
      this.litresValue = 0.0;
      this.costValue = 0.0;
      this.commentValue = "";

      const btnReceiptPhoto = this.$refs["btn-receipt-photo"];
      const btnOdoPhoto = this.$refs["btn-odo-photo"];
      const btnTollPhoto = this.$refs["btn-toll-photo"];

      if (btnReceiptPhoto) btnReceiptPhoto.resetState();
      if (btnOdoPhoto) btnOdoPhoto.resetState();
      if (btnTollPhoto) btnTollPhoto.resetState();
    },

    isCameraReady() {
      return (
        navigator !== undefined &&
        navigator !== null &&
        navigator.camera !== undefined &&
        navigator.camera !== null
      );
    },
    onFinishUploadSnap() {},
    onFinishUploadTollPhoto() {},
    onFinishUploadReceipt() {},
  },
  computed: {
    computeselectedReason() {
      return this.selectedReason ? this.selectedReason.type : undefined;
    },
    isValid() {
      return (
        (this.selectedReason != null &&
          this.selectedReason.type == TYPES.REFUEL &&
          this.isRefuelValid) ||
        (this.selectedReason != null &&
          this.selectedReason.type == TYPES.TOLL &&
          this.isTollValid)
      );
    },
    isRefuelValid() {
      return (
        this.isSelectedReourceAndAllocation &&
        this.odoValue > 0 &&
        this.litresValue > 0 &&
        this.costValue > 0
      );
    },
    isTollValid() {
      return this.isSelectedReourceAndAllocation && this.costValue > 0;
    },
    isLoading() {
      return (
        this.loadingSave ||
        this.loadingUploadReceipt ||
        this.loadingUploadOdo ||
        this.loadingUploadToll
      );
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxNumberBox,
    DxButton,
    DxLoadPanel,
    DxSelectBox,
    ImagePickerButton,
    DxTextArea,
    SelectedDataAccordion,
  },
};
</script>

<style lang="scss"></style>
