<template>
  <div class="dx-card chat-container">
    <div class="receiver-info-container">
      <img
        class="receiver-propic"
        width="32"
        height="32"
        src="@/assets/images/profile-user.svg"
      />
      <span><b>Planner X</b></span>
    </div>
    <hr />
    <DxList
      ref="chatsList"
      class="chats-list"
      :items="chats"
      :pullRefreshEnabled="false"
      selectionMode="none"
      :focusStateEnabled="false"
      :hoverStateEnabled="false"
      :activeStateEnabled="false"
      :bounceEnabled="true"
      :scrollByThumb="true"
      :useNativeScrolling="false"
    >
      <template #item="{ data: chatItem, index: index }">
        <div>
          <div
            v-if="chatItem.type === 'receive'"
            :class="
              index == 0
                ? 'chat-from-container chat-list-first'
                : 'chat-from-container'
            "
          >
            <div v-if="isHeaderVisible(index)" class="chat-header">
              <hr class="chat-header-indicator" />
              <span class="chat-header-text">
                {{ getChatDateText(chatItem.dateTime) }}
              </span>
              <hr class="chat-header-indicator" />
            </div>
            <div class="chat-from">
              <span class="tail-in" data-icon="tail-in">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 8 13"
                  width="8"
                  height="13"
                >
                  <path
                    opacity=".13"
                    fill="#0000000"
                    d="M1.533 3.568L8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M1.533 2.568L8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"
                  ></path>
                </svg>
              </span>
              <div class="chat-bubble">
                <span class="chat-message">{{ chatItem.message }} </span>
                <span class="chat-date-time"
                  >{{ getChatTimeText(chatItem.dateTime) }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="chatItem.type === 'send'"
            :class="
              index == 0
                ? 'chat-to-container chat-list-first'
                : 'chat-to-container'
            "
          >
            <div v-if="isHeaderVisible(index)" class="chat-header">
              <hr class="chat-header-indicator" />
              <span class="chat-header-text">
                {{ getChatDateText(chatItem.dateTime) }}
              </span>
              <hr class="chat-header-indicator" />
            </div>
            <div class="chat-to">
              <span class="tail-out" data-icon="tail-out">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 8 13"
                  width="8"
                  height="13"
                >
                  <path
                    opacity=".13"
                    d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"
                  ></path>
                </svg>
              </span>
              <div class="chat-bubble">
                <span class="chat-message">{{ chatItem.message }} </span>
                <span class="chat-date-time"
                  >{{ getChatTimeText(chatItem.dateTime) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </DxList>
    <div class="chat-template-container">
      <DxButton
        :class="
          idx == chatsTemplate.length - 1
            ? 'chat-template last-item'
            : 'chat-template'
        "
        :key="chatTemplate.ID"
        v-for="(chatTemplate, idx) in chatsTemplate"
        :text="chatTemplate.text"
        @click="onClickChatTemplate(chatTemplate)"
      />
      <!-- </div> -->
    </div>
    <div class="new-chat-container">
      <DxTextArea
        class="text-area-send"
        :placeholder='$t("chat.hint_chat_message")'
        height="64"
        v-model="message"
        valueChangeEvent="input"
        :onKeyDown="onKeyDownMsg"
      />
      <DxButton
        type="success"
        icon="email"
        width="48"
        height="48"
        class="btn-send"
        :onClick="onBtnSendMessageClick"
      />
    </div>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxTextArea from "devextreme-vue/text-area";
import DxList from "devextreme-vue/list";
import moment from "moment";

const dummyChats = [
  {
    ID: 1,
    type: "send",
    message: "Hi, could you please check Order XXX-YYY?",
    dateTime: moment()
      .subtract(3, "days")
      .subtract(3, "hours"),
  },
  {
    ID: 2,
    type: "receive",
    message: "Okay, one moment please...",
    dateTime: moment()
      .subtract(1, "days")
      .subtract(2, "hours"),
  },
  {
    ID: 3,
    type: "receive",
    message: "I can't find order with ID XXX-YYY",
    dateTime: moment()
      .subtract(1, "days")
      .subtract(1, "hours"),
  },
];

const chatsTemplateDummy = [
  {
    ID: 1,
    text: "Hi, please check this order ",
  },
  {
    ID: 2,
    text: "Sorry I can't take order right now ",
  },
  {
    ID: 3,
    text: "Hi, I have finished the task ",
  },
];

export default {
  data() {
    return {
      chats: [
        {
          ID: 1,
          type: "send",
          message: "Hi, could you please check Order XXX-YYY?",
          dateTime: moment()
            .subtract(3, "days")
            .subtract(3, "hours"),
        },
        {
          ID: 2,
          type: "receive",
          message: "Okay, one moment please...",
          dateTime: moment()
            .subtract(1, "days")
            .subtract(2, "hours"),
        },
        {
          ID: 3,
          type: "receive",
          message: "I can't find order with ID XXX-YYY",
          dateTime: moment()
            .subtract(1, "days")
            .subtract(1, "hours"),
        },
      ],
      chatsTemplate: chatsTemplateDummy,
      message: "",
      counter: 3,
    };
  },
  methods: {
    onKeyDownMsg(e) {
      if (e.event.key === "Enter" && !e.event.shiftKey) {
        e.event.preventDefault();

        const messageToSend = this.message;
        this.message = "";

        this.sendMessage(messageToSend);
      }
    },
    sendMessage(message) {
      if (message && message !== "") {
        this.counter++;

        this.chats.push({
          ID: this.counter,
          type: "send",
          message: message,
          dateTime: this.$moment(),
        });

        this.$nextTick(() => {
          this.$refs.chatsList.instance.scrollToItem(this.chats.length - 1);
        });
      }
    },
    onBtnSendMessageClick() {
      const messageToSend = this.message;
      this.message = "";

      this.sendMessage(messageToSend);
    },

    getChatTimeText(momentDateTime) {
      return momentDateTime.format("hh:mm A");
    },
    getChatDateText(momentDateTime) {
      let current = this.$moment();

      if (current.isSame(momentDateTime, "day")) return "Today";
      if (current.diff(momentDateTime, "day") == 1) return "Yesterday";

      return momentDateTime.format("DD MMM yyyy");
    },
    isHeaderVisible(currIdx) {
      if (currIdx == 0) return true;

      let currItem = this.chats[currIdx];
      let prevItem = this.chats[currIdx - 1];

      return !currItem.dateTime.isSame(prevItem.dateTime, "day");
    },
    onClickChatTemplate(template) {
      this.sendMessage(template.text);
    },
  },
  components: {
    DxButton,
    DxTextArea,
    DxList,
  },
};
</script>

<style lang="scss">
.chat-container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  .chats-list {
    flex: 1 1 0;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .chat-template-container {
    width: 100%;
    display: inline-block;
    overflow-x: auto;
    white-space: nowrap;

    padding: 1em 0;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .chat-template {
      font-size: 0.8em;
      border-radius: 12px;
      padding: 0;
      margin-left: 1em;

      .dx-button-content {
        padding: 0.4em 0.75em;
      }
    }
  }
}

.chat-list-first {
  margin-top: 1.5em;
}

hr {
  border: 0.1px solid #dee1e3;
  margin: 0;
  padding: 0;
}

.receiver-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;

  .receiver-propic {
    margin-right: 0.5em;
  }
}

.new-chat-container {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-bottom: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;

  .text-area-send {
    flex-grow: 1;
    margin-right: 1em;
  }

  .btn-send {
    border-radius: 50%;
  }
}

.chat-from-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .chat-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 1em;

    .chat-header-indicator {
      flex-grow: 1;
      border: 0.5px dashed #dad9d9;
    }

    .chat-header-text {
      font-size: 0.7em;
      color: #303030;
      font-weight: 500;

      margin-left: 1em;
      margin-right: 1em;
    }
  }

  .chat-from {
    // margin-bottom: 1.5em;
    position: relative;
    flex: none;
    margin-left: 1em;

    .chat-bubble {
      padding: 12px;
      z-index: 100;
      color: #303030;
      background-color: #f2f2f2;
      border: 0;
      margin: 0;
      padding: 6px 7px 8px 9px;
      border-top-right-radius: 7.5px;
      border-bottom-right-radius: 7.5px;
      border-bottom-left-radius: 7.5px;

      display: flex;
      flex-direction: row;
      align-items: flex-end;

      .chat-message {
        max-width: 80vh;
        overflow-wrap: break-word;
        white-space: pre-wrap;
      }

      .chat-date-time {
        font-size: 0.5em;
        margin-left: 2em;
      }
    }

    .tail-in {
      position: absolute;
      top: -2.5px;
      left: -8px;
      z-index: 100;
      display: block;
      width: 8px;
      height: 13px;
      color: #f2f2f2;
    }
  }
}

.chat-to-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .chat-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 1em;

    .chat-header-indicator {
      flex-grow: 1;
      border: 0.5px dashed #dad9d9;
    }

    .chat-header-text {
      font-size: 0.7em;
      color: #303030;
      font-weight: 500;

      margin-left: 1em;
      margin-right: 1em;
    }
  }

  .chat-to {
    // margin-bottom: 1.5em;
    position: relative;
    flex: none;
    margin-right: 1em;

    .chat-bubble {
      width: 100%;
      padding: 12px;
      z-index: 100;
      color: white;
      background-color: rgba(245, 124, 0, 1);
      border: 0;
      margin: 0;
      padding: 6px 7px 8px 9px;
      border-top-left-radius: 7.5px;
      border-bottom-right-radius: 7.5px;
      border-bottom-left-radius: 7.5px;

      display: flex;
      flex-direction: row;
      align-items: flex-end;

      .chat-message {
        max-width: 80vh;
        overflow-wrap: break-word;
        white-space: pre-wrap;
      }

      .chat-date-time {
        font-size: 0.5em;
        margin-left: 2em;
      }
    }

    .tail-out {
      position: absolute;
      top: -2.5px;
      right: -8px;
      z-index: 100;
      display: block;
      width: 8px;
      height: 13px;
      color: rgba(245, 124, 0, 1);
    }
  }
}

.dx-item.dx-list-item {
  border-top: none;
  cursor: text;
}

.chat-template-container::-webkit-scrollbar {
  display: none;
}

.chat-template.last-item {
  margin-right: 1em;
}
</style>
