<template>
  <div class="content-block dx-card responsive-paddings">
    <div
      class="activity-detail-item-container"
      v-for="(item, idx) in items"
      :key="item.id"
    >
      <div class="activity-detail-indicator">
        <div :class="getIndicatorHeadClassName(idx, item)"></div>
        <div
          @click="onClickItem(item)"
          :class="getIndicatorClassName(item)"
        ></div>
        <div :class="getIndicatorBottomClassName(idx, items, item)"></div>
      </div>
      <div class="activity-detail-info">
        <div>
          <span style="font-size: 1.1em; color: #81b214">{{
            translateLogType(item.LogType)
          }}</span>
        </div>
        <div>
          <span style="font-size: 0.75em; color: gray"
            >{{ formatPodActivityDate(item.UpdateDate) }}
          </span>
          <span style="font-size: 0.75em; font-weight: 700">
            - {{ item.CreatedUser ? item.CreatedUser : "-" }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "./../i18n";

export default {
  props: {
    items: {
      items: Array,
      default: [],
    },
  },

  data() {
    return {
      today: this.$moment().startOf("day"),
      yesterday: this.$moment().subtract(1, "days").startOf("day"),
    };
  },
  computed: {},
  methods: {
    translateLogType(logType) {
      if (logType == null || logType == "") return logType;
      const logTypeKey = logType.replace(" ", "_");

      return i18n.t(`dictionaries.${logTypeKey}`);
    },
    formatPodActivityDateOld(dateStr) {
      if (!dateStr) return "";

      try {
        let date = this.$moment(dateStr, "YYYY-MM-DDTHH:mm:ss.SSSSSS");
        // let date = this.today;

        if (this.today.isSame(date, "date")) {
          return date.format("[Today] hh:mm A");
        } else if (this.yesterday.isSame(date, "date")) {
          return date.format("[Yesterday] hh:mm A");
        }

        return date.format("YYYY-MM-DD hh:mm A");
      } catch (err) {
        return "-";
      }
    },
    formatPodActivityDate(dateStr) {
      if (!dateStr) return "";

      try {
        const date = this.$moment
          .utc(dateStr, "YYYY-MM-DDTHH:mm:ss.SSSSSS")
          .local();

        return date.format("YYYY-MM-DD HH:mm");
      } catch (err) {
        return "-";
      }
    },
    getIndicatorClassName(item) {
      if (item == null) return "activity-detail-indicator-normal";
      return item.isDone
        ? "activity-detail-indicator-active"
        : "activity-detail-indicator-normal";
    },
    getIndicatorHeadClassName(idx, item) {
      if (idx == 0) return "activity-detail-line-no-line";
      return item.isDone
        ? "activity-detail-line-active"
        : "activity-detail-line-normal";
    },
    getIndicatorBottomClassName(idx, items, item) {
      if (idx >= items.length - 1) return "activity-detail-line-no-line";

      const nextIdx = idx + 1;
      return items[nextIdx].isDone
        ? "activity-detail-line-active"
        : "activity-detail-line-normal";
    },
    onClickItem(item) {
      this.$emit("click", item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../themes/generated/variables.base.scss";

.activity-detail-indicator {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.activity-detail-indicator-normal {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2.5px solid #a1a1a1;
}

.activity-detail-indicator-active {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid #81b214;
}

.activity-detail-line-no-line {
  flex: 1;
  left: 50%;
  top: 0;
}

.activity-detail-line-normal {
  border-left: 2.5px solid #a1a1a1;
  flex: 1;
  left: 50%;
  top: 0;
}

.activity-detail-line-active {
  border-left: 2.5px solid #81b214;
  flex: 1;
  left: 50%;
  top: 0;
}

.activity-detail-item-container {
  display: flex;
  flex-direction: row;
}

.activity-detail-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  align-items: flex-start;
}

.activity-detail-indicator-normal,
.activity-detail-indicator-active {
  &:hover,
  &:active {
    cursor: pointer;
    border: 4px solid $base-accent;
  }
}
</style>
