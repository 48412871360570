import CryptoJS from "crypto-js";
import auth from "../auth";
import moment from "moment";
import api from "../commons/api";
import { pscopeAxios } from "../main";
import { planStatus } from "../commons/constants";
import localRepository from "./local-repository";

// disclosures
const DONT_SHOW_DISCLOSURE_AGAIN = "DONT_SHOW_DISCLOSURE_AGAIN";
const DISCLOSURE_STATUS = "DISCLOSURE_STATUS";

// tenants related
const SELECTED_TENANT_ID_KEY = "SELECTED_TENANT_ID";
const SELECTED_TENANT_NAME_KEY = "SELECTED_TENANT_NAME";
const SELECTED_SITE_KEY = "SELECTED_SITE";

// allocations related
const SELECTED_RESOURCE_ID_KEY = "SELECTED_RESOURCE_ID";
const SELECTED_ALLOCATION_ID_KEY = "SELECTED_ALLOCATION_ID";

// activities related
/**
 * @deprecated
 */
//
const SELECTED_TASK_ID_KEY = "SELECTED_TASK_ID";
/**
 * @deprecated
 */
//
const SELECTED_APPOINTMENT_KEY = "SELECTED_APPOINTMENT";
const SELECTED_ACTIVITY_PLAN_KEY = "SELECTED_ACTIVITY_PLAN";
const LOCAL_ACTIVITY_PLAN_KEY = "LOCAL_ACTIVITY_PLAN";

const COMPLETED_TASKS_KEY = "COMPLETED_TASKS_KEY";
const SKIPPED_TASKS_KEY = "SKIPPED_TASKS_KEY";

// temp
const SELECTED_TEMP_TENANT_ID_KEY = "SELECTED_TEMP_TENANT_ID";
const SELECTED_TEMP_USERNAME_KEY = "SELECTED_TEMP_USERNAME";
const SELECTED_TEMP_PASSWORD_KEY = "SELECTED_TEMP_PASSWORD";

export default {
  booleanConverter(value) {
    if (value === "true") {
      return true;
    } else if (value === "false") {
      return false;
    } else {
      return null;
    }
  },

  hasSelectTenant() {
    return (
      localStorage.getItem(SELECTED_TENANT_ID_KEY) !== null &&
      localStorage.getItem(SELECTED_TENANT_NAME_KEY)
    );
  },

  getSelectedTenant() {
    if (!this.hasSelectTenant()) return null;

    return {
      TenantID: localStorage.getItem(SELECTED_TENANT_ID_KEY),
      Name: localStorage.getItem(SELECTED_TENANT_NAME_KEY),
    };
  },

  saveTempUserData(userName, password, tenantID) {
    let key = auth.key;

    localStorage.setItem(SELECTED_TEMP_USERNAME_KEY, userName);
    localStorage.setItem(
      SELECTED_TEMP_PASSWORD_KEY,
      CryptoJS.AES.encrypt(password, key)
    );

    localStorage.setItem(SELECTED_TEMP_TENANT_ID_KEY, tenantID);
  },

  getTempUserData() {
    console.log(
      "raw password",
      localStorage.getItem(SELECTED_TEMP_PASSWORD_KEY)
    );

    let key = auth.key;
    let rawPassword = localStorage.getItem(SELECTED_TEMP_PASSWORD_KEY);

    return {
      userName: localStorage.getItem(SELECTED_TEMP_USERNAME_KEY),
      password:
        rawPassword !== null
          ? CryptoJS.AES.decrypt(rawPassword, key).toString(CryptoJS.enc.Utf8)
          : null,
      tenantID: localStorage.getItem(SELECTED_TEMP_TENANT_ID_KEY),
    };
  },

  clearTempUserData() {
    localStorage.removeItem(SELECTED_TEMP_USERNAME_KEY);
    localStorage.removeItem(SELECTED_TEMP_PASSWORD_KEY);
    localStorage.removeItem(SELECTED_TEMP_TENANT_ID_KEY);
  },

  saveSelectedTenant(tenant) {
    localStorage.setItem(SELECTED_TENANT_ID_KEY, tenant.TenantID);
    localStorage.setItem(SELECTED_TENANT_NAME_KEY, tenant.Name);
  },

  clearSelectedTenant() {
    localStorage.removeItem(SELECTED_TENANT_ID_KEY);
    localStorage.removeItem(SELECTED_TENANT_NAME_KEY);
  },

  async hasSelectedResourceAndAllocation() {
    // do api request here
    const selectedResourceId = localStorage.getItem(SELECTED_RESOURCE_ID_KEY);
    const selectedAllocationId = localStorage.getItem(
      SELECTED_ALLOCATION_ID_KEY
    );

    if (selectedResourceId == null || selectedAllocationId == null) {
      this.clearSelectedResourceAndAllocation(null, null);
      return false;
    }

    // check selectedResourceId validity
    try {
      const res = await pscopeAxios.post(api.CHECK_ALLOCATION_VALID_URL, {
        AllocationID: selectedAllocationId,
      });
      return !(res == false || res == "False");
    } catch (e) {
      return true;
    }
  },

  saveSelectedResourceAndAllocation(resourceId, allocationId) {
    const oldResourceId = this.getSelectedResourceId();
    const oldAllocationId = this.getSelectedAllocationId();

    if (oldResourceId !== resourceId || oldAllocationId !== allocationId) {
      this.clearSelectedTaskId();
      this.clearSelectedAppointment();
      this.clearSelectedActivityPlan();
      this.clearLocalActivityPlan();
      this.clearCompletedTasks();
      this.clearSkippedTasks();
    }

    localStorage.setItem(SELECTED_RESOURCE_ID_KEY, resourceId);
    localStorage.setItem(SELECTED_ALLOCATION_ID_KEY, allocationId);
  },

  clearSelectedResourceAndAllocation() {
    localStorage.removeItem(SELECTED_RESOURCE_ID_KEY);
    localStorage.removeItem(SELECTED_ALLOCATION_ID_KEY);
    this.clearSelectedTaskId();
    this.clearSelectedAppointment();
    this.clearSelectedActivityPlan();
    this.clearLocalActivityPlan();
    this.clearCompletedTasks();
    this.clearSkippedTasks();
  },

  getSelectedResourceId() {
    return localStorage.getItem(SELECTED_RESOURCE_ID_KEY);
  },

  getSelectedAllocationId() {
    return localStorage.getItem(SELECTED_ALLOCATION_ID_KEY);
  },

  saveSelectedSite(site) {
    try {
      localStorage.setItem(SELECTED_SITE_KEY, JSON.stringify(site));
    } catch (e) {
      // empty
    }
  },

  getSelectedSite() {
    try {
      return JSON.parse(localStorage.getItem(SELECTED_SITE_KEY));
    } catch (e) {
      return null;
    }
  },

  clearSelectedSite() {
    localStorage.removeItem(SELECTED_SITE_KEY);
  },

  hasSelectedSite() {
    return localStorage.getItem(SELECTED_SITE_KEY) !== null;
  },

  getCurrentDateStr() {
    return moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
  },

  saveDisclosure(accepted, dontShowAgain) {
    if (accepted) {
      localStorage.setItem(DISCLOSURE_STATUS, "true");
    } else {
      localStorage.setItem(DISCLOSURE_STATUS, "false");
    }

    if (dontShowAgain) {
      localStorage.setItem(DONT_SHOW_DISCLOSURE_AGAIN, "true");
    } else {
      localStorage.setItem(DONT_SHOW_DISCLOSURE_AGAIN, "false");
    }
  },

  // disclosure related
  shouldShowDisclosure() {
    return !(localStorage.getItem(DONT_SHOW_DISCLOSURE_AGAIN) === "true");
  },

  isDisclosureAccepted() {
    return localStorage.getItem(DISCLOSURE_STATUS) === "true";
  },

  isDisclosurePermanentlyAccepted() {
    return (
      localStorage.getItem(DISCLOSURE_STATUS) === "true" &&
      localStorage.getItem(DONT_SHOW_DISCLOSURE_AGAIN) === "true"
    );
  },

  isDisclosurePermanentlyRejected() {
    return (
      localStorage.getItem(DISCLOSURE_STATUS) === "false" &&
      localStorage.getItem(DONT_SHOW_DISCLOSURE_AGAIN) === "true"
    );
  },

  clearDisclosuresStatus() {
    localStorage.removeItem(DISCLOSURE_STATUS);
    localStorage.removeItem(DONT_SHOW_DISCLOSURE_AGAIN);
  },

  /**
   * @deprecated
   */
  getSelectedAppointment() {
    const selectedAppointment = localStorage.getItem(SELECTED_APPOINTMENT_KEY);

    try {
      return JSON.parse(selectedAppointment);
    } catch (error) {
      return null;
    }
  },

  /**
   * @deprecated
   */
  saveSelectedAppointment(selectedAppointment) {
    const oldAppointment = this.getSelectedAppointment();

    localStorage.setItem(
      SELECTED_APPOINTMENT_KEY,
      JSON.stringify(selectedAppointment)
    );

    if (oldAppointment) {
      if (oldAppointment.Seq != selectedAppointment.Seq) {
        this.clearSelectedTaskId();
      }
    }
  },

  /**
   * @deprecated
   */
  clearSelectedAppointment() {
    localStorage.removeItem(SELECTED_APPOINTMENT_KEY);
  },

  /**
   * @deprecated
   */
  getSelectedTaskId() {
    return localStorage.getItem(SELECTED_TASK_ID_KEY);
  },

  /**
   * @deprecated
   */
  saveSelectedTaskId(taskId) {
    localStorage.setItem(SELECTED_TASK_ID_KEY, taskId);
  },

  /**
   * @deprecated
   */
  clearSelectedTaskId() {
    localStorage.removeItem(SELECTED_TASK_ID_KEY);
  },

  getSelectedActivityPlan() {
    const selectedActivityPlan = localStorage.getItem(
      SELECTED_ACTIVITY_PLAN_KEY
    );

    try {
      return JSON.parse(selectedActivityPlan);
    } catch (error) {
      return null;
    }
  },

  saveSelectedActivityPlan(selectedActivityPlan) {
    localStorage.setItem(
      SELECTED_ACTIVITY_PLAN_KEY,
      JSON.stringify(selectedActivityPlan)
    );
  },

  clearSelectedActivityPlan() {
    localStorage.removeItem(SELECTED_ACTIVITY_PLAN_KEY);
  },

  getLocalActivityPlan() {
    const localActivityPlan = localStorage.getItem(LOCAL_ACTIVITY_PLAN_KEY);

    try {
      return JSON.parse(localActivityPlan);
    } catch (error) {
      return null;
    }
  },

  saveLocalActivityPlan(localActivityPlan) {
    localStorage.setItem(
      LOCAL_ACTIVITY_PLAN_KEY,
      JSON.stringify(localActivityPlan)
    );
  },

  clearLocalActivityPlan() {
    localStorage.removeItem(LOCAL_ACTIVITY_PLAN_KEY);
  },

  completeActivityPlan() {
    const selectedActivityPlan = this.getSelectedActivityPlan();

    if (selectedActivityPlan == null) return;
    const completedActivityPlan = {
      ...selectedActivityPlan,
      PlanStatus: planStatus.COMPLETED,
    };
    this.saveSelectedActivityPlan(completedActivityPlan);

    const localActivityPlan = this.getLocalActivityPlan();
    if (
      localActivityPlan != null &&
      selectedActivityPlan.AllocationID == localActivityPlan.AllocationID &&
      selectedActivityPlan.Seq == localActivityPlan.Seq
    ) {
      const completedLocalActivityPlan = {
        ...localActivityPlan,
        PlanStatus: planStatus.COMPLETED,
      };

      this.saveLocalActivityPlan(completedLocalActivityPlan);
    }
  },

  getCompletedTasks() {
    const completedTasksJson = localStorage.getItem(COMPLETED_TASKS_KEY);

    try {
      const completedTasks = JSON.parse(completedTasksJson);
      return completedTasks.sort((a, b) => a - b);
    } catch (error) {
      return [];
    }
  },

  saveCompletedTask(taskSeq) {
    const completedTasks = this.getCompletedTasks();

    if (!completedTasks.includes(taskSeq)) {
      completedTasks.push(taskSeq);
      localStorage.setItem(COMPLETED_TASKS_KEY, JSON.stringify(completedTasks));
    }
  },

  clearCompletedTasks() {
    localStorage.removeItem(COMPLETED_TASKS_KEY);
  },

  isTaskCompleted(taskSeq) {
    const completedTasks = this.getCompletedTasks();
    return completedTasks.includes(taskSeq);
  },

  getSkippedTasks() {
    const skippedTasksJson = localStorage.getItem(SKIPPED_TASKS_KEY);

    try {
      const skippedTasks = JSON.parse(skippedTasksJson);
      return skippedTasks.sort((a, b) => a - b);
    } catch (error) {
      return [];
    }
  },

  saveSkippedTask(taskSeq) {
    const skippedTasks = this.getSkippedTasks();

    if (!skippedTasks.includes(taskSeq)) {
      skippedTasks.push(taskSeq);
      localStorage.setItem(SKIPPED_TASKS_KEY, JSON.stringify(skippedTasks));
    }
  },

  clearSkippedTasks() {
    localStorage.removeItem(SKIPPED_TASKS_KEY);
  },

  isTaskSkipped(taskSeq) {
    const skippedTasks = this.getSkippedTasks();
    return skippedTasks.includes(taskSeq);
  },

  getKeyValueFromObject(obj, key) {
    if (obj == null) return null;
    if (key in obj) return obj["key"];
    return null;
  },

  deleteAllData() {
    localStorage.clear();
    return localRepository.deleteAllData();
  },
};
