<template>
  <div id="content-container">
    <h2 class="content-block">{{ $t("create_task.title") }}</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <DxForm
          id="form"
          :form-data="taskData"
          label-location="top"
          col-count="auto"
          :min-col-width="240"
        >
          <DxTabbedItem
            :tabPanelOptions="{
              swipeEnabled: false,
              loop: false,
            }"
          >
            <DxTab :title="$t('create_task.main.title')" :col-count="1">
              <DxGroupItem>
                <DxSimpleItem
                  data-field="taskId"
                  :label="{
                    text: $t('create_task.main.lbl_task_id'),
                  }"
                  :is-required="true"
                  editorType="dxTextBox"
                  :editorOptions="{
                    maxLength: 50,
                    placeholder: $t('create_task.main.hint_task_id'),
                    value: this.taskData.taskId,
                  }"
                />
                <DxSimpleItem
                  data-field="from"
                  :label="{
                    text: $t('create_task.main.lbl_from'),
                  }"
                  :is-required="true"
                  editorType="dxSelectBox"
                  :editorOptions="{
                    placeholder: $t('create_task.main.hint_from'),
                    displayExpr: 'Description',
                    valueExpr: 'SiteID',
                    searchExpr: ['SiteID', 'Description'],
                    searchEnabled: true,
                    dataSource: siteDataSource,
                  }"
                />
                <DxSimpleItem
                  data-field="to"
                  :label="{
                    text: $t('create_task.main.lbl_to'),
                  }"
                  :is-required="true"
                  editorType="dxSelectBox"
                  :editorOptions="{
                    placeholder: $t('create_task.main.hint_to'),
                    displayExpr: 'Description',
                    valueExpr: 'SiteID',
                    searchExpr: ['SiteID', 'Description'],
                    searchEnabled: true,
                    dataSource: siteDataSource,
                  }"
                />
                <DxSimpleItem
                  data-field="type"
                  :label="{
                    text: $t('create_task.main.lbl_product_type'),
                  }"
                  editorType="dxSelectBox"
                  :editorOptions="{
                    placeholder: $t('create_task.main.hint_product_type'),
                    displayExpr: 'TypeID',
                    valueExpr: 'TypeID',
                    searchExpr: ['TypeID', 'Description'],
                    searchEnabled: true,
                    dataSource: productDataSource,
                  }"
                />
              </DxGroupItem>
              <DxEmptyItem />
              <DxGroupItem template="dimensionTemplate" />
              <DxGroupItem :visible="dimensionGroupVisible">
                <DxSimpleItem
                  data-field="quantity"
                  :label="{
                    text: $t('create_task.main.lbl_quantity'),
                  }"
                  editorType="dxNumberBox"
                  :editorOptions="{
                    showSpinButtons: true,
                    min: 0,
                    max: 100000,
                    step: 0.5,
                  }"
                />
                <DxSimpleItem
                  data-field="metricTon"
                  :label="{
                    text: $t('create_task.main.lbl_metric_ton'),
                  }"
                  editorType="dxNumberBox"
                  :editorOptions="{
                    showSpinButtons: true,
                    min: 0,
                    max: 100000,
                    step: 0.5,
                  }"
                />
                <DxSimpleItem
                  data-field="metricCube"
                  :label="{
                    text: $t('create_task.main.lbl_metric_cube'),
                  }"
                  editorType="dxNumberBox"
                  :editorOptions="{
                    showSpinButtons: true,
                    min: 0,
                    max: 100000,
                    step: 0.5,
                  }"
                />
                <DxSimpleItem
                  data-field="metricOthers"
                  :label="{
                    text: $t('create_task.main.lbl_metric_others'),
                  }"
                  editorType="dxNumberBox"
                  :editorOptions="{
                    showSpinButtons: true,
                    min: 0,
                    max: 100000,
                    step: 0.5,
                  }"
                />
                <DxSimpleItem
                  data-field="comment"
                  :label="{
                    text: $t('create_task.main.lbl_comment'),
                  }"
                  editorType="dxTextArea"
                  :editorOptions="{
                    height: '60',
                    maxLength: 50,
                  }"
                />
              </DxGroupItem>
              <DxEmptyItem />
              <DxButtonItem
                :buttonOptions="{
                  icon: 'todo',
                  type: 'success',
                  width: '48',
                  height: '48',
                  elementAttr: {
                    id: 'create-task-button',
                  },
                  onClick: createTask,
                  disabled: !this.isValid,
                }"
              />
            </DxTab>
            <DxTab :title="$t('create_task.timing.title')">
              <DxGroupItem>
                <DxSimpleItem
                  data-field="earliestStart"
                  :label="{
                    text: $t('create_task.timing.lbl_earliest_start'),
                  }"
                  editorType="dxDateBox"
                  :editorOptions="{
                    type: 'datetime',
                    pickertype: 'rollers',
                    dateSerializationFormat: 'yyyy-MM-dd HH:mm:ss',
                  }"
                />
                <DxSimpleItem
                  data-field="earliestFinish"
                  :label="{
                    text: $t('create_task.timing.lbl_earliest_finish'),
                  }"
                  editorType="dxDateBox"
                  :editorOptions="{
                    type: 'datetime',
                    pickertype: 'rollers',
                    dateSerializationFormat: 'yyyy-MM-dd HH:mm:ss',
                  }"
                />
              </DxGroupItem>
            </DxTab>
          </DxTabbedItem>
          <template #dimensionTemplate="{}">
            <div style="display: flex">
              <DxButton
                :icon="!dimensionGroupVisible ? 'chevronright' : 'chevrondown'"
                :onClick="toggleDimensionGroupVisibility"
              />
              <span
                class="dx-form-group-caption"
                style="
                  display: inline-flex;
                  align-items: center;
                  margin-left: 0.5rem;
                "
                >{{ $t("create_task.main.lbl_dimention") }}</span
              >
            </div>
          </template>
        </DxForm>
      </div>
    </div>
    <DxLoadPanel
      :visible="isLoading"
      :message="$t('create-task.Loading')"
      :position="{ of: '#content-container' }"
      :close-on-outside-click="false"
    />
  </div>
</template>

<script>
import api from "../commons/api";
import auth from "../../src/auth";
import notify from "devextreme/ui/notify";
import DxLoadPanel from "devextreme-vue/load-panel";
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxTabbedItem,
  DxTab,
  DxEmptyItem,
  DxButtonItem,
} from "devextreme-vue/form";
import DataSource from "devextreme/data/data_source";
import { createStore } from "devextreme-aspnet-data-nojquery";
import DxButton from "devextreme-vue/button";

export default {
  data() {
    const emptyTaskData = {
      taskId: "afwefew",
      from: null,
      to: null,
      type: null,
      comment: null,
      quantity: 0.0,
      metricTon: 0.0,
      metricCube: 0.0,
      metricOthers: 0.0,
      earliestStart: null,
      earliestFinish: null,
    };

    const dataStore = createStore({
      key: "SiteID",
      loadUrl: api.API_URL + api.GET_SITES_URL,
      onBeforeSend: (_, ajaxSettings) => {
        const token = auth.getUserToken();

        ajaxSettings.headers = {
          Authorization: `Bearer ${token}`,
        };
      },
    });

    const siteDataSource = new DataSource({
      store: dataStore,
      pageSize: 20,
      paginate: true,
      requireTotalCount: false,
      searchExpr: ["SiteID", "Description"],
    });

    const productStore = createStore({
      key: "TypeID",
      loadUrl: api.API_URL + api.GET_PRODUCT_TYPES_URL,
      onBeforeSend: (_, ajaxSettings) => {
        const token = auth.getUserToken();

        ajaxSettings.headers = {
          Authorization: `Bearer ${token}`,
        };
      },
    });

    const productDataSource = new DataSource({
      store: productStore,
      pageSize: 20,
      paginate: true,
      requireTotalCount: false,
      searchExpr: ["TypeID", "Description"],
    });

    return {
      isLoading: false,
      allocationId: null,
      siteDataSource,
      productDataSource,
      defaultData: { ...emptyTaskData },
      taskData: { ...emptyTaskData },
      dimensionGroupVisible: false,
    };
  },
  mounted() {
    // no allocation required for this feature
    this.allocationId = "0";
    this.taskData.taskId = `Task-${this.$moment().format("DDMMYYYYHHmmss")}`;
  },
  computed: {
    colCountByScreen() {
      return {
        sm: 2,
        md: 4,
      };
    },
    isValid() {
      return this.isFormValid && this.allocationId != null;
    },
    isFormValid() {
      return (
        this.taskData.taskId != null &&
        this.taskData.from != null &&
        this.taskData.to != null
      );
    },
  },
  methods: {
    toggleDimensionGroupVisibility() {
      this.dimensionGroupVisible = !this.dimensionGroupVisible;
    },
    screenByWidth(width) {
      return width < 720 ? "sm" : "md";
    },
    createTask() {
      const taskData = this.taskData;

      const param = {
        AllocationID: this.allocationId,
        TaskID: taskData.taskId,
        OrigSiteID: taskData.from,
        DestSiteID: taskData.to,
        ProductType: taskData.type,
        DemandQty: taskData.quantity,
        DemandMT: taskData.metricTon,
        DemandM3: taskData.metricCube,
        DemandUD: taskData.metricOthers,
        EarliestPickUp: taskData.earliestStart,
        EarliestDropOff: taskData.earliestFinish,
        Extra: taskData.comment,
        Status: "0",
        Active: false,
      };

      this.isLoading = true;
      this.$http
        .post(api.POST_TASK_URL, [param])
        .then((_) => {
          this.isLoading = false;
          notify("Success create Task", "success", 2000);
          this.resetData();
        })
        .catch((_) => {
          this.isLoading = false;
          notify("Failed to create Task", "error", 2000);
        });
    },
    resetData() {
      this.taskData = { ...this.defaultData };

      this.allocationId = "0";
      this.taskData.taskId = `Task-${this.$moment().format("DDMMYYYYHHmmss")}`;
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxTabbedItem,
    DxTab,
    DxEmptyItem,
    DxButtonItem,
    DxButton,
    DxLoadPanel,
  },
};
</script>

<style lang="scss">
#create-task-button {
  border-radius: 50%;
  .dx-icon {
    font-size: 20px;
    width: auto;
    height: auto;
  }
}
</style>
