<template>
  <div>
    <h2 class="content-block">Camera</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <dx-button
          type="default"
          :text="$t('common.btn_take_picture')"
          width="100%"
          @click="takePicture"
        />
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "./../i18n";
import DxButton from "devextreme-vue/button";

export default {
  data() {
    return {};
  },

  mounted() {
    document.addEventListener("deviceReady", this.onDeviceReady, false);
  },
  methods: {
    takePicture() {
      if (this.isCameraReady()) {
        navigator.camera.getPicture(this.onSuccess, this.onFail, {
          quality: 50,
          sourceType: navigator.camera.PictureSourceType.CAMERA,
          destinationType: navigator.camera.DestinationType.FILE_URI,
        });
      } else {
        alert("Camera is not ready or device is not support camera");
      }
    },

    onSuccess(imageURI) {
      alert("Image URI: " + imageURI);
    },

    onFail(message) {
      alert("Failed because: " + message);
    },

    isCameraReady() {
      return (
        navigator !== undefined &&
        navigator !== null &&
        navigator.camera !== undefined &&
        navigator.camera !== null
      );
    },
  },
  components: {
    DxButton,
  },
};
</script>

<style lang="scss"></style>
