import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import Vue from "vue";

import App from "./App";
import { createPinia, PiniaVuePlugin } from "pinia";
import router from "./router";
import appInfo from "./app-info";
import auth from "../src/auth";
import api from "../src/commons/api";
import axios from "axios";
import moment from "moment";
import util from "./commons/utility";
import service from "./commons/service";
import localRepository from "./commons/local-repository";
import notify from "devextreme/ui/notify";
import { confirm } from "devextreme/ui/dialog";
import i18n from "./i18n";
import { useGeneralStore } from "./stores/generalStore";

document.addEventListener(
  "deviceready",
  () => {
    exponentialBackoff(handleDeviceReady, 5, 1000);
  },
  false
);

function exponentialBackoff(fn, maxRetries, initialDelay) {
  let retryCount = 0;
  let delay = initialDelay;

  const attempt = async () => {
    try {
      return fn();
    } catch (error) {
      retryCount++;
      if (retryCount >= maxRetries) {
        return;
      }
      delay *= 2; // Double the delay for each retry
      return attempt();
    }
  };

  return attempt();
}

function handleDeviceReady() {
  try {
    const store = useGeneralStore();
    store.setDeviceReady();
    localRepository.initialize();
  } catch (_) {
    // TODO
  }
}

// setup axios
const axiosConfig = {
  baseURL: api.API_URL,
  timeout: 30000,
};

const pscopeAxios = axios.create(axiosConfig);

pscopeAxios.interceptors.request.use(
  function (config) {
    const token = auth.getUserToken();

    if (auth.authenticated() && config.headers && token !== null) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

pscopeAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log("error interceptor", error);
    if (
      typeof error === "object" &&
      error !== null &&
      401 === error.response.status
    ) {
      auth.logOut();

      router
        .replace({
          path: "/login-form",
          query: {
            redirect: "/resource-selection",
            errorMessage:
              "Your session has been expired or token not valid, please relogin...",
          },
        })
        .catch(() => {});
    } else {
      return Promise.reject(error);
    }
  }
);

// setup global scope variable
Vue.config.productionTip = false;
Vue.prototype.$appInfo = appInfo;
Vue.prototype.$http = pscopeAxios;
Vue.prototype.$auth = auth;
Vue.prototype.$moment = moment;
// Vue.prototype.$util = utility;

// start background location plugin, make sure 'deviceready' has been invoked
Vue.prototype.$startPscopeTracker = () => {
  const accessToken = auth.getUserToken();
  const tenantID = util.getSelectedTenant().TenantID;
  const resourceID = util.getSelectedResourceId();
  const allocationID = util.getSelectedAllocationId();

  let bgGeo = window.BackgroundGeolocation;

  bgGeo.onHeartbeat((event) => {
    console.log("BackgroundGeolocation - [onHeartbeat] ", event);
  });

  bgGeo.onLocation(
    function (location) {
      console.log(
        "BackgroundGeolocation - [location] - lat : " +
          location.coords.latitude +
          " - lon : " +
          location.coords.longitude
      );
    },
    function (error) {
      console.log("BackgroundGeolocation - [location] - errror", error);
    }
  );

  bgGeo.onHttp((response) => {
    let status = response.status;
    let responseText = response.responseText;
    console.log("BackgroundGeolocation - [onHttp] - code " + status);
    console.log("BackgroundGeolocation - [onHttp] - response" + responseText);
  });

  bgGeo.ready(
    {
      reset: true,
      debug: true,
      logLevel: bgGeo.LOG_LEVEL_VERBOSE,
      desiredAccuracy: bgGeo.DESIRED_ACCURACY_HIGH,
      distanceFilter: 0,
      locationUpdateInterval: 60000,
      fastestLocationUpdateInterval: 30000,
      // allowIdenticalLocations: true,
      allowIdenticalLocations: false,
      url: api.API_URL + api.POST_GPS_LOG_URL,
      autoSync: true,
      batchSync: true,
      autoSyncThreshold: 10,
      maxRecordsToPersist: 1000,
      maxDaysToPersist: 100,
      foregroundService: true,
      stopOnTerminate: false,
      enabledHeadless: true,
      startOnBoot: false,
      params: {
        TenantID: tenantID,
        ResourceID: resourceID,
        AllocationID: allocationID,
        Region: "",
        SiteID: "9999",
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      backgroundPermissionRationale: {
        title:
          "Allow Pscope Mobile to access this device's location even when the app is closed or not in use.",
        message:
          "This app collects location data to allow GPS tracking and geofencing even when the app is closed or not in use. This data is also used to further optimize your delivery and notify customer on ETA",
        positiveAction: "Okay",
        negativeAction: "Cancel",
      },
    },
    (state) => {
      // <-- Current state provided to #configure callback
      // 3.  Start tracking
      console.log("BackgroundGeolocation is configured and ready to use");
      console.log("BackgroundGeolocation - is enabled = " + state.enabled);
      if (!state.enabled) {
        bgGeo
          .start()
          .then(function () {
            bgGeo.changePace(true);
            console.log("BackgroundGeolocation tracking started");
          })
          .catch(function (error) {
            console.log("BackgroundGeolocation tracking error " + error);
          });
      } else {
        bgGeo.changePace(true);
      }
    }
  );
};

Vue.prototype.$stopPscopeTracker = () => {
  const bgGeo = window.BackgroundGeolocation;

  if (bgGeo) {
    bgGeo.stop();
  }
};

Vue.prototype.$syncTracker = function (onSuccess, onFailed) {
  const bgGeo = window.BackgroundGeolocation;

  console.log("syncTracker", bgGeo);
  try {
    if (bgGeo) {
      bgGeo
        .sync((records) => {
          console.log("[sync] success: ", JSON.stringify(records));

          onSuccess(records);
        })
        .catch((error) => {
          console.log("[sync] FAILURE: ", error);

          onFailed(error);
        });
    }
  } catch (e) {
    onFailed(e);
  }
};

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

const app = new Vue({
  router,
  i18n,
  render: (h) => h(App),
  data: {
    deviceReady: false,
    isLoading: false,
    deviceReadyCallback: new Map(), // list of callbacks
  },
  beforeCreate() {},
  created() {
    // console.log("app created");
    // console.log("window.sqlitePlugin", window);
    document.addEventListener("deviceready", this.initialize, false);
  },
  beforeDestroy() {
    if (this.$root.deviceReady) {
      const plugins = window.plugins;
      if (plugins == null) return;

      const intentShim = plugins["intentShim"];
      if (intentShim == null) return;

      intentShim.unregisterBroadcastReceiver();
    }
  },
  methods: {
    initialize() {
      console.log("device is ready to be use");
      this.deviceReady = true;
      this.registerQRCodeReceiver();
    },
    showLoading(isLoading) {
      this.isLoading = isLoading;
    },
    showMessage(message, duration) {
      notify(message, duration);
    },
    showSuccess(message, duration) {
      notify(message, "success", duration);
    },
    showWarning(message, duration) {
      notify(message, "warning", duration);
    },
    showError(message, duration) {
      notify(message, "error", duration);
    },
    showConfirmationDialog(title, message) {
      return confirm(`<i>${message}</i>`, title);
    },
    showCustomConfirmationDialog(htmlTitle, htmlMessage) {
      return confirm(htmlMessage, htmlTitle);
    },
    registerQRCodeReceiver() {
      let callbacks = this.deviceReadyCallback;

      const plugins = window.plugins;
      if (plugins == null) return;

      const intentShim = plugins["intentShim"];
      if (intentShim == null) return;

      intentShim.registerBroadcastReceiver(
        {
          filterActions: ["com.se4500.onDecodeComplete"],
        },

        function (intent) {
          console.log("intent", intent);
          console.log("extras", JSON.stringify(intent.extras));
          console.log("callbacks", callbacks);

          let barcode = intent.extras["se4500"];

          // if(barcode && callbacks.length > 0) {
          // if(barcode && callbacks) {
          if (barcode && callbacks.size > 0) {
            console.log("barcode", barcode);

            callbacks.forEach((callback, key) => {
              console.log("calling callback", key);
              callback(barcode);
            });

            // for (let [key, callback] of callbacks) {
            //   callback(barcode);
            // }

            // Object.entries(callbacks).map(callback => {
            //   if(callback) callback(barcode);
            // })
          }
        }
      );
    },
  },
  pinia,
});

service.initialize();

// document.addEventListener('deviceready', app.init, false);
app.$mount("#app");

export { pscopeAxios };
