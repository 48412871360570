<template>
<div></div>
  <!-- <div
    id="current-location-container"
    class="content-block dx-card responsive-paddings">
     <div class="current-position-container">
      <span class="current-position-info">
        Latitude : <b>{{ currentCoordinate.lat ? currentCoordinate.lat : '-' }} </b>
      </span>
      <span class="current-position-info">
        Longitude : <b>{{ currentCoordinate.lon ? currentCoordinate.lon : '-' }} </b>
      </span>
    </div> 
  </div> -->
</template>

<script>
export default {
  data() {
    return {
      currentCoordinate: {
        lat: null,
        lon: null,
        // lat: "-6.1753871",
        // lon: "106.8249641",
      },
    };
  },

  methods: {
  },
  computed: {
    isLocationValid() {
      if(this.currentCoordinate == null) return false;

      return this.currentCoordinate.lat != null &&
        this.currentCoordinate.lon != null
    },
  },
  components: {
  },
};
</script>

<style lang="scss" scoped>
.dx-template-wrapper.dx-item-content.dx-accordion-item-title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.current-position-container {
  display: flex;
  flex-direction: row;
}

.current-position-info {
  flex-grow: 1;
  font-size: 0.8em;
  align-self: center;
}
</style>
