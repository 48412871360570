// const API_URL = 'https://pscope-api.azurewebsites.net/api';
const API_URL = "https://pscope-api-uat.azurewebsites.net/api";

const LOGIN_URL = "/oauth/token";
const GET_TENANT_LIST_URL = "/Account/GetTenant";
const GET_RESOURCE_URL = "/Resource/GetResourceByUser";
const POST_RESOURCE_LOG_URL = "/Resource/PostLog";
const POST_RESOURCE_LOG_IMAGE_URL = "/Resource/PostResourceLogImage";
const POST_RESOURCE_LOGS_IMAGE_URL = "/Resource/PostResourceLogsImage";
const GET_ALLOCATION_PLAN_URL = "/Allocation/GetAllocationPlan";
const GET_TASK_BY_ALLOCATION_URL = "/Task/GetTasksByAllocation";
const CHECK_ALLOCATION_VALID_URL = "/Allocation/CheckValidAllocation";

const GET_ALLOCATION_URL = "/Resource/GetAllocation";
const GET_ALLOCATION_BY_RESOURCE_URL = "/Allocation/GetAllocationByResource";
const GET_ALLOCATION_DETAIL_URL = "/Allocation/GetAllocation";
const POST_ALLOCATION_ACTION_URL = "/Allocation/UpdateAllocationAction";
const GET_ACTIVITY_PLANS_URL = "/Allocation/GetActivityPlan";

const POST_POD_ALLOCATION_URL = "/Allocation/PostPOD";
const POST_LOG_URL = "/Resource/PostLog";
const GET_RESOUCE_LOGS = "/Resource/GetResourceLog";
const GET_ACTIVITIES_URL = "/Activity/GetActivity";

const GET_ACTION_URL = "/Helper/GetLookup";
const POST_GPS_LOG_URL = "/Resource/GPSLog";

const GET_JOB_LIST_URL = "/Task/GetTaskSequence";
const GET_SELECTED_JOB_URL = "/Task/GetSelectedTaskSequence";

const GET_TASK_BY_MANIFEST = "/Task/GetTasksByManifest";
const GET_TASK_LINES_BY_TASK_IDS = "/Task/GetTasksByListID";
const POST_TASK_LINE = "/Task/PostTaskLines";
const POST_MULTITASK_LINES = "/Task/PostMultipleTaskLines";
const POST_VALIDATE_TASK_LINES = "/Task/ValidateTaskLines";

const GET_USER_PROFILE_URL = "/Account/GetProfile";
const GET_NOTIFICATION_TEMPLATE_URL = "/Task/GetNotification";
const POST_JOB_NOTIFICATION_MESSAGE = "/Task/SendNotification";

const GET_SITE_DETAIL = "/Site/GetSite";

const GET_SITES_URL = "/Site/GetSites";
const GET_PRODUCT_TYPES_URL = "/Product/GetProductTypes";
const POST_TASK_URL = "/Task/InsertTasks";

//pass data between view
let currentLatExport = "Lat not ready";
let currentLonExport = "Lon not ready";
let GPS_Time = "GPS_Time not ready";

export default {
  API_URL,
  LOGIN_URL,
  GET_TENANT_LIST_URL,
  GET_RESOURCE_URL,
  POST_RESOURCE_LOG_URL,
  POST_RESOURCE_LOG_IMAGE_URL,
  POST_RESOURCE_LOGS_IMAGE_URL,
  GET_ALLOCATION_BY_RESOURCE_URL,
  GET_ALLOCATION_DETAIL_URL,
  GET_ALLOCATION_PLAN_URL,
  GET_ACTIVITY_PLANS_URL,
  GET_TASK_BY_ALLOCATION_URL,
  CHECK_ALLOCATION_VALID_URL,
  POST_ALLOCATION_ACTION_URL,
  POST_LOG_URL,
  POST_POD_ALLOCATION_URL,
  GET_RESOUCE_LOGS,
  GET_ACTIVITIES_URL,
  GET_ACTION_URL,
  POST_GPS_LOG_URL,
  GET_USER_PROFILE_URL,
  GET_JOB_LIST_URL,
  GET_SELECTED_JOB_URL,
  GET_NOTIFICATION_TEMPLATE_URL,
  POST_JOB_NOTIFICATION_MESSAGE,
  GET_SITE_DETAIL,
  GET_ALLOCATION_URL,
  GET_SITES_URL,
  GET_PRODUCT_TYPES_URL,
  POST_TASK_URL,
  GET_TASK_BY_MANIFEST,
  GET_TASK_LINES_BY_TASK_IDS,
  POST_TASK_LINE,
  POST_MULTITASK_LINES,
  POST_VALIDATE_TASK_LINES,
  currentLatExport,
  currentLonExport,
};
