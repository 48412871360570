<template>
  <div>
    <DxPopup
      :title="$t('activity.activity_detail.title')"
      :visible="visible"
      :drag-enabled="false"
      :show-title="true"
      :close-on-outside-click="true"
      :show-close-button="true"
      :width="'90%'"
      :height="'80%'"
      @hidden="hidden"
    >
      <DxScrollView :scroll-by-content="true" :scroll-by-thumb="true">
        <div class="dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label">Allocation ID</div>
            <div class="dx-field-value">
              <DxTextBox :read-only="true" :value="allocationId" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Site ID</div>
            <div class="dx-field-value">
              <DxTextBox :read-only="true" :value="siteId" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Site Description</div>
            <div class="dx-field-value">
              <DxTextBox :read-only="true" :value="siteDescription" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Site Address</div>
            <div class="dx-field-value">
              <DxTextArea :read-only="true" :value="siteAddress" height="75" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Contact Name</div>
            <div class="dx-field-value">
              <DxTextBox :read-only="true" :value="contactName" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Contact Phone</div>
            <div class="dx-field-value">
              <DxTextBox :read-only="true" :value="contactPhoneNumber" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Contact Email</div>
            <div class="dx-field-value">
              <DxTextBox :read-only="true" :value="contactEmail" />
            </div>
          </div>
          <!-- hide for now -->
          <div class="dx-field" v-if="false">
            <div class="dx-field-label">Latitude</div>
            <div class="dx-field-value">
              <DxTextBox :read-only="true" :value="siteLatitude" />
            </div>
          </div>
          <!-- hide for now -->
          <div class="dx-field" v-if="false">
            <div class="dx-field-label">Longitude</div>
            <div class="dx-field-value">
              <DxTextBox :read-only="true" :value="siteLongitude" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Expected Arrive Time</div>
            <div class="dx-field-value">
              <DxTextBox :read-only="true" :value="expArriveTime" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Expected Depart Time</div>
            <div class="dx-field-value">
              <DxTextBox :read-only="true" :value="expDepartTime" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ $t("common.lbl_comment") }}</div>
            <div class="dx-field-value">
              <DxTextArea :read-only="true" :value="siteComment" height="75" />
            </div>
          </div>
        </div>
        <DxMap
          ref="activity-map"
          :zoom="mapConfig.zoom"
          :height="mapConfig.height"
          :width="mapConfig.width"
          :provider="mapConfig.provider"
          :auto-adjust="false"
          :center="centerOfMap"
        >
          <DxKey :google="mapConfig.googleKey" />
        </DxMap>
      </DxScrollView>
    </DxPopup>
  </div>
</template>
<script>
import DxPopup from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxMap, DxKey } from "devextreme-vue/map";
import DxTextBox from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selectedPlan: {
      type: Object,
      default: null,
    },
    hidden: Function,
  },
  data() {
    return {
      mapConfig: {
        width: "100%",
        height: "300",
        zoom: 14,
        provider: "google",
        googleKey: "AIzaSyDXUe4sWzgd_Ab4y516s8Y9RcxXDw_zSoo",
      },
      centerOfMap: { lat: 0.0, lng: 0.0 },
    };
  },
  computed: {
    allocationId() {
      if (this.selectedPlan == null || this.selectedPlan.AllocationID == null)
        return "";
      return this.selectedPlan.AllocationID;
    },
    siteId() {
      if (this.selectedPlan == null || this.selectedPlan.SiteID == null)
        return "";
      return this.selectedPlan.SiteID;
    },
    siteDescription() {
      if (
        this.selectedPlan == null ||
        this.selectedPlan.Sites == null ||
        this.selectedPlan.Sites.Description == null
      )
        return "";
      return this.selectedPlan.Sites.Description;
    },
    siteAddress() {
      if (!this.selectedPlan || !this.selectedPlan.Sites) return "";

      const site = this.selectedPlan.Sites;
      const address = site.Address;
      const address2 = site.Address2;
      const area = site.Area;
      const city = site.City;
      return (
        (address ? address : "") +
        (address2 ? `\n${address2}` : "") +
        (area ? `\n${area}` : "") +
        (city ? `\n${city}` : "")
      );
    },
    siteLatitude() {
      if (
        this.selectedPlan == null ||
        this.selectedPlan.Sites == null ||
        this.selectedPlan.Sites.Latitude == null
      )
        return "";
      return this.selectedPlan.Sites.Latitude;
    },
    siteLongitude() {
      if (
        this.selectedPlan == null ||
        this.selectedPlan.Sites == null ||
        this.selectedPlan.Sites.Longitude == null
      )
        return "";
      return this.selectedPlan.Sites.Longitude;
    },
    expArriveTime() {
      if (this.selectedPlan == null || this.selectedPlan.ExpArriveTime == null)
        return "";
      return this.selectedPlan.ExpArriveTime;
    },
    expDepartTime() {
      if (this.selectedPlan == null || this.selectedPlan.ExpDepartTime == null)
        return "";
      return this.selectedPlan.ExpDepartTime;
    },
    siteComment() {
      if (
        this.selectedPlan == null ||
        this.selectedPlan.Sites == null ||
        this.selectedPlan.Sites.Comment == null
      )
        return "";
      return this.selectedPlan.Sites.Comment;
    },
    contactName() {
      return this.selectedPlan &&
        this.selectedPlan.Sites &&
        this.selectedPlan.Sites.ContactName
        ? this.selectedPlan.Sites.ContactName
        : "";
    },
    contactPhoneNumber() {
      return this.selectedPlan &&
        this.selectedPlan.Sites &&
        this.selectedPlan.Sites.ContactPhone
        ? this.selectedPlan.Sites.ContactPhone
        : "";
    },
    contactEmail() {
      return this.selectedPlan &&
        this.selectedPlan.Sites &&
        this.selectedPlan.Sites.ContactEmail
        ? this.selectedPlan.Sites.ContactEmail
        : "";
    },
  },
  methods: {},
  watch: {
    visible: function (newValue, oldValue) {
      if (oldValue == false && newValue == true) {
        if (this.siteLatitude == null || this.siteLongitude == null) return;

        // delete all previous marker, add new one
        const activityMap = this.$refs["activity-map"].instance;

        const markers = activityMap.option("markers");
        if (markers) {
          markers.forEach((marker) => {
            activityMap.removeMarker(marker);
          });
        }

        const location = {
          lat: this.siteLatitude,
          lng: this.siteLongitude,
        };

        // add new marker
        activityMap.addMarker({
          location: location,
        });

        activityMap.option("zoom", this.mapConfig.zoom);

        this.centerOfMap = location;
      }
    },
  },
  components: {
    DxPopup,
    DxScrollView,
    DxMap,
    DxKey,
    DxTextBox,
    DxTextArea,
  },
};
</script>
<style lang=""></style>
