var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-block dx-card responsive-paddings"},[_c('DxAccordion',{attrs:{"data-source":_vm.items,"collapsible":true,"selected-item":null,"multiple":false},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(" "+_vm._s(_vm.$t("selected_data_accordion.lbl_resource"))+" : "),_c('b',[_vm._v(_vm._s(data.selectedResourceId))])])]}},{key:"item",fn:function(ref){
var data = ref.data;
return [_c('div',[_c('div',[_c('p',{staticClass:"selected-accordion-item"},[_vm._v(" "+_vm._s(_vm.$t("selected_data_accordion.lbl_allocation"))+" : "),_c('b',[_vm._v(_vm._s(data.selectedAllocationId))])]),_c('p',{staticClass:"selected-accordion-item"},[_vm._v(" "+_vm._s(_vm.$t("selected_data_accordion.lbl_site"))+" : "),_c('b',[_vm._v(_vm._s(data.selectedSiteId))])]),_c('p',{staticClass:"selected-accordion-item"},[_vm._v(" "+_vm._s(_vm.$t("selected_data_accordion.lbl_location"))+" : "),_c('b',[_vm._v(_vm._s(data.currentLat)+" , "+_vm._s(data.currentLon)+" ("+_vm._s(data.locationAccuraccy)+" m)")])])]),(_vm.ableChangeTask)?_c('DxLookup',{staticStyle:{"margin-top":"1em"},attrs:{"dataSource":_vm.allocationPlanDataSource,"valueExpr":"SiteID","displayExpr":"SiteID","placeholder":_vm.$t('selected_data_accordion.hint_site'),"onValueChanged":_vm.onAllocationPlanChanged,"value":_vm.selectedSite}},[_c('DxDropDownOptions',{attrs:{"close-on-outside-click":true,"show-title":false}})],1):_vm._e(),_c('div',{staticStyle:{"margin-top":"1.5em","margin-bottom":"0.5em"}},[_c('DxButton',{attrs:{"icon":"refresh","onClick":_vm.onClickChangeTask}}),_c('DxButton',{attrs:{"icon":"info","onClick":_vm.showAllocationPlanDetail}})],1)],1)]}}])}),_c('DxPopup',{ref:"plan-detail-popup",attrs:{"visible":_vm.planDetailVisible,"drag-enabled":false,"show-title":true,"show-close-button":true,"title":_vm.$t('selected_data_accordion.lbl_tasks_list'),"on-hidden":_vm.onPlanDetailPopupHidden,"close-on-outside-click":true,"height":'auto',"width":'90%'}},[_c('div',[_c('DxDataGrid',{attrs:{"showColumnLines":true,"showRowLines":true,"showBorders":true,"dataSource":_vm.planTasksDataSource,"columnHidingEnabled":true,"columns":[
          'TaskID',
          'DestSite',
          'ProductType',
          {
            dataType: 'number',
            caption: 'Qty',
            dataField: 'Qty',
          },
          'Status' ],"editing":{
          allowAdding: false,
          allowUpdating: false,
          allowDeleteing: false,
        }}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }